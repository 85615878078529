import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC16EtzjYF0oKMkrH8IZ6zx4ZiED-scozY",
  authDomain: "humans-of-rural-india-d2b22.firebaseapp.com",
  projectId: "humans-of-rural-india-d2b22",
  storageBucket: "humans-of-rural-india-d2b22.appspot.com",
  messagingSenderId: "43638107391",
  appId: "1:43638107391:web:aaf9916a04958ef90bf0ca",
  measurementId: "G-SKBC8H5EH4",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const storage = firebase.storage();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
export const githubAuth = new firebase.auth.GithubAuthProvider();

const app = firebase.initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BK8ZZL0QmKnVGZdIAhHo2CJp9T6Vd1CdppvSLIc15P7GsEv_TTPo8RqbOZm-9_myvNtXl75tFFx6zAkl3W_YEVM",
    });
    console.log(token);
    return token;
  }
};
