import { useEffect, useState } from "react"
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useSelector } from "react-redux";
import './product.scss'

const formatter = new Intl.NumberFormat(undefined, {
  currency: 'INR',
  style: "currency"
})

const BillProduct = props => {
    const { productData, billedProducts, addToBill, increseCount, decreseCount } = props
    const billedProductsIds = billedProducts.map(each => each.productId)
    const { storeData } = useSelector(state => state.store)
    const {shopId} = storeData
    const { productId, productImages, productName, productCategory, productdiscountPrice, hasVariants, variants } = productData
    const [categoryData] = storeData.categorys.filter(each => each.categoryName === productCategory)
    const gst = categoryData.gst
    let price = productdiscountPrice
    let sizes = []
    let colors = []
    if (hasVariants) {
        sizes = Object.keys(variants)
        colors = Object.keys(variants[sizes[0]])
    }
    const [activeSize, setActiveSize] = useState(sizes[0])
    const [activeColor, setActiveColor] = useState(colors[0])
    const [stateColors, setColors] = useState(colors)
    if (hasVariants) {
        price = variants[activeSize][activeColor].price
    }
    let billedProduct = false
    let count
    if (billedProductsIds.includes(productId)) {
        let billedProductData = {}
        billedProducts.forEach(each => {
            if (productId === each.productId) {
                if (hasVariants) {
                    if (activeColor === each.color && activeSize === each.size) {
                        billedProductData = each
                    }
                } else {
                    billedProductData = each
                }
            }
        })
        
        const { productCount } = billedProductData
        count = productCount
        if (hasVariants) {
            const billedProductData = billedProducts.filter(eachProduct => (eachProduct.productId === productId && activeSize === eachProduct.size && activeColor === eachProduct.color))
            if (billedProductData.length !== 0) {
                billedProduct = true
            }
        }
        else {
            billedProduct = true
        }
    }

    useEffect(() => {
        if (hasVariants) {
            colors = Object.keys(variants[activeSize])
            setColors(colors)
        }
    }, [activeSize])

    const decreseProductCount = () => {
        decreseCount({productId, activeSize, activeColor})
    }

    const increaseProductCount = () => {
        increseCount({productId, activeSize, activeColor})
    }

    return (
        <li className="product-con">
            <div>
                <div className="product-img-con">
                    <img src={productImages[0]} alt="product" className="product-img" />
                </div>
                <div className="product-name-price"><p className="prod-name">{productName.slice(0, 10)}..</p><p>{formatter.format(price)}</p></div>
                <p className="category">{productCategory}</p>
                </div>
            {hasVariants && <div className="sizes"><p className="sizes-text">Size </p>{sizes.map((eachSize, index) => <button key={index} className={`size ${activeSize === eachSize && 'active-size-btn'}`} type="button" onClick={() => { setActiveSize(prevState => eachSize); setActiveColor(prevState => (Object.keys(variants[eachSize]))[0]);}}>{eachSize}</button>)}</div>}
            {hasVariants && <div className="colors"><p className="colors-text">Color </p> {stateColors.map((eachColor, index) => <div type="button" className={`color ${activeColor === eachColor && 'active-color-btn'}`} key={index} style={{backgroundColor: eachColor}} onClick={() => setActiveColor(eachColor)}></div>)}</div>}
            {billedProduct ?
                <div className='count-controlls'>
                    <button type="button" className='controll-btn' onClick={increaseProductCount} ><AiOutlinePlus /></button>
                    <p className='product-count'>{count}</p>
                    <button type="button" className='controll-btn' onClick={decreseProductCount}><AiOutlineMinus /></button>
                </div> :
                <button type="button" onClick={() => addToBill({ ...productData, productCount: 1, onePiecePrice: price, shopId, gst: gst, size: activeSize, color: activeColor })} className="add-btn">ADD </button>} 
        </li>
    )
}

export default BillProduct