import { Outlet } from "react-router-dom";
import Digistall from "../../../img/Digistall.png";
// import { useEffect } from "react";
import "./signUp.scss";

const LoginSheredLayout = () => {
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE, includedLanguages: 'en,hi,te,ta,pa,', autoDisplay: false, multilanguagePage: true }, 'google_translate_element')
  // }

  // useEffect(() => {
  //   var addScript = document.createElement('script');
  //   addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, [])

  return (
    <div className="signup-Container">
      <div className="left-container">
        <img src={Digistall} alt="logo" className="auth-logo" />
      </div>
      <Outlet />
      <div className="right-container">
        <p className="copy-right">All Rights Reserved (C) | digistall.in</p>
        {/* <div id="google_translate_element"></div> */}
      </div>
    </div>
  );
};

export default LoginSheredLayout;
