import { useEffect, useRef, useState } from "react";
import {
  getPasswordStatus,
  updatePassword,
  loginWithPassword,
} from "../../../fetchCalles";
// import { auth } from "../../../firebase.config.auth";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { getEmitraUser } from "../../../fetures/emitra.slice";
import Dashboard from "../Dashboard/dashboard";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import "./landingPage.scss";
import axios from "axios";
import { useParams } from "react-router-dom";

const LandingPage = () => {
  const { ssoid } = useParams();
  console.log(ssoid, "this is ssoid");

  const kioskcode = window.location.href.split("/")[4];
  const { logged } = useSelector((state) => state.emitra);
  // const logged = true;
  const [passwordStatus, setPasswordStatus] = useState();
  const [userData, setUserData] = useState({
    KIOSKCODE: kioskcode,
    name: undefined,
    phone: undefined,
    password: undefined,
    email: undefined,
    ssoid: ssoid,
  });
  const [error, setError] = useState();
  const [password, setPassword] = useState();
  const [otpSent, setOtpStatus] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [OTP, setOtp] = useState(new Array(6).fill(""));
  const [status, setStatus] = useState("LOADING");
  const [visibul, setVisibulity] = useState(false);
  const dispatch = useDispatch();
  const [forgot, setForgot] = useState(false);
  const [sendmail, setSendmail] = useState();
  const mainid = useRef();

  useEffect(() => {
    checkPasswordStatus();
  }, []);

  const checkPasswordStatus = async () => {
    try {
      const response = await getPasswordStatus(kioskcode);
      setPasswordStatus(response.data.response);
      setStatus("SUCCESS");
    } catch (error) {
      setError(error.error);
      setStatus("FAILURE");
      setLoadingStatus(false);
    }
  };
  //   useEffect(() => {
  //     dispatch(getEmitraUser(kioskcode));
  //   }, []);
  const loginUser = async (e) => {
    e.preventDefault();
    setLoadingStatus(true);
    try {
      const response = await loginWithPassword({
        KIOSKCODE: kioskcode,
        password,
      });
      console.log(response);
      if (response.data.status === "Valid") {
        dispatch(getEmitraUser(kioskcode));
        setLoadingStatus(false);
        // dispatch(emitraLogin(true));
      } else {
        setError(response.data.response);
        setLoadingStatus(false);
      }
    } catch (error) {
      alert("Something was wrong! Are you in Online.");
      loadingStatus(false);
    }
  };

  const updateEmitraPersonData = async () => {
    try {
      await updatePassword(userData);
      alert("Account Created Successfully Check Your mail");
      dispatch(getEmitraUser(kioskcode));
    } catch (error) {
      alert("Something was wrong try again");
      setLoadingStatus(false);
    }
  };

  //   const onClickVerify = async () => {
  //     // let otp = "";
  //     // OTP.forEach((each) => (otp += each));
  //     setLoadingStatus(true);
  //     let confirmationResult = window.confirmationResult;
  //     confirmationResult
  //       .confirm(otp)
  //       .then((result) => {
  //         updateEmitraPersonData();
  //       })
  //       .catch((err) => {
  //         const error = err.message.split(".");
  //         setError(error[0].split(":")[1]);
  //         setLoadingStatus(false);
  //       });
  //   };

  function changeFocus(target, index) {
    if (isNaN(target.value)) {
      return false;
    }
    setOtp([...OTP.map((v, ind) => (ind === index ? target.value : v))]);
    if (target.nextSibling) {
      target.nextSibling.focus();
    }
  }
  const onClickSendOtp = async (e) => {
    if (!userData.email) {
      alert("Enter your email");
      return;
    }
    e.preventDefault();
    if (userData.name) {
      if (userData.phone.length === 10) {
        if (userData.password.length > 6) {
          setLoadingStatus(true);
          setError();
          setLoadingStatus(false);
          console.log("halfyway");
          updateEmitraPersonData();
        } else {
          setError("Enter more then 6 chares in password");
        }
      } else {
        setError("Enter valid phone number");
        setLoadingStatus(false);
      }
    } else {
      setError("Enter your name");
    }
  };

  const renderFailureView = () => {
    return <h1>Something was wrong. Please check your network!</h1>;
  };

  const RenderForgetView = () => {
    return (
      <>
        <div className="password-bg-container">
          <div className="password-form">
            <h1 className="p-heding">Hello {kioskcode}!</h1>
            <p className="p-description">Welcome back to Digistall</p>
            <div className="p-form">
              <label className="p-label" htmlFor="password">
                Enter your email to get password
              </label>

              <div className="password-container">
                <input
                  className="password-in"
                  type="email"
                  // onChange={(e) => setSendmail(e.target.value)}
                  // value={sendmail}
                  ref={mainid}
                  // value={mainid.current}
                  placeholder="Enter your email"
                />
              </div>
              <button className="login-btn" onClick={(e) => sendMailFunc(e)}>
                Send
              </button>
              <p className="e-note">
                <span className="red-note">Note: </span> Check Spam folder if
                message is not in inbox
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };
  const sendMailFunc = async (e) => {
    e.preventDefault();
    try {
      console.log(mainid.current.value, kioskcode);
      const response = await axios.post(
        `https://backend.digistall.in/emitra/forgot`,
        {
          email: mainid.current.value,
          kioskcode: kioskcode,
        }
      );
      console.log(response, response.data);
      if (response.data.success) {
        alert("Email sent successfully");
        setForgot(false);
      }
    } catch (error) {
      alert("Something was wrong try again");
      setLoadingStatus(false);
    }
  };

  const renderSuccessView = () => {
    return (
      <div>
        {/* <div id="recaptcha"></div> */}

        {logged ? (
          <Dashboard />
        ) : (
          <>
            {forgot ? (
              <RenderForgetView />
            ) : (
              <>
                {passwordStatus ? (
                  <div className="password-bg-container">
                    <div className="password-form">
                      <h1 className="p-heding">Hello {kioskcode}!</h1>
                      <p className="p-description">Welcome back to Digistall</p>
                      <form onSubmit={loginUser} className="p-form">
                        <label className="p-label" htmlFor="password">
                          Enter your Password
                        </label>
                        <div className="password-container">
                          <input
                            className="password-in"
                            type={visibul ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {visibul ? (
                            <AiOutlineEye
                              onClick={() => setVisibulity(false)}
                              className="eye-icon"
                            />
                          ) : (
                            <AiOutlineEyeInvisible
                              className="eye-icon"
                              onClick={() => setVisibulity(true)}
                            />
                          )}
                        </div>
                        {loadingStatus ? (
                          <button type="button" className="login-btn">
                            Loading
                          </button>
                        ) : (
                          <button type="submit" className="login-btn">
                            Login
                          </button>
                        )}
                      </form>
                      <p className="e-note">
                        <span className="red-note">
                          HelpLine No: +91 9461852060{" "}
                        </span>
                      </p>
                      <p className="e-note">
                        <span
                          className="red-note"
                          onClick={() => setForgot(true)}
                        >
                          Forgot password?
                        </span>
                      </p>
                    </div>
                  </div>
                ) : (
                  // <div className="password-bg-container">
                  //   <div className="password-form">
                  //     <h1 className="p-heding">Hello {kioskcode}!</h1>
                  //     <p className="p-description">Welcome to Digistall</p>

                  //     <form onSubmit={onClickSendOtp} className="p-form">
                  //       <label htmlFor="userName" className="p-label">
                  //         User Name
                  //       </label>
                  //       <input
                  //         className="nor-in"
                  //         type="text"
                  //         id="userName"
                  //         value={userData.name}
                  //         onChange={(e) =>
                  //           setUserData({ ...userData, name: e.target.value })
                  //         }
                  //       />
                  //       <label htmlFor="phone" className="p-label">
                  //         Phone Number
                  //       </label>
                  //       <input
                  //         className="nor-in"
                  //         type="number"
                  //         id="phone"
                  //         value={userData.phone}
                  //         onChange={(e) =>
                  //           setUserData({ ...userData, phone: e.target.value })
                  //         }
                  //       />
                  //       <label htmlFor="email" className="p-label">
                  //         Email
                  //       </label>
                  //       <input
                  //         className="nor-in"
                  //         type="email"
                  //         id="email"
                  //         value={userData.email}
                  //         onChange={(e) =>
                  //           setUserData({ ...userData, email: e.target.value })
                  //         }
                  //       />
                  //       <label htmlFor="password" className="p-label">
                  //         Create Password
                  //       </label>
                  //       <div className="password-container">
                  //         <input
                  //           className="password-in"
                  //           type={visibul ? "text" : "password"}
                  //           autoComplete="new-password"
                  //           id="password"
                  //           value={userData.password}
                  //           onChange={(e) =>
                  //             setUserData({
                  //               ...userData,
                  //               password: e.target.value,
                  //             })
                  //           }
                  //         />
                  //         {visibul ? (
                  //           <AiOutlineEye
                  //             onClick={() => setVisibulity(false)}
                  //             className="eye-icon"
                  //           />
                  //         ) : (
                  //           <AiOutlineEyeInvisible
                  //             className="eye-icon"
                  //             onClick={() => setVisibulity(true)}
                  //           />
                  //         )}
                  //       </div>
                  //       {error && <p className="error-msg">{error}</p>}
                  //       {loadingStatus ? (
                  //         <button type="button" className="login-btn">
                  //           Loading
                  //         </button>
                  //       ) : (
                  //         <button type="submit" className="login-btn">
                  //           Sign Up
                  //         </button>
                  //       )}
                  //     </form>
                  //   </div>
                  //   <p className="e-note">
                  //     <span className="red-note">Note: </span>This is one time
                  //     proccess. for your security.
                  //   </p>
                  // </div>
                  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
                    <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
                      <h1 className="text-3xl font-semibold text-gray-800 mb-4">
                        Hello {kioskcode}!
                      </h1>
                      <p className="text-gray-600 mb-6">Welcome to Digistall</p>

                      <form onSubmit={onClickSendOtp} className="space-y-4">
                        <div>
                          <label
                            htmlFor="userName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Full Name
                          </label>
                          <input
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            type="text"
                            id="userName"
                            value={userData.name}
                            onChange={(e) =>
                              setUserData({ ...userData, name: e.target.value })
                            }
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone Number
                          </label>
                          <input
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            type="number"
                            id="phone"
                            value={userData.phone}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                phone: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <input
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            type="email"
                            id="email"
                            value={userData.email}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Create Password
                          </label>
                          <div className="relative mt-1">
                            <input
                              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                              type={visibul ? "text" : "password"}
                              autoComplete="new-password"
                              id="password"
                              value={userData.password}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  password: e.target.value,
                                })
                              }
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                              {visibul ? (
                                <AiOutlineEye
                                  onClick={() => setVisibulity(false)}
                                  className="cursor-pointer text-gray-500"
                                />
                              ) : (
                                <AiOutlineEyeInvisible
                                  className="cursor-pointer text-gray-500"
                                  onClick={() => setVisibulity(true)}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        {error && (
                          <p className="text-red-500 text-sm">{error}</p>
                        )}

                        <div>
                          {loadingStatus ? (
                            <button
                              type="button"
                              className="w-full py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              Loading
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="w-full py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                              Sign Up
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                    <p className="mt-4 text-center text-sm text-gray-600">
                      <span className="font-semibold text-red-500">Note:</span>{" "}
                      This is a one-time process for your security.
                    </p>
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div
          id="sign-in-button"
          style={{ display: "none" }}
          className="hiden"
        ></div>
      </div>
    );
  };

  const renderLoadingView = () => {
    return <h1>Loading..</h1>;
  };

  const renderConetent = () => {
    switch (status) {
      case "LOADING":
        return renderLoadingView();
      case "SUCCESS":
        return renderSuccessView();
      case "FAILURE":
        return renderFailureView();
      default:
        break;
    }
  };

  return (
    <div>
      <div id="recaptcha"></div>
      {renderConetent()}
    </div>
  );
};

export default LandingPage;
