import { configureStore } from "@reduxjs/toolkit";
import storeReducer from "./fetures/store.slice";
import userReducer from "./fetures/user.slice";
import emitraSlice from "./fetures/emitra.slice";
import sumoSlice from "./fetures/sumoSlice";

export const store = configureStore({
  reducer: {
    store: storeReducer,
    user: userReducer,
    emitra: emitraSlice,
    sumo: sumoSlice,
  },
});

const saveState = (state) => {
  try {
    const serialisedStore = JSON.stringify(state.store);
    const serialisedUser = JSON.stringify(state.user);
    const serialisedEmitra = JSON.stringify(state.emitra);
    const serialisedSumo = JSON.stringify(state.sumo);
    window.localStorage.setItem("local_store", serialisedStore);
    window.localStorage.setItem("local_user", serialisedUser);
    window.localStorage.setItem("local_emitra", serialisedEmitra);
    window.localStorage.setItem("local_sumo", serialisedSumo);
  } catch (error) {
    saveState(store.getState());
  }
};

store.subscribe(() => {
  saveState(store.getState());
});
