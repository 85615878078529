import { Outlet, useNavigate } from "react-router-dom";
import SideNavbar from "./Sidenavbar/sideNavbar";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { intlFormatDistance } from "date-fns";
import ExpiryView from "../expireView/expiryView";
import "./profileLayout.scss";
import HeaderProfile from "../Header/headerProfile";

const Profile = () => {
  const { userStatus } = useSelector((state) => state.user);
  const { storeData } = useSelector((state) => state.store);
  const sumo = useSelector((state) => state.store.storeData.sumo);

  const { validTill } = storeData;
  let validUntill = 0;
  if (userStatus === "COMPLETED") {
    if (validTill !== "SumoUser") {
      validUntill = intlFormatDistance(new Date(validTill), new Date(), {
        numeric: "always",
        unit: "day",
      }).split(" ")[1];
    }
  }
  const navigate = useNavigate();

  useEffect(() => {
    switch (userStatus) {
      case "UNAUTHORIZED" || "User Not Existed":
        return navigate("/login");
      case "DOMAIN":
        return navigate("/domain");
      case "BUSSINESS":
        return navigate("/bussiness");
      case "FIRSTPRODUCT":
        return navigate("/product");
      case "PAYMENT":
        return navigate("/payment");
      default:
        break;
    }
  });

  return (
    <>
      {sumo ? (
        <>
          <div className="profile-container">
            <HeaderProfile />
            {userStatus === "COMPLETED" && (
              <div className="body-container">
                <SideNavbar />
                <Outlet />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {validUntill > 0 ? (
            <>
              <div className="profile-container">
                <HeaderProfile />
                {userStatus === "COMPLETED" && (
                  <div className="body-container">
                    <SideNavbar />
                    <Outlet />
                  </div>
                )}
              </div>
            </>
          ) : (
            <ExpiryView />
          )}
        </>
      )}
    </>
  );
};

export default Profile;
