import { useSelector } from "react-redux"
import { intlFormatDistance } from "date-fns"

const StoreItem = (props) => {
    const { storeData, setStore } = props
    const {userStatus} = useSelector(state => state.user)
  let validUntill = 0
    if (userStatus === 'COMPLETED') {
        validUntill = intlFormatDistance(
            new Date(storeData.validTill),
            new Date(),
            { numeric: 'always', unit: 'day' }
        ).split(" ")[1]
    }

    const onclickSubscription = () => {
        setStore(storeData)
    }
    
    return (
        <li className="store-item">
            <img src={storeData.logo} alt="store-logo" className="new-store-logo" />
            <p>{storeData.storeName}</p>
            <a href={`https://${storeData.shopId}.digistall.in`} target="__blank">{`https://${storeData.shopId}.digistall.in`}</a>
            <p>{validUntill > 0 ? 'Active' : 'InActive'}</p>
            <button type="button" onClick={onclickSubscription}>Subscribe Now</button>
        </li>
    )
}

export default StoreItem