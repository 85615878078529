import { useSelector } from "react-redux";
import { intlFormatDistance } from "date-fns";

const EmitraStoreItem = (props) => {
  const { storeData, setStore } = props;
  const { userStatus } = useSelector((state) => state.user);
  let validUntill = 1;
  validUntill = intlFormatDistance(new Date(storeData.validTill), new Date(), {
    numeric: "always",
    unit: "day",
  }).split(" ")[1];

  const onclickSubscription = () => {
    setStore(storeData);
  };

  return (
    <>
      {/* // <li className="store-item"> */}
      <td>{storeData.SSOID}</td>
      <td>
        <img
          src={storeData.logo}
          alt="store-logo"
          className="new-store-logo"
          style={{ width: "70px" }}
        />
      </td>
      <td>{storeData.storeName}</td>
      <td>
        <a
          href={`https://${storeData.shopId}.digistall.in`}
          target="__blank"
        >{`https://${storeData.shopId}.digistall.in`}</a>
      </td>
      {/* <p className={`${validUntill > 0 ? "badge badge-success" : "badge badge-danger"}`}>{validUntill > 0 ? 'Active' : 'InActive'}</p> */}
      {validUntill > 0 ? (
        <td>
          <span className="badge badge-success P-5"> Active</span>
        </td>
      ) : (
        <td>
          <span className="badge badge-danger P-5">InActive</span>
        </td>
      )}
      <td className="d-flex flex-column text-center">
        {/* {validUntill > 0 && (
          <span>
            {storeData.planType} plan ends in {validUntill} Days
          </span>
        )} */}
        {validUntill > 0 ? (
          <button
            type="button"
            className="bg-green-500 py-2 px-2 text-white rounded-md"
            onClick={onclickSubscription}
          >
            {storeData.planType} plan ends in {validUntill} Days
          </button>
        ) : (
          <button
            type="button"
            className=" btn-danger bg-button"
            onClick={onclickSubscription}
          >
            Subscribe Now
          </button>
        )}
        {/* <button type="button" className=" btn-danger bg-button mx-auto" onClick={onclickSubscription} style={{width: "50%"}}>Subscribe Now</button> */}
      </td>
      {/* // </li> */}
    </>
  );
};

export default EmitraStoreItem;
