import axios from "axios";
import Digistall_logo from "../../img/Digistall_logo.png";
import { FiEdit, FiTool } from "react-icons/fi";
import { RiShoppingBag3Line } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiOutlineHome } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { FiBell } from "react-icons/fi";
import { generateToken } from "../../firebase.config.auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

// import "./header.scss";

const tabsConst = {
  home: "HOME",
  editor: "EDITOR",
  catalogue: "CATALOGUE",
  tools: "TOOLS",
  profile: "PROFILE",
};

const Header = () => {
  const { storeData } = useSelector((state) => state.store);
  const { shopId } = storeData;
  const rlang = useSelector((state) => state.user.lang);

  const AllowNotification = async () => {
    try {
      const token = await generateToken();
      console.log(token);

      const response = await axios.post(
        "https://backend.digistall.in/user/setDeviceToken",
        {
          deviceToken: token,
          shopId,
        }
      );
      if (response.data.status == "success") {
        toast.info("Allowed");
        console.log("Allowed");
      } else {
        toast.info("Not Allowed");
        console.log("Not Allowed");
      }
    } catch (error) {
      console.error("Error occurred while allowing notification:", error);
    }
  };

  let tab = window.location.href.split("/")[3];
  if (tab === "") {
    tab = tabsConst.home;
  } else if (tab === "editor") {
    tab = tabsConst.editor;
  } else if (tab === "catalogue") {
    tab = tabsConst.catalogue;
  } else if (tab === "tools") {
    tab = tabsConst.tools;
  } else {
    tab = tabsConst.profile;
  }
  const navigate = useNavigate();

  return (
    // <div className="catalogue-header">
    //   <Link to="/" className="catalogue-image">
    //     <img src={Digistall_logo} alt="logo" className="imgs" />
    //   </Link>
    //   <div className="catalogue-nav-buttons">
    //     <div className="icon-name">
    //       <button
    //         type="button"
    //         onClick={() => navigate("/")}
    //         className={`home-btn ${tab === tabsConst.home && "active"}`}
    //       >
    //         <AiOutlineHome className="icons" size={25} />
    //         Home
    //       </button>
    //     </div>
    //     <button
    //       onClick={() => navigate("/catalogue")}
    //       className={`icon-name ${tab === tabsConst.catalogue && "active"}`}
    //     >
    //       <RiShoppingBag3Line className="icons" size={25} />
    //       Catalog
    //     </button>
    //     <button
    //       type="button"
    //       onClick={() => navigate("/editor")}
    //       className={`icon-name ${tab === tabsConst.editor && "active"}`}
    //     >
    //       <FiEdit className="icons" size={25} />
    //       Editor
    //     </button>
    //     <button
    //       type="button"
    //       onClick={() => navigate("/tools")}
    //       className={`icon-name ${tab === tabsConst.tools && "active"}`}
    //     >
    //       <FiTool className="icons" size={25} />
    //       Tools
    //     </button>
    //     <button
    //       type="button"
    //       onClick={() => navigate("/profile")}
    //       className={`icon-name ${tab === tabsConst.profile && "active"}`}
    //     >
    //       <CgProfile className="icons" size={25} />
    //       Profile
    //     </button>
    //   </div>
    // </div>
    <div className="relative mb-2">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex justify-between items-center md:px-4 pt-2 bg-white">
        <Link to="/" className="">
          <img src={Digistall_logo} alt="logo" className="w-52 md:w-52" />
        </Link>
        <div
          onClick={AllowNotification}
          className="px-4 bg-white rounded-2xl shadow-lg py-2 mr-2 md:hidden"
        >
          <FiBell />
        </div>
        <div className="hidden md:flex items-center gap-4 md:gap-6">
          <div
            onClick={AllowNotification}
            className="px-4 bg-white rounded-2xl shadow-lg py-2 mr-2 md:visible flex justify-center"
          >
            {rlang === "hi" ? "अधिसूचना की अनुमति दें" : "Allow Notification"}

            <FiBell className="mt-1 ml-2" />
          </div>
          <button
            type="button"
            className={`flex flex-col items-center ${
              tab === tabsConst.home ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/")}
          >
            <AiOutlineHome className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "होम पेज" : "Home"}</span>
          </button>
          <button
            className={`flex flex-col items-center ${
              tab === tabsConst.catalogue ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/catalogue")}
          >
            <RiShoppingBag3Line className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "कैटलॉग" : "Catalogue"}</span>
          </button>
          <button
            type="button"
            className={`flex flex-col items-center ${
              tab === tabsConst.editor ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/editor")}
          >
            <FiEdit className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "एडिटर" : "Editor"}</span>
          </button>
          <button
            type="button"
            className={`flex flex-col items-center ${
              tab === tabsConst.tools ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/tools")}
          >
            <FiTool className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "टूल" : "Tools"}</span>
          </button>
          <button
            type="button"
            className={`flex flex-col items-center ${
              tab === tabsConst.profile ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/profile")}
          >
            <CgProfile className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "प्रोफाइल" : "Profile"}</span>
          </button>
        </div>
      </div>
      <div className="flex-1"></div>
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md py-2 md:hidden flex justify-around items-center">
        <button
          type="button"
          className={`flex flex-col items-center ${
            tab === tabsConst.home ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/")}
        >
          <AiOutlineHome className="text-2xl" />
          <span className="text-xs">{rlang === "hi" ? "होम पेज" : "Home"}</span>
        </button>
        <button
          className={`flex flex-col items-center ${
            tab === tabsConst.catalogue ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/catalogue")}
        >
          <RiShoppingBag3Line className="text-2xl" />
          <span className="text-xs">
            {rlang === "hi" ? "कैटलॉग" : "Catalog"}
          </span>
        </button>
        <button
          type="button"
          className={`flex flex-col items-center ${
            tab === tabsConst.editor ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/editor")}
        >
          <FiEdit className="text-2xl" />
          <span className="text-xs">{rlang === "hi" ? "एडिटर" : "Editor"}</span>
        </button>
        <button
          type="button"
          className={`flex flex-col items-center ${
            tab === tabsConst.tools ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/tools")}
        >
          <FiTool className="text-2xl" />
          <span className="text-xs">{rlang === "hi" ? "टूल" : "Tools"}</span>
        </button>
        <button
          type="button"
          className={`flex flex-col items-center ${
            tab === tabsConst.profile ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/profile")}
        >
          <CgProfile className="text-2xl" />
          <span className="text-xs">
            {rlang === "hi" ? "प्रोफाइल" : "Profile"}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Header;
