import React, { useState } from "react";
import Stamp1 from '../../../img/Stamp1.png';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import './Stamp1.scss';

const Stamps1 = () => {
    const [BusinessName, setBusinessName] = useState();

    const businessname = (e) => {
        setBusinessName(e.target.value);
    }

    const handleCaptureClick = async () => {
        const canvas = await html2canvas(document.querySelector('#stampDownload'));
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, 'Stamp.png', 'image/png');
    };
    return (
        <>
            <div className="stamp-btn-container">
                <div className="Stamp1-container">
                    <div className="stamp-input">
                        <label htmlFor="name" className="label">Business Name (Authorized signature)</label>
                        <input type="text" id="name" maxLength={14} className='input-value input-shadow' onChange={businessname} />
                    </div>
                    <div className="stamp1-img" id="stampDownload">
                        <img src={Stamp1} className='stmp1' />
                        <div className="business-stamp">
                            <div className='business-stamp-name'>
                                {BusinessName}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="download-stamp-btn">
                    <button type="button" className="titles" onClick={handleCaptureClick} >Download</button>
                </div>
            </div>
        </>
    );
}

export default Stamps1;