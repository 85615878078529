import { useEffect, useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase.config.auth";
import { intlFormatDistance } from "date-fns";
import subscriptionBody from "../../../img/sub.png";
import {
  getUserData,
  createStore,
  updatePayment,
  addRefferal,
  checkAvailability,
  createsubdomain,
  addProduct,
  addCategory,
  subscribeStore,
  genarateHash,
} from "../../../fetchCalles";
import axios from "axios";
import { addDays } from "date-fns";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { HiOutlinePhotograph } from "react-icons/hi";
import qr from "../../../img/qr.png";
import GooglePayLogo from "../../../img/google-pay-logo.svg";
import PhonePeLogo from "../../../img/phonepe-logo.svg";
import PaytmLogo from "../../../img/paytm-logo.svg";
import Switch from "react-switch";
import COD from "../../../img/cod.png";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../fetures/user.slice";
import "./admin.scss";
import StoreItem from "./storeItem";
import { BsSearch } from "react-icons/bs";
import digi from "../../../img/Digistall.png";
import { sendPaymentReq } from "../../../fetchCalles";
import successImg from "../../../img/pay-suc.png";
import { v4 } from "uuid";

const filterConst = [
  "Filter",
  "ACTIVE",
  "INACTIVE",
  "1 DAY",
  "6 MONTHS",
  "12 MONTHS",
];

const storeStatusConst = {
  phone: "PHONE",
  otp: "OTP",
  domain: "DOMAIN",
  business: "BUSINESS",
  product: "PRODUCT",
  payment: "PAYMENT",
  stors: "STORS",
};

const Admin = () => {
  const { userData, userStatus } = useSelector((state) => state.user);
  const { storeData } = useSelector((state) => state.store);
  const { createdStors } = userData;
  let storsKeys = [];
  let storsCount = 0;
  if (createdStors) {
    storsKeys = Object.keys(createdStors);
    storsCount = storsKeys.length;
  }
  const [storeStatus, setStoreStatus] = useState("STORS");
  const [phone, setPhone] = useState();
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [userName, setUserName] = useState();
  const [error, setError] = useState();
  const [OTP, setOtp] = useState(new Array(6).fill(""));
  const [userId, setUserId] = useState();
  const [domain, setDomain] = useState();
  const [available, setAvailability] = useState(false);
  const [err, setErr] = useState();
  const [businessName, setBusinessName] = useState();
  const [businessAddress, setBusinessAddress] = useState();
  const [productImage, setProductImage] = useState();
  const [productImageFile, setProductImageFile] = useState();
  const [productName, setProductName] = useState();
  const [productCategory, setProductCategory] = useState();
  const [productOriginalPrice, setProductOriginalPrice] = useState();
  const [productDiscountPrice, setProductDiscountPrice] = useState();
  const [productCount, setProductCount] = useState(1);
  const [paymentQR, setPaymentQR] = useState();
  const [paymentQRFile, setPaymentQRFile] = useState();
  const [UPIId, setUPIId] = useState();
  const [cod, setCod] = useState(false);
  const [filter, setFilter] = useState(filterConst[0]);
  const [search, setSearch] = useState("");
  const [activeStore, setActiveStore] = useState();
  const [transactionId, setTransactionId] = useState();
  const { shopId, planType } = storeData;
  const [stage, setStage] = useState("plans");
  const [planPrice, setPlanPrice] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  var date = new Date();
  const validTill = addDays(date, 1);

  let validUntill = 1;
  if (userStatus === "COMPLETED" && activeStore) {
    validUntill = intlFormatDistance(
      new Date(activeStore.validTill),
      new Date(),
      { numeric: "always", unit: "day" }
    ).split(" ")[1];
  }

  useEffect(() => {
    if (planPrice) {
      const sendSubscriptionRequest = async (planPrice) => {
        const months = planPrice === 3000 ? 6 : 12;
        let planType = "Half-Yearly";
        if (planPrice === 6000) {
          planType = "Yearly";
        }
        try {
          subscribeStore({
            shopId: shopId,
            months: months,
            planType: planType,
          });
          setActiveStore();
          setLoadingStatus(false);
        } catch (e) {
          alert(
            "Something was wrong please contact humansofruralindia@gmail.com"
          );
          setPlanPrice();
          setLoadingStatus(false);
        }
      };

      const getPaymentPage = async (payloadData) => {
        // console.log(payloadData)
        try {
          // const { data } = await axios.post('http://localhost:5000/user/test', payloadData);
          // setData(data)
          await axios.post("https://secure.payu.in/_payment", payloadData);
          sendSubscriptionRequest(payloadData.planPrice);
          setLoadingStatus(false);
          // You can redirect the user to the PayU payment page by setting window.location.href = response.data
        } catch (error) {
          // Handle any errors
          alert("Something was wrong.. plese try again");
          setPlanPrice();
          setLoadingStatus(false);
        }
      };

      const genaratePayload = async () => {
        const tId = v4();
        const string = `PCErGq|${tId}|${planPrice}|Subscription|${
          userData.userName
        }|${
          userData.email && userData.email !== ""
            ? userData.email
            : "humansofruralindia@gmail.com"
        }|||||||||||xZuuBrbszZOH6ijdejRYrjkARA80LDL5)`;
        const salt = "xZuuBrbszZOH6ijdejRYrjkARA80LDL5";
        const payload = {
          key: "PCErGq",
          salt: salt,
          txnid: tId,
          amount: `${planPrice}`,
          productinfo: "Subscription",
          firstname: `${userData.userName}`,
          email: `${
            userData.email && userData.email !== ""
              ? userData.email
              : "humansofruralindia@gmail.com"
          }`,
          phone: `${
            userData.phoneNumber && userData.phoneNumber !== ""
              ? userData.phoneNumber
              : "8555913642"
          }`,
          surl: "https://test-payment-middleware.payu.in/simulatorResponse",
          furl: "https://test-payment-middleware.payu.in/simulatorResponse",
          service_provider: "payu_paisa",
        };
        try {
          const response = await genarateHash({ salt, inputString: string });
          payload.hash = response.data.hash;
          // const payload = {
          //             username: userData.userName,
          //             email: (userData.email && userData.email !== '')  ? userData.email : 'humansofruralindia@gmail.com',
          //             phone: (userData.phoneNumber && userData.phoneNumber !== '') ? userData.phoneNumber : '8555913642',
          //             amount: planPrice
          // }
          getPaymentPage(payload);
          setLoadingStatus(false);
        } catch (error) {
          // Handle any errors
          alert("Something was wrong.. plese try again");
          setPlanPrice();
          setLoadingStatus(false);
        }
      };
      genaratePayload();
    }
  }, [planPrice]);

  const sendRequest = async () => {
    if (!transactionId) {
      setErr("Enter Transaction ID");
    } else if (transactionId.length === 23) {
      let type = "Monthly";
      if (planPrice === 2000) {
        type = "Quarterly";
      } else if (planPrice === 6000) {
        type = "Half-Yearly";
      }
      let contact;
      if (userData.phoneNumber === null) {
        contact = userData.email;
      } else {
        contact = userData.phoneNumber;
      }
      setLoading(true);
      const body = {
        shopId: activeStore.shopId,
        amount: planPrice,
        planType: type,
        transactionId: transactionId,
        contact: contact,
      };
      try {
        await sendPaymentReq(body);
        setStage("success");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert("something was wrong");
      }
    } else {
      setErr("Invalid Transaction ID!");
    }
  };

  async function callUpdateRef() {
    try {
      await addRefferal({
        userId: userData.userId,
        newStore: {
          userId: userId,
          shopId: domain,
          storeName: businessName,
          validTill: validTill,
          planType: "1 days trail",
          logo: "https://res.cloudinary.com/aguruprasad/image/upload/v1669717427/woodline/product-images/Logo_2_xastkj.png",
        },
      });
      dispatch(getUser(userData.userId));
      alert("Store Created Successfully");
      setLoadingStatus(false);
      setStoreStatus("STORS");
    } catch (e) {
      setErr("Something went wrong 1");
      setLoadingStatus(false);
    }
  }

  async function callUpdatePayment(qr) {
    try {
      await updatePayment(domain, qr, UPIId, cod);
      callUpdateRef();
    } catch (e) {
      setErr("Something went wrong");
      setLoadingStatus(false);
    }
  }

  const uploadPaymentQRHandler = (e) => {
    setPaymentQR(URL.createObjectURL(e.target.files[0]));
    setPaymentQRFile(e.target.files[0]);
  };

  async function paymentHandler() {
    if (!paymentQR) {
      setErr("Please upload QR code for payments");
      return;
    }
    if (!UPIId) {
      setErr("Please enter UPI Id");
      return;
    }
    setLoadingStatus(true);
    const storage = getStorage();
    const location = domain + "-" + businessName;
    const storageRef = ref(storage, "shop-qr/" + location);
    await uploadBytes(storageRef, paymentQRFile).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then(async (url) => {
        callUpdatePayment(url);
      });
    });
  }

  const addProductFunction = async (product) => {
    try {
      await addProduct(domain, product);
      setLoadingStatus(false);
      setStoreStatus("PAYMENT");
    } catch (error) {
      setErr("Something went wrong");
      setLoadingStatus(false);
    }
  };

  const addCategoryAndProduct = async (product) => {
    const category = {
      categoryName: productCategory,
      categoryImg:
        product.productImages.length === 0
          ? "https://res.cloudinary.com/aguruprasad/image/upload/v1678167791/woodline/product-images/noImage_nyfhe0.jpg"
          : product.productImages[0],
      gst: 0,
    };
    try {
      await addCategory(domain, category);
      addProductFunction(product);
    } catch (error) {
      setErr("Something went wrong");
      setLoadingStatus(false);
    }
  };

  const addProductHandler = async () => {
    if (!productName) {
      setErr("Please enter Product Name");
      return;
    }
    if (!productCategory) {
      setErr("Please enter Product Category");
      return;
    }
    if (!productOriginalPrice) {
      setErr("Please enter Product Original Price");
      return;
    }
    if (!productCount) {
      setErr("Please enter Product Count ( Stock )");
      return;
    }

    if (productImage) {
      setLoadingStatus(true);
      const storage = getStorage();
      const location = productName + "-" + productCategory;
      const storageRef = ref(storage, "product/" + location);
      await uploadBytes(storageRef, productImageFile).then(async (snapshot) => {
        await getDownloadURL(snapshot.ref).then(async (url) => {
          try {
            const product = {
              productName,
              productImages: [url],
              productPrice: productOriginalPrice,
              productDescription: "",
              productdiscountPrice: productDiscountPrice,
              productCategory: productCategory,
              productUnits: productCount,
              productHSN: "",
              productGST: "",
              productUnitType: "unit",
            };
            addCategoryAndProduct(product);
          } catch (e) {
            setErr("Something went wrong");
            setLoadingStatus(false);
          }
        });
      });
    } else {
      try {
        const product = {
          productName,
          productImages: [],
          productPrice: productOriginalPrice,
          productDescription: "",
          productdiscountPrice: productDiscountPrice,
          productCategory: productCategory,
          productUnits: productCount,
          productHSN: "",
          productGST: "",
          productUnitType: "unit",
        };
        addCategoryAndProduct(product);
        // setLoadingStatus(false);
        // setStoreStatus("PAYMENT");
      } catch (e) {
        setErr("Something went wrong");
        setLoadingStatus(false);
      }
    }
  };

  const uploadProductImageHandler = (e) => {
    setProductImage(URL.createObjectURL(e.target.files[0]));
    setProductImageFile(e.target.files[0]);
  };

  const businessHandler = async () => {
    if (!businessName) {
      setErr("Please enter a Business Name");
      return;
    }
    if (!businessAddress) {
      setErr("Please enter a Business Address");
      return;
    }
    setLoadingStatus(true);
    try {
      await createStore(
        domain,
        businessName,
        userId,
        undefined,
        businessAddress,
        validTill
      );
      setLoadingStatus(false);
      setStoreStatus("PRODUCT");
    } catch (e) {
      setErr("some thing was wrong please try again..");
      setLoadingStatus(false);
    }
  };

  async function onClickCreatesubdomain() {
    setLoadingStatus(true);
    try {
      await createsubdomain(domain, userName, undefined);
      setLoadingStatus(false);
      setStoreStatus("BUSINESS");
    } catch (e) {
      setErr("Something went wrong");
      setLoadingStatus(false);
    }
  }

  async function onChnageSubdomain(e) {
    setAvailability(false);
    const value = e.target.value
      .replace(/[^\w\s]/gi, "")
      .trim()
      .toLowerCase();
    setDomain(value);
    try {
      const { data } = await checkAvailability(value);
      if (data.response) {
        setAvailability(true);
        setErr("");
      } else {
        setAvailability(false);
        setErr("sub-domain already exist");
      }
    } catch (e) {
      setAvailability(false);
      setErr("Something went wrong");
    }
  }

  const createUser = async (userId) => {
    try {
      await axios.post("https://backend.digistall.in/user/createuser", {
        userId: userId,
        userName: userName,
        phoneNumber: phone,
      });
      setLoadingStatus(false);
      setStoreStatus("DOMAIN");
    } catch (e) {
      setError("Something went wrong! PLEASE TRY TO LOGIN");
      setLoadingStatus(false);
    }
  };

  const checkUser = async (userId) => {
    try {
      const response = await getUserData(userId);
      if (response.data.response === "User Not Existed") {
        await createUser(userId);
      } else {
        setError("User already existed please try Login");
        setLoadingStatus(false);
      }
    } catch (e) {
      setError("Something went wrong");
      setLoadingStatus(false);
    }
  };

  const onClickVerify = async () => {
    let otp = "";
    OTP.forEach((each) => (otp += each));
    setLoadingStatus(true);
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then((result) => {
        setUserId(result.user["uid"]);
        checkUser(result.user["uid"]);
      })
      .catch((error) => {
        setError("Somerthing was wrong");
        setLoadingStatus(false);
      });
  };

  function changeFocus(target, index) {
    if (isNaN(target.value)) {
      return false;
    }
    setOtp([...OTP.map((v, ind) => (ind === index ? target.value : v))]);
    if (target.nextSibling) {
      target.nextSibling.focus();
    }
  }

  const genarateCaptchaVerifyer = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  const onClickSendOtp = async () => {
    if (phone.length === 10) {
      setLoadingStatus(true);
      genarateCaptchaVerifyer();
      let appVerifyer = window.recaptchaVerifier;
      const phoneNumber = `+91${phone}`;
      signInWithPhoneNumber(auth, phoneNumber, appVerifyer)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setError();
          setStoreStatus("OTP");
          setLoadingStatus(false);
        })
        .catch((error) => {
          setError("Something went wrong");
          setLoadingStatus(false);
        });
    } else {
      setError("Enter valid phone number");
      setLoadingStatus(false);
    }
  };

  const onClickSetActiveStore = (store) => {
    setActiveStore(store);
  };

  const renderPayment = () => {
    return (
      <div className="pay-container">
        <div className="payment-heading">Set up Payment</div>
        <div className="payment-sub-heading">Enter your payment details</div>
        <div className="pay-cod">
          <div className="payment-cod-container">
            <div className="payment-container">
              <p className="qr-title">Upload QR Code</p>
              <div className="qr-container">
                {paymentQR ? (
                  <div>
                    <img src={paymentQR} className="qr-code" />
                  </div>
                ) : (
                  <div className="without-qr">
                    <label htmlFor="dropzone-file" className="without-upload">
                      <div className="icons">
                        <img src={qr} />
                        <p className="texts">
                          For Payments please upload QR code
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        onChange={uploadPaymentQRHandler}
                      />
                    </label>
                  </div>
                )}
              </div>
              <div className="upi-logo-container">
                <div className="upi-logo">
                  <img src={PaytmLogo} />
                </div>
                <div className="upi-logo">
                  <img src={PhonePeLogo} />
                </div>
                <div className="upi-logo">
                  <img src={GooglePayLogo} />
                </div>
              </div>
              {err && (
                <div>
                  <div className="text-red-600 mt-2 text-lg">{err}</div>
                </div>
              )}

              <div className="upi-input">
                <div className="upi-id">
                  <label htmlFor="firstname" className="title">
                    Enter Your UPI ID
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    className="upi-full input-shadow"
                    onChange={(e) => setUPIId(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="cod-container">
              <p className="qr-title">Enable for Cash on delivery</p>
              <div>
                <img src={COD} alt="cod" className="cod-img" />
                <Switch
                  onChange={() => setCod(!cod)}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  activeBoxShadow="0 0 2px 3px #0293A8"
                  onColor="#0293A8"
                  checked={cod}
                />
              </div>
              <div className="bttn">
                <>
                  {loadingStatus ? (
                    <div className="bottom-btn-container">
                      <button type="button" className="confirm-btn">
                        Loading...{" "}
                      </button>
                    </div>
                  ) : (
                    <div
                      className="bottom-btn-container"
                      onClick={paymentHandler}
                    >
                      <button type="button" className="confirm-btn">
                        Next{" "}
                      </button>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderProduct = () => {
    return (
      <div className="p-container">
        <div className="product-heading">Add Your First Product</div>
        <div className="product-sub-heading">
          Upload Product Details To Get Started
        </div>
        <div className="first-product-container">
          <div className="image-container">
            {!productImage ? (
              <label htmlFor="dropzone-file" className="without-img-container">
                <div style={{ color: "#B3B3B3" }}>
                  <HiOutlinePhotograph size={50} />
                </div>
                <div style={{ color: "#B3B3B3" }}>Click to add an Image</div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={uploadProductImageHandler}
                />
              </label>
            ) : (
              <div>
                <img src={productImage} className="uploaded-img" />
              </div>
            )}
          </div>
          <div className="text-inputs">
            <div className="product-input">
              <label htmlFor="firstname" className="title">
                Product Name
              </label>
              <input
                type="text"
                id="firstname"
                placeholder="Name"
                className="product-full input-shadow"
                onChange={(e) => {
                  setProductName(e.target.value);
                  setErr();
                }}
              />
            </div>
            <div className="product-input">
              <label htmlFor="firstname" className="title">
                Product Category
              </label>
              <input
                type="text"
                id="firstname"
                placeholder="Daily use, Grocery, Home appliances etc"
                className="product-full input-shadow"
                onChange={(e) => {
                  setProductCategory(e.target.value);
                  setErr();
                }}
              />
            </div>
            <div className="form-two-line-container">
              <div className="price-container">
                <label htmlFor="firstname" className="title">
                  {" "}
                  Product Price
                </label>
                <input
                  type="number"
                  id="firstname"
                  placeholder="Product price in market"
                  className="hulf-Width-input input-shadow"
                  onChange={(e) => {
                    setProductOriginalPrice(e.target.value);
                    setErr();
                  }}
                />
              </div>
              <div className="price-container">
                <label htmlFor="firstname" className="title">
                  Discounted Price
                </label>
                <input
                  type="number"
                  id="firstname"
                  placeholder="Discount Price"
                  className="hulf-Width-input input-shadow"
                  onChange={(e) => {
                    setProductDiscountPrice(e.target.value);
                    setErr();
                  }}
                />
              </div>
            </div>
            <div className="product-input">
              <label htmlFor="firstname" className="title">
                {" "}
                Product Stock Count
              </label>
              <input
                type="number"
                id="firstname"
                placeholder="Eg: 12"
                className="product-full input-shadow"
                onChange={(e) => {
                  setProductCount(e.target.value);
                  setErr();
                }}
              />
            </div>
          </div>
        </div>
        {err && <p className="error">{err}</p>}
        <div className="flex justify-end ml-20 mt-7">
          <>
            {loadingStatus ? (
              <div className="bottom-btn-container">
                <button type="button" className="confirm-btn">
                  Loading...{" "}
                </button>
              </div>
            ) : (
              <div className="bottom-btn-container" onClick={addProductHandler}>
                <button type="button" className="confirm-btn">
                  Next{" "}
                </button>
              </div>
            )}
          </>
        </div>
      </div>
    );
  };

  const renderBusiness = () => {
    return (
      <div className="oshop-details">
        <div className="shop-details">Shop Details</div>
        <p className="shop-sub-heading">Fill in the Shop details below</p>
        <div className="business-input">
          <label htmlFor="firstname" className="title">
            {" "}
            Business Name
          </label>
          <input
            type="text"
            id="firstname"
            className="full input-shadow"
            placeholder="Eg: Digistall Pvt"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </div>

        <div className="business-input">
          <label htmlFor="firstname" className="title">
            {" "}
            Business Address
          </label>
          <input
            type="text"
            id="firstname"
            className="full input-shadow"
            placeholder="Eg: xyz area, Rajasthan. India - 342902"
            value={businessAddress}
            onChange={(e) => setBusinessAddress(e.target.value)}
          />
        </div>
        {err && (
          <div>
            <div className="error-msg">{err}</div>
          </div>
        )}
        <div className="mt-5 flex justify-start mb-5 shopDetails-btn">
          <>
            {loadingStatus ? (
              <div className="bottom-btn-container">
                <button type="button" className="confirm-btn">
                  Loading...{" "}
                </button>
              </div>
            ) : (
              <div className="bottom-btn-container" onClick={businessHandler}>
                <button type="button" className="confirm-btn">
                  Next{" "}
                </button>
              </div>
            )}
          </>
        </div>
      </div>
    );
  };

  const renderDomain = () => {
    return (
      <div className="sdomain-input">
        <div className="domain-input">
          <input
            type="text"
            id="domain"
            placeholder="Ex: jeweleryforall"
            className="full"
            value={domain}
            onChange={onChnageSubdomain}
          />
          <div className="server-name">.digistall.in</div>
        </div>
        {err && <p className="text-red-600 text-center mt-6 text-lg">{err}</p>}
        <div className="screate-domain">
          {available && domain !== "" && (
            <>
              {loadingStatus ? (
                <button
                  type="button"
                  className="confirm-btn bottom-btn-container"
                >
                  Loading...{" "}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={onClickCreatesubdomain}
                  className="bottom-btn-container confirm-btn"
                >
                  CREATE
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderOtp = () => {
    return (
      <div className="phoneotp-bg-con">
        <div className="phone-otp-container">
          <p className="otp-heading">
            We have sent a code to your Phone:
            <br /> <span className="number">+91-{phone}</span>
          </p>
          <div className="Otp">
            {OTP.map((each, index) => {
              return (
                <input
                  type="text"
                  value={each}
                  key={index}
                  maxLength={1}
                  name="otp"
                  onChange={(e) => changeFocus(e.target, index)}
                  onFocus={(e) => e.target.select()}
                  className="otp-field"
                />
              );
            })}
          </div>
          {loadingStatus ? (
            <button className="ver-btn">Please Wait..</button>
          ) : (
            <button type="button" onClick={onClickVerify} className="ver-btn">
              Verify
            </button>
          )}
          {error && <p className="error-msg">{error}</p>}
        </div>
      </div>
    );
  };

  const renderPhone = () => {
    return (
      <div className="phone-bg-con">
        <div className="phone-number-con">
          <img src={digi} alt="digi-logo" className="signup-logo" />
          <label htmlFor="name" className="phone-lab">
            Full Name
          </label>
          <input
            type="text"
            value={userName}
            className="seller-name-input"
            onChange={(e) => {
              setUserName(e.target.value);
              setError();
            }}
          />
          <label htmlFor="phone" className="phone-lab">
            Phone Number
          </label>
          <div className="phone-number-input-div">
            <button type="button" className="reg-code">
              +91
            </button>
            <input
              type="number"
              className="phone-number-input"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setError();
              }}
            />
          </div>
          {loadingStatus ? (
            <button type="button" className="send-otp-btn">
              Loading..
            </button>
          ) : (
            <button
              type="button"
              className="send-otp-btn"
              onClick={onClickSendOtp}
            >
              Send OTP
            </button>
          )}
          {error && <p className="error-msg">{error}</p>}
        </div>
      </div>
    );
  };

  const getFileredStores = (storesList) => {
    let filteredStores;
    switch (filter) {
      case filterConst[1]:
        const activeStores = storesList.filter((each) => {
          let validUntill = "day";
          if (userStatus === "COMPLETED") {
            validUntill = intlFormatDistance(
              new Date(createdStors[each].validTill),
              new Date(),
              { numeric: "always", unit: "day" }
            ).split(" ")[2];
          }
          if (validUntill === "day") {
            return each;
          }
        });
        filteredStores = activeStores;
        return filteredStores;
      case filterConst[2]:
        const InActiveStores = storesList.filter((each) => {
          let validUntill = "day";
          if (userStatus === "COMPLETED") {
            validUntill = intlFormatDistance(
              new Date(createdStors[each].validTill),
              new Date(),
              { numeric: "always", unit: "day" }
            ).split(" ")[2];
          }
          if (validUntill !== "day") {
            return each;
          }
        });
        filteredStores = InActiveStores;
        return filteredStores;
      case filterConst[3]:
        const oneDayStores = storesList.filter(
          (each) => createdStors[each].planType === "1 days trail"
        );
        filteredStores = oneDayStores;
        return filteredStores;
      case filterConst[4]:
        const threeMonthsStores = storesList.filter(
          (each) => createdStors[each].planType === "Quarterly"
        );
        filteredStores = threeMonthsStores;
        return filteredStores;
      case filterConst[5]:
        const sixMonthsStores = storesList.filter(
          (each) => createdStors[each].planType === "Half-Yearly"
        );
        filteredStores = sixMonthsStores;
        return filteredStores;
      default:
        return storesList;
    }
  };

  const renderStors = () => {
    const storesList = storsKeys.filter(
      (each) =>
        createdStors[each].storeName.startsWith(search) ||
        createdStors[each].shopId.startsWith(search)
    );
    const filteredStores = getFileredStores(storesList);

    return (
      <div className="stores-bg-con">
        <div className="h-btn-con">
          <h1 className="stores-h">Manage Stores</h1>
          <button
            className="add-btn"
            type="button"
            onClick={() => setStoreStatus(storeStatusConst.phone)}
          >
            + ADD NEW
          </button>
        </div>

        {storsCount !== 0 ? (
          <ul className="stors-con">
            <div className="stores-filter-section">
              <select
                value={filter}
                className="filter"
                onChange={(e) => setFilter(e.target.value)}
              >
                {filterConst.map((each) => (
                  <option value={each}>{each}</option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Find Store..."
                className="search-bar"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <BsSearch className="search-icon" />
            </div>
            <li className="stores-header">
              <p>Logo</p>
              <p>Business Name</p>
              <p>Link</p>
              <p>Status</p>
              <p>Subscription</p>
            </li>
            {filteredStores.map((each) => (
              <StoreItem
                storeData={createdStors[each]}
                key={createdStors[each].shopId}
                setStore={onClickSetActiveStore}
              />
            ))}
          </ul>
        ) : (
          <div className="no-stors-con">No Stores Found</div>
        )}
      </div>
    );
  };

  const renderSignupPages = () => {
    switch (storeStatus) {
      case storeStatusConst.stors:
        return renderStors();
      case storeStatusConst.phone:
        return renderPhone();
      case storeStatusConst.otp:
        return renderOtp();
      case storeStatusConst.domain:
        return renderDomain();
      case storeStatusConst.business:
        return renderBusiness();
      case storeStatusConst.product:
        return renderProduct();
      case storeStatusConst.payment:
        return renderPayment();
      default:
        break;
    }
  };

  const renderSuccessView = () => {
    return (
      <div className="success-view">
        <h1 className="success-h">Request sent successfully!</h1>
        <p className="success-p">
          Give us a couple of hours to confirm your payment and your store will
          be active.
        </p>
        <img src={successImg} alt="success" className="success-img" />
      </div>
    );
  };

  const renderPaymentView = () => {
    return (
      <div className="payment-page">
        <div className="left-secion">
          <p className="pay-title"> QR Code</p>
          <img
            src="https://res.cloudinary.com/aguruprasad/image/upload/v1686714345/PhonePeQR_IndusInd_Bank_-_5249_1_fza5vd.png"
            alt="qr code"
            className="sub-qr-code"
          />
          <div className="upi-id-con">
            <p className="pay-title">UPI ID</p>
            <p className="pay-title">digistall@axl</p>
          </div>
          <label htmlFor="transaction-id" className="pay-title mt-4">
            Transaction ID
          </label>
          <input
            id="transaction-id"
            placeholder="eg: T8273HE938374NDJ9938434"
            className="payment-input mb-4 input-shadow"
            value={transactionId}
            onChange={(e) => {
              setTransactionId(e.target.value);
              setErr();
            }}
          />
          {err && (
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "8px",
                color: "crimson",
              }}
            >
              {err}
            </p>
          )}
          <div className="buttons-con">
            <button
              type="button"
              onClick={() => setStage("plans")}
              className="can-btn"
            >
              Cancle
            </button>
            {loading ? (
              <button type="button" className="done-btn">
                Loading..
              </button>
            ) : (
              <button type="button" className="done-btn" onClick={sendRequest}>
                Done
              </button>
            )}
          </div>
          {/* <p>1. please write down your transaction id to confirm your subscription</p>
                    <p>2. Give us one to two hours we will confirm and your store will be active</p> */}
        </div>
        <div className="right-section">
          <div className="keys">
            <p className="pay-title">Company Name</p>
            <p className="pay-title">CIN NO</p>
            <p className="pay-title">TAN No</p>
            <p className="pay-title">PAN No</p>
            <p className="pay-title">Registration Number</p>
            <p className="pay-title">Registered Adress</p>
          </div>
          <div className="values">
            <p className="payment-input">Siyag Rural Market Private Limited</p>
            <p className="payment-input">U52100RJ2021PTC077111</p>
            <p className="payment-input">JDHS24211F</p>
            <p className="payment-input">ABGCS8909L</p>
            <p className="payment-input">08ABGCS8909L1ZN</p>
            <p className="">
              C/O DAY A RAM SIYAG, NEMA RAM SIYAG SECONDARY SCHOOL KE PAS GOTHAN
              PUTR NEMA RAM SIYAG SECONDARY,GOTAN,Nagaur,Rajasthan,342902
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderPlansView = () => {
    return (
      <div className="subscription">
        <h1 className="subscription-h pt-2" style={{ textAlign: "center" }}>
          Your {activeStore.planType} plan is{" "}
          <span style={{ fontWeight: "600" }}>ACTIVE!</span>
          <br />
          ~Your {activeStore.planType} plan ends in{" "}
          {validUntill > 0 ? 0 : validUntill} Days~
        </h1>
        <div className="subscription-plan">
          <img src={subscriptionBody} alt="plan data" className="plan-data" />
          <div className="plans">
            {/* <div className='plan'>
                        <div className='plan-name'>Monthly Plan</div>
                        <div className='plan-details'>
                            <h1>700</h1>
                            <p>1 month</p>
                                <button type='button' onClick={() => { setPlanPrice(700);  setStage('payment')}}>Subscribe</button>
                        </div>
                    </div> */}
            <div className="plan">
              <div className="plan-name">Half-Yearly Plan</div>
              <div className="plan-details">
                <h1>&#8377;3000</h1>
                <p>6 month</p>
                {loadingStatus ? (
                  <button type="button">Loading..</button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setPlanPrice(3000);
                      setLoadingStatus(true);
                    }}
                  >
                    Subscribe
                  </button>
                )}
              </div>
            </div>
            <div className="plan">
              <div className="plan-name">Yearly Plan</div>
              <div className="plan-details">
                <h1>&#8377;6000</h1>
                <p>1 Year</p>
                {loadingStatus ? (
                  <button type="button">Loading..</button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setPlanPrice(6000);
                      setLoadingStatus(true);
                    }}
                  >
                    Subscribe
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPaymentPages = () => {
    switch (stage) {
      case "plans":
        return renderPlansView();
      case "payment":
        return renderPaymentView();
      case "success":
        return renderSuccessView();
      default:
        break;
    }
  };

  return (
    <div className="admin-bg-con">
      {activeStore ? renderPaymentPages() : renderSignupPages()}
      <div id="sign-in-button"></div>
    </div>
  );
};

export default Admin;
