// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   appSumoData: {},
//   isLoggedIn: false,
// };

// const sumoSlice = createSlice({
//   name: "sumo",
//   initialState,
//   reducers: {
//     setSumoData: (state, action) => {
//       const appSumoData = action.payload;
//       state.appSumoData = appSumoData;
//     },
//   },
// });

// export const { appSumoData } = sumoSlice.actions;

// export default sumoSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  sumoData: {},
};

const loadState = () => {
  let state;
  try {
    const serialisedState = window.localStorage.getItem("local_sumo");
    if (!serialisedState) {
      state = defaultState;
      const serialisedState2 = JSON.stringify(state);
      window.localStorage.setItem("local_sumo", serialisedState2);
    } else {
      state = JSON.parse(serialisedState);
    }
  } catch (err) {
    console.log("error");
  }
  return state;
};

const initialState = loadState();

const sumoSlice = createSlice({
  name: "sumo",
  initialState,
  reducers: {
    setSumoData: (state, action) => {
      const sumoData = action.payload;
      state.sumoData = sumoData;
    },
  },
});

export const { setSumoData } = sumoSlice.actions;

export default sumoSlice.reducer;
