import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { RiImageAddFill } from "react-icons/ri";
import { updateStore } from "../../../fetchCalles";
import { getStoreData } from "../../../fetures/store.slice";
import { v4 } from "uuid";
import { generateToken, messaging } from "./../../../firebase.config.auth";
import { onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";

import "./profilePage.scss";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { setLang } from "../../../fetures/user.slice";

const ProfilePage = () => {
  const { storeData } = useSelector((state) => state.store);
  const { shopId } = storeData;
  const [store, setStore] = useState(storeData);
  const [editMode, setEditMode] = useState(false);
  const [uploading, setUploadingStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const dispatch = useDispatch();
  const rlang = useSelector((state) => state.user.lang);

  const updateProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateStore(store);
      dispatch(getStoreData(shopId));
      setLoading(false);
      setEditMode(false);
    } catch (e) {
      setLoading(false);
      alert("Something went wrong..");
    }
  };

  const uploadImg = async (e) => {
    setUploadingStatus(true);
    const storage = getStorage();
    const location = `${store.shopId}-logo-${v4()}`;
    const storageRef = ref(storage, "profile" + location);
    await uploadBytes(storageRef, e.target.files[0]).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then(async (url) => {
        setImage(true);
        setStore((prev) => ({
          ...prev,
          settings: {
            ...prev.settings,
            header: { ...prev.settings.header, logo: url },
          },
        }));
        setUploadingStatus(false);
      });
    });
  };
  const AllowNotification = async () => {
    try {
      const token = await generateToken();
      console.log(token);

      const response = await axios.post(
        "https://backend.digistall.in/user/setDeviceToken",
        {
          deviceToken: token,
          shopId,
        }
      );
      if (response.data.status == "success") {
        toast.info("Allowed");
        console.log("Allowed");
      } else {
        toast.info("Not Allowed");
        console.log("Not Allowed");
      }
    } catch (error) {
      console.error("Error occurred while allowing notification:", error);
    }
  };

  onMessage(messaging, (payload) => {
    console.log("payload", payload);
    toast.info(payload.notification.body);
  });
  // const [language, setLanguage] = useState(rlang);

  const handleToggle = () => {
    dispatch(setLang(rlang === "hi" ? "en" : "hi"));
  };
  // useEffect(() => {
  //   console.log(language, "this is the selected language");
  //   dispatch(setLang(language));
  // }, [language]);

  return (
    <div className="">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {editMode ? (
        <>
          <form
            onSubmit={updateProfile}
            className="bg-white shadow-md rounded-lg p-6"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <p className="text-lg font-semibold mb-2">
                  {rlang === "hi" ? "तस्विर अपलोड करना" : "Upload Image"}
                </p>
                <div className="flex items-center">
                  <label htmlFor="dropzone-file" className="cursor-pointer">
                    {image ? (
                      <img
                        src={store.settings.header.logo || "/placeholder.png"}
                        className="w-24 h-24 rounded-full object-cover"
                        alt="logo"
                      />
                    ) : (
                      <>
                        {uploading ? (
                          <span>
                            {rlang === "hi"
                              ? "अपलोड हो रहा है..."
                              : "Uploading..."}
                          </span>
                        ) : (
                          <RiImageAddFill className="text-gray-500" size={40} />
                        )}
                      </>
                    )}
                    <input
                      id="dropzone-file"
                      type="file"
                      disabled={uploading}
                      className="hidden"
                      onChange={uploadImg}
                    />
                  </label>
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="name" className="block font-semibold mb-1">
                  {rlang === "hi" ? "स्टोर नाम" : "Store Name"}
                </label>
                <input
                  type="text"
                  id="name"
                  className="input-shadow w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
                  value={store.settings.header.shopName}
                  onChange={(e) =>
                    setStore((prev) => ({
                      ...prev,
                      settings: {
                        ...prev.settings,
                        header: {
                          ...prev.settings.header,
                          shopName: e.target.value,
                        },
                      },
                    }))
                  }
                />
              </div>
              <div className="mb-4">
                <label htmlFor="category" className="block font-semibold mb-1">
                  {rlang === "hi" ? "स्टोर श्रेणी" : "Store Category"}
                </label>
                <input
                  type="text"
                  id="category"
                  className="input-shadow w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
                  value={store.shopCategory}
                  onChange={(e) =>
                    setStore({ ...store, shopCategory: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label htmlFor="address" className="block font-semibold mb-1">
                  {rlang === "hi" ? "पता" : "Address"}
                </label>
                <textarea
                  id="address"
                  rows={5}
                  className="input-shadow w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
                  value={store.settings.footer.address}
                  onChange={(e) =>
                    setStore((prev) => ({
                      ...prev,
                      settings: {
                        ...prev.settings,
                        footer: {
                          ...prev.settings.footer,
                          address: e.target.value,
                        },
                      },
                    }))
                  }
                />
              </div>
              <div className="mb-4">
                <label htmlFor="number" className="block font-semibold mb-1">
                  {rlang === "hi" ? "फ़ोन नंबर" : "Phone Number"}
                </label>
                <input
                  type="number"
                  id="number"
                  className="input-shadow w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
                  value={store.settings.header.phoneNumber}
                  onChange={(e) =>
                    setStore((prev) => ({
                      ...prev,
                      settings: {
                        ...prev.settings,
                        header: {
                          ...prev.settings.header,
                          phoneNumber: e.target.value,
                        },
                      },
                    }))
                  }
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block font-semibold mb-1">
                  {rlang === "hi" ? "ईमेल" : "Email"}
                </label>
                <input
                  type="text"
                  id="email"
                  className="input-shadow w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
                  value={store.settings.header.email}
                  onChange={(e) =>
                    setStore((prev) => ({
                      ...prev,
                      settings: {
                        ...prev.settings,
                        header: {
                          ...prev.settings.header,
                          email: e.target.value,
                        },
                      },
                    }))
                  }
                />
              </div>
              <div className="mb-4">
                <label htmlFor="gst" className="block font-semibold mb-1">
                  {rlang === "hi" ? "जीएसटी संख्या" : "GST No"}
                </label>
                <input
                  type="text"
                  id="gst"
                  className="input-shadow w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
                  value={store.gstNumber}
                  onChange={(e) =>
                    setStore({ ...store, gstNumber: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setEditMode(false)}
                className="px-4 py-2 rounded-lg border border-gray-300 bg-gray-200 hover:bg-gray-300 focus:outline-none"
              >
                {rlang === "hi" ? "रद्द करना" : "Cancel"}
              </button>
              <button
                type="submit"
                className="ml-4 px-4 py-2 rounded-lg bg-theme-blue text-white hover:bg-blue-600 focus:outline-none"
              >
                {uploading || loading ? "" : "Save"}
                {uploading || loading ? (
                  <>{rlang === "hi" ? "अपलोड हो रहा है..." : "Updating..."}</>
                ) : (
                  <>{rlang === "hi" ? "सुरक्षित करें" : "Save"}</>
                )}
              </button>
            </div>
          </form>
        </>
      ) : (
        <div className="w-full flex flex-col md:w-1/2 m-auto text-center md:h-screen justify-center">
          <div className=" flex justify-center mt-2 mb-2">
            <div className="flex items-center space-x-4">
              <span
                className={`py-2 px-4 rounded ${
                  rlang === "hi"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                हिंदी
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={rlang === "hi" ? false : "en"}
                  onChange={handleToggle}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-1 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              <span
                className={`py-2 px-4 rounded ${
                  rlang === "en"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                English
              </span>
            </div>
          </div>
          <div className=" bg-white shadow-md rounded-lg p-6  mt-2 ">
            <img
              src={storeData.settings.header.logo || "/placeholder.png"}
              alt="logo"
              className="w-24 h-24 rounded-full mx-auto mb-4"
            />
            <div className="">
              <ul className="">
                <div className="flex flex-row justify-between border-b">
                  <li className="">
                    {rlang === "hi" ? "स्टोर नाम" : "Store Name"}
                  </li>
                  <li className="">
                    {storeData.settings.header.shopName || "- - None - -"}
                  </li>
                </div>
                <div className="flex flex-row justify-between border-b">
                  <li className="">
                    {rlang === "hi" ? "स्टोर वर्ग" : "Store Category"}
                  </li>
                  <li className="">
                    {storeData.shopCategory || "- - None - -"}
                  </li>
                </div>
                <div className="flex flex-row justify-between border-b">
                  <li className="">{rlang === "hi" ? "पता" : "Address"}</li>
                  <li className="">
                    {storeData.settings.footer.address || "- - None - -"}
                  </li>
                </div>
                <div className="flex flex-row justify-between border-b">
                  <li className="">
                    {rlang === "hi" ? "मोबाइल नंबर" : "Mobile No"}
                  </li>
                  <li className="">
                    {storeData.settings.header.phoneNumber || "- - None - -"}
                  </li>
                </div>
                <div className="flex flex-row justify-between border-b">
                  <li className="">{rlang === "hi" ? "ईमेल" : "Email"}</li>
                  <li className="">
                    {storeData.settings.header.email || "- - None - -"}
                  </li>
                </div>
                <div className="flex flex-row justify-between border-b">
                  <li className="">
                    {rlang === "hi" ? "जीएसटी संख्या" : "GST No"}
                  </li>
                  <li className="">{storeData.gstNumber || "- - None - -"}</li>
                </div>
              </ul>
            </div>
            <div className="flex flex-row justify-between w-full  md:px-4 mb-4 mt-4 cursor-pointer">
              <div
                className="bg-theme-blue px-4 py-2 rounded-md text-white"
                onClick={AllowNotification}
              >
                {rlang === "hi"
                  ? "अधिसूचना की अनुमति दें"
                  : "Allow Notification"}
              </div>
              <div
                className="bg-theme-blue px-4 py-2 rounded-md text-white cursor-pointer"
                onClick={() => setEditMode(true)}
              >
                {rlang === "hi" ? "संपादन करना" : "Edit"}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
