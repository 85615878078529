import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetStoreData } from "../../../fetures/store.slice";
import { logoutUser } from "../../../fetures/user.slice";
import { CgProfile } from "react-icons/cg";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { FaRegIdCard } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { RiContactsLine } from "react-icons/ri";
import { FcRules } from "react-icons/fc";
import { FaBell } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { MdDeleteForever } from "react-icons/md"; // Import icon for delete
import axios from "axios";

const SideNavbar = () => {
  const tab = window.location.href.split("/")[4];
  const [activeTab, setActiveTab] = useState(tab);
  const { userStatus } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const rlang = useSelector((state) => state.user.lang);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const shopId = useSelector((state) => state.user.userData.shopId);
  const firebaseId = useSelector((state) => state.user.userData.userId);
  console.log(shopId, firebaseId, "shopId firebaseId");

  useEffect(() => {
    switch (userStatus) {
      case "UNAUTHORIZED" || "User Not Existed":
        return navigate("/login");
      case "DOMAIN":
        return navigate("/domain");
      case "BUSSINESS":
        return navigate("/bussiness");
      case "FIRSTPRODUCT":
        return navigate("/product");
      case "PAYMENT":
        return navigate("/payment");
      default:
        break;
    }
  });

  const handleDeleteAccount = async () => {
    const reponse = await axios.post(
      "https://backend.digistall.in/user/deleteUserPermanently",
      {
        shopId: shopId,
        firebaseId: firebaseId,
      }
    );
    if (reponse.status === 200) {
      console.log("Account Deleted Sucesfully");
      setIsDeletePopupOpen(false);
      dispatch(logoutUser());
      dispatch(resetStoreData());
    }
  };

  return (
    <div className="relative md:min-h-screen bg-white text-gray-800 w-full">
      <div className="p-4 flex w-full justify-between  md:hidden bg-[#F5F5F5]">
        {activeTab == null ? (
          <>
            <h1 className="text-xl font-bold">
              {rlang === "hi" ? "प्रोफाइल" : "Profile"}
            </h1>
          </>
        ) : (
          <>
            <h1 className="text-xl font-bold">{activeTab}</h1>
          </>
        )}

        <button className="text-2xl" onClick={() => setIsOpen(!isOpen)}>
          &#9776;
        </button>
      </div>
      <div
        className={`fixed inset-y-0 left-0 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:relative md:translate-x-0 md:flex md:flex-col  p-4 bg-white shadow-lg z-50`}
      >
        <ul className="space-y-4">
          <li
            className={`${
              activeTab === undefined
                ? "bg-[#0293A8] text-white"
                : "bg-[#F5F5F5] text-gray-800"
            } px-4 py-2 rounded-lg cursor-pointer`}
            onClick={() => {
              navigate("/profile");
              setActiveTab();
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2">
              {rlang === "hi" ? "प्रोफाइल की जानकारी" : "Profile Information"}{" "}
              <CgProfile className="mt-1" />
            </div>
          </li>
          <li
            className={`${
              activeTab === "subscription"
                ? "bg-[#0293A8] text-white"
                : "bg-[#F5F5F5] text-gray-800"
            } px-4 py-2 rounded-lg cursor-pointer`}
            onClick={() => {
              navigate("/profile/subscription");
              setActiveTab("subscription");
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2">
              {rlang === "hi" ? "अंशदान" : "Subscription"}

              <BiPurchaseTagAlt className="mt-1" />
            </div>
          </li>
          <li
            className={`${
              activeTab === "card"
                ? "bg-[#0293A8] text-white"
                : "bg-[#F5F5F5] text-gray-800"
            } px-4 py-2 rounded-lg cursor-pointer`}
            onClick={() => {
              navigate("/profile/card");
              setActiveTab("card");
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2">
              {rlang === "hi" ? "मेरे कार्ड" : "my card"}

              <FaRegIdCard className="mt-1" />
            </div>
          </li>
          <li
            className={`${
              activeTab === "payment"
                ? "bg-[#0293A8] text-white"
                : "bg-[#F5F5F5] text-gray-800"
            } px-4 py-2 rounded-lg cursor-pointer`}
            onClick={() => {
              navigate("/profile/payment");
              setActiveTab("payment");
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2">
              {rlang === "hi" ? "भुगतान" : "Payment"}

              <FaRupeeSign className="mt-1" />
            </div>
          </li>
          <li
            className={`${
              activeTab === "contact"
                ? "bg-[#0293A8] text-white"
                : "bg-[#F5F5F5] text-gray-800"
            } px-4 py-2 rounded-lg cursor-pointer`}
            onClick={() => {
              navigate("/profile/contact");
              setActiveTab("contact");
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2">
              {rlang === "hi" ? "संपर्क करें" : " Contact Us"}

              <RiContactsLine className="mt-1" />
            </div>
          </li>
          <li
            className={`${
              activeTab === "terms"
                ? "bg-[#0293A8] text-white"
                : "bg-[#F5F5F5] text-gray-800"
            } px-4 py-2 rounded-lg cursor-pointer`}
            onClick={() => {
              navigate("/profile/terms");
              setActiveTab("terms");
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2">
              {rlang === "hi" ? "नियम व शर्त" : "Terms & Condition"}

              <CgProfile className="mt-1" />
            </div>
          </li>
          {/* <li
            className={`${
              activeTab === "admin"
                ? "bg-[#0293A8] text-white"
                : "bg-[#F5F5F5] text-gray-800"
            } px-4 py-2 rounded-lg cursor-pointer`}
            onClick={() => {
              navigate("/profile/admin");
              setActiveTab("admin");
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2">
              {rlang === "hi" ? "व्यवस्थापक" : "Admin"}

              <FcRules className="mt-1" />
            </div>
          </li> */}
          <li
            className={`${
              activeTab === "appointment"
                ? "bg-[#0293A8] text-white"
                : "bg-[#F5F5F5] text-gray-800"
            } px-4 py-2 rounded-lg cursor-pointer`}
            onClick={() => {
              navigate("/profile/appointment");
              setActiveTab("appointment");
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2">
              {rlang === "hi" ? "नियुक्ति" : "Appointment"}

              <FaBell className="mt-1" />
            </div>
          </li>
        </ul>
        <a href="https://digistall.in" className="block mt-4">
          <button
            type="button"
            className="bg-red-600 text-white px-4 py-2 rounded-lg w-full mt-4"
            onClick={async () => {
              dispatch(logoutUser());
              dispatch(resetStoreData());
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2">
              {rlang === "hi" ? "लॉग आउट" : "Logout"}

              <CiLogout className="mt-1" />
            </div>
          </button>
        </a>
        <div
          className="px-4 py-2 rounded-lg cursor-pointer  bg-red-600 mt-4 text-white"
          onClick={() => setIsDeletePopupOpen(true)}
        >
          <div className="flex gap-2">
            {rlang === "hi"
              ? "खाता स्थायी रूप से हटाएँ"
              : "Delete Account Permanently"}
            <MdDeleteForever className="mt-1" />
          </div>
        </div>
      </div>
      {isDeletePopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-md">
            <h2 className="text-xl font-bold mb-4">
              {rlang === "hi"
                ? "खाता स्थायी रूप से हटाएँ"
                : "Delete Account Permanently"}
            </h2>
            <p className="mb-6">
              {rlang === "hi"
                ? "क्या आप सुनिश्चित हैं कि आप अपना खाता स्थायी रूप से हटाना चाहते हैं?"
                : "Are you sure you want to permanently delete your account?"}
            </p>
            <div className="flex justify-end gap-4">
              <button
                type="button"
                className="bg-red-600 text-white px-4 py-2 rounded-lg"
                onClick={handleDeleteAccount}
              >
                {rlang === "hi" ? "हां, हटाएँ" : "Yes, Delete"}
              </button>
              <button
                type="button"
                className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg"
                onClick={() => setIsDeletePopupOpen(false)}
              >
                {rlang === "hi" ? "रद्द करें" : "Cancel"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideNavbar;
