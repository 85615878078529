import React, { useState } from "react";
import "./sideNav.scss";
import { Link } from "react-router-dom";

export default function SideNav() {
    const [view, setView] = useState("desktop")     // setup setView function
    return (
        <>
            {
                view === "desktop"
                    ?
                    <div className="side-nav">
                        <a href="#header" className="nav-item">
                            Header
                        </a>
                        <a href="#team" className="nav-item">
                            Our Team
                        </a>
                        <a href="#buttons" className="nav-item">
                            Buttons
                        </a>
                        <a href="#footer" className="nav-item">
                            Footer
                        </a>
                    </div>
                    :
                    <div className="side-nav">

                        <Link to='/' className="nav-item">  {/* setup individual pages */}    
                            <span>Header</span>
                            <img src="." alt="side nav icons" className='imgs' />
                        </Link>

                        <Link to='/' className="nav-item">
                            <span>Our Team</span>
                            <img src="." alt="side nav icons" className='imgs' />
                        </Link>

                        <Link to='/' className="nav-item">
                            <span>Buttons</span>
                            <img src="." alt="side nav icons" className='imgs' />
                        </Link>

                        <Link to='/' className="nav-item">
                            <span>Footer</span>
                            <img src="." alt="side nav icons" className='imgs' />
                        </Link>

                    </div>
            }
        </>
    );
}
