import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateStore } from "../../../fetchCalles";
import { getStoreData } from "../../../fetures/store.slice";
import { v4 } from "uuid";
import Loader from "../../Loading";
import uploadImg from '../../../img/filePlus.png'
import './terms.scss'

const Terms = () => {
    const { storeData } = useSelector(state => state.store)
    const { shopId, settings } = storeData
    const {termsAndConditions} = settings
    const [terms, setTerms] = useState(termsAndConditions.termsAndConditions)
    const [termsFile, setTermsFile] = useState()
    const [msme, setMsme] = useState(termsAndConditions.msmeCertificate)
    const [msmeFile, setMsmeFile] = useState()
    const [uploading, setUploading] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const uploadImage = async (file) => {
        let responseURL
        const storage = getStorage();
        const location = storeData.shopId + '-' + v4()
        const storageRef = ref(storage, "images" + location);
        await uploadBytes(storageRef, file).then(async (snapshot) => {
            await getDownloadURL(snapshot.ref).then(async (url) => {
                responseURL = url
            })
        })
        return await responseURL
    }

    const saveChanges = async () => {
        setLoading(true)
        const updatedTerms = {...termsAndConditions}
        if (termsFile) {
            const termsUrl = await uploadImage(termsFile)
            updatedTerms.termsAndConditions = termsUrl
        }
        if (msmeFile) {
            const msmeUrl = await uploadImage(msmeFile)
            updatedTerms.msmeCertificate = msmeUrl
        }
        try {
            await updateStore({ ...storeData, settings: { ...storeData.settings, termsAndConditions: updatedTerms } })
            dispatch(getStoreData(storeData.shopId))
            setTermsFile()
            setMsmeFile()
            setLoading(false)
        } catch (e) {
            alert('Something went wrong..')
            setLoading(false)
        }
    }
  

    return (
        <div className="terms-bg-con">
            {loading ? <Loader /> :
                <div className="terms-msme-con">
                    <div className="terms-cont">
                        <h1 className="heading">Terms & Conditions</h1>
                        <label htmlFor="tearms" className='uploads-label'>
                            {terms !== "NO Terms & Cunditions." ?
                                <img src={terms} className='terms-msme-image' />
                                :
                                <div className='empty-con'>
                                    <img src={uploadImg} alt="upload" size={30} />
                                    <p className='texts'>Upload photo</p>
                                </div>}
                            <input id="tearms" type="file" className='hidden' onChange={(e) => { setTerms(URL.createObjectURL(e.target.files[0])); setTermsFile(e.target.files[0]) }} />
                        </label>
                    </div>
                    <div className="terms-cont">
                        <h1 className="heading">MSME Certificate</h1>
                        <label htmlFor="msme" className='uploads-label'>
                            {msme !== "www.blankpage.com" ?
                                <img src={msme} className='terms-msme-image' />
                                :
                                <div className='empty-con'>
                                    <img src={uploadImg} alt="upload" size={30} />
                                    <p className='texts'>Upload photo</p>
                                </div>
                            }
                            <input id="msme" type="file" className='hidden' onChange={(e) => { setMsme(URL.createObjectURL(e.target.files[0])); setMsmeFile(e.target.files[0]) }} />
                        </label>
                    </div>
                </div>}
            {!loading && <>{(termsFile || msmeFile) && <div><button type="button" className="cancelBtn" onClick={() => { setTerms(termsAndConditions.termsAndConditions); setTermsFile(); setMsme(termsAndConditions.msmeCertificate); setMsmeFile() }}>Cancel</button><button type="button" className="saveBtn" onClick={saveChanges}>Save</button></div>}</>}
            {(!termsFile || !msmeFile) && <p className="note"><span className="note-h">Note</span> : To update image click on the existing image, select new and save.</p>}
        </div>
    )
}

export default Terms