import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Header/header";
import {
  AiOutlineCloudUpload,
  AiOutlineInstagram,
  AiFillYoutube,
} from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import { BiHeart, BiMobile, BiUser } from "react-icons/bi";
import { BsFacebook } from "react-icons/bs";
import { FiShoppingBag, FiUpload, FiLink2 } from "react-icons/fi";
import { RxPerson } from "react-icons/rx";
import { GoDeviceDesktop } from "react-icons/go";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateStore } from "../../fetchCalles";
import { getStoreData } from "../../fetures/store.slice";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { intlFormatDistance } from "date-fns";
import mob from "../../img/mobTemp.png";
import web from "../../img/webTemp.png";
import bag from "../../img/bag.png";
import ama from "../../img/ama.png";
import fli from "../../img/fli.png";
import duk from "../../img/duk.png";
import mee from "../../img/mee.png";
import aji from "../../img/aji.png";
import int from "../../img/int.png";
import "./editor.scss";
import ExpiryView from "../expireView/expiryView";
import SideNav from "./editorComponents/SideNav/SideNav";

const Editor = () => {
  const { storeData } = useSelector((state) => state.store);
  const { userStatus } = useSelector((state) => state.user);
  const [desktop, setDesktop] = useState(false);
  const [store, setStore] = useState(storeData);
  const [newImage, setNewImage] = useState();
  const [newImageFile, setNewImageFile] = useState();
  const [terms, setTerms] = useState(
    store.settings.termsAndConditions.termsAndConditions
  );
  const [termsfile, setTermsFile] = useState();
  const [msme, setMsme] = useState(
    store.settings.termsAndConditions.msmeCertificate
  );
  const [msmefile, setMsmeFile] = useState();
  const [activeBanner, setActiveBanner] = useState(
    store.settings.welcomeBanner.bannerImages[0]
  );
  const [img1, setImg1] = useState(
    store.settings.welcomeBanner.bannerImages[0]
  );
  const [img2, setImg2] = useState(
    store.settings.welcomeBanner.bannerImages[1]
  );
  const [img3, setImg3] = useState(
    store.settings.welcomeBanner.bannerImages[2]
  );
  const [btnImg, setBtnImg] = useState(storeData.settings.buttonsImg);
  const [btnImgFile, setButtonImgFile] = useState();
  const [insta, setInsta] = useState(storeData.settings.footer.instgramUrl);
  const [Facebook, setFacebook] = useState(
    storeData.settings.footer.facebookUrl
  );
  const [youtube, setYoutube] = useState(storeData.settings.footer.youtubeUrl);
  const [imgf1, setImgf1] = useState();
  const [imgf2, setImgf2] = useState();
  const [imgf3, setImgf3] = useState();
  const [teamList, setTeamList] = useState(store.settings.team);
  const sumo = useSelector((state) => state.store.storeData.sumo);
  const [buttonsList, setButtonsList] = useState(
    store.settings.buttons.length === 0
      ? [
          {
            id: v4(),
            buttonIcon: "AMA",
            buttonVisibility: true,
            buttonUrl: "https://",
          },
          {
            id: v4(),
            buttonIcon: "FLI",
            buttonVisibility: true,
            buttonUrl: "https://",
          },
          {
            id: v4(),
            buttonIcon: "AJI",
            buttonVisibility: true,
            buttonUrl: "https://",
          },
          {
            id: v4(),
            buttonIcon: "MEE",
            buttonVisibility: true,
            buttonUrl: "https://",
          },
          {
            id: v4(),
            buttonIcon: "DUK",
            buttonVisibility: true,
            buttonUrl: "https://",
          },
          {
            id: v4(),
            buttonIcon: "INT",
            buttonVisibility: true,
            buttonUrl: "https://",
          },
        ]
      : store.settings.buttons
  );
  const [buttonsDiscription, setButtonsDiscription] = useState(
    store.settings.buttonsDiscription
  );
  const profileImage = useSelector(
    (state) => state.store.storeData.settings.header.logo
  );
  console.log("profileImage", profileImage);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let validUntill = "1";
  if (userStatus === "COMPLETED") {
    if (!sumo || sumo === false) {
      validUntill = intlFormatDistance(
        new Date(storeData.validTill),
        new Date(),
        { numeric: "always", unit: "day" }
      ).split(" ")[1];
    }
  }

  const uploadImage = async (file) => {
    let responseURL;
    const storage = getStorage();
    const location = storeData.shopId + "-" + v4();
    const storageRef = ref(storage, "images" + location);
    await uploadBytes(storageRef, file).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then(async (url) => {
        responseURL = url;
      });
    });
    return await responseURL;
  };

  const updateStoreData = async (store) => {
    try {
      await updateStore(store);
      setProgress(95);
      setLoading(false);
      // navigate("/");
      dispatch(getStoreData(store.shopId));
    } catch (e) {
      alert("Something went wrong...");
      setLoading(false);
    }
  };

  const updateTermsAndMSME = async (storeData) => {
    const termsAndConditions = { ...store.settings.termsAndConditions };
    if (termsfile) {
      const url = await uploadImage(termsfile);
      termsAndConditions["termsAndConditions"] = url;
    }
    setProgress(80);
    if (msmefile) {
      const url = await uploadImage(msmefile);
      termsAndConditions["msmeCertificate"] = url;
    }
    setProgress(90);
    storeData = {
      ...storeData,
      settings: {
        ...storeData.settings,
        termsAndConditions: termsAndConditions,
        footer: {
          ...storeData.settings.footer,
          facebookUrl: Facebook,
          instgramUrl: insta,
          youtubeUrl: youtube,
        },
      },
    };
    updateStoreData(storeData);
  };

  const updateTeam = async (updatedStore) => {
    let storeData = Object.assign({}, updatedStore);
    let updatedTeam = [];
    if (teamList.length !== 0) {
      updatedTeam = await Promise.all(
        teamList.map(async (each) => {
          if (each.imgFile) {
            const url = await uploadImage(each.imgFile);
            return { ...each, imgUrl: url };
          } else {
            return each;
          }
        })
      );
    }
    if (btnImgFile) {
      let newBtnImgUrl = await uploadImage(btnImgFile);
      storeData = {
        ...storeData,
        settings: { ...storeData.settings, buttonsImg: newBtnImgUrl },
      };
      setBtnImg((prev) => newBtnImgUrl);
    }
    storeData = {
      ...storeData,
      settings: { ...storeData.settings, team: updatedTeam },
    };
    storeData = {
      ...storeData,
      settings: {
        ...storeData.settings,
        buttons: buttonsList,
        buttonsDiscription,
      },
    };
    setProgress(70);
    updateTermsAndMSME(storeData);
  };

  const onSave = async () => {
    setLoading(true);
    setProgress(0);
    let updatedStore = Object.assign({}, store);
    if (newImage) {
      const url = await uploadImage(newImageFile);
      updatedStore = {
        ...updatedStore,
        settings: {
          ...updatedStore.settings,
          header: { ...updatedStore.settings.header, logo: url },
        },
      };
    }
    setProgress(10);
    if (imgf1) {
      const url = await uploadImage(imgf1);
      const banners = [...updatedStore.settings.welcomeBanner.bannerImages];
      if (banners.length === 0) {
        banners.push(url);
      } else {
        banners.splice(0, 1, url);
      }
      updatedStore = {
        ...updatedStore,
        settings: {
          ...updatedStore.settings,
          welcomeBanner: {
            ...updatedStore.settings.welcomeBanner,
            bannerImages: banners,
          },
        },
      };
    }
    setProgress(20);
    if (imgf2) {
      const url = await uploadImage(imgf2);
      const banners = [...updatedStore.settings.welcomeBanner.bannerImages];
      if (banners.length <= 1) {
        banners.push(url);
      } else {
        banners.splice(1, 1, url);
      }
      updatedStore = {
        ...updatedStore,
        settings: {
          ...updatedStore.settings,
          welcomeBanner: {
            ...updatedStore.settings.welcomeBanner,
            bannerImages: banners,
          },
        },
      };
    }
    setProgress(30);
    if (imgf3) {
      const url = await uploadImage(imgf3);
      const banners = [...updatedStore.settings.welcomeBanner.bannerImages];
      if (banners.length <= 2) {
        banners.push(url);
      } else {
        banners.splice(2, 1, url);
      }
      updatedStore = {
        ...updatedStore,
        settings: {
          ...updatedStore.settings,
          welcomeBanner: {
            ...updatedStore.settings.welcomeBanner,
            bannerImages: banners,
          },
        },
      };
    }
    setProgress(40);
    updateTeam(updatedStore);
  };

  const uploadtermsHandler = (e) => {
    setTerms(URL.createObjectURL(e.target.files[0]));
    setTermsFile(e.target.files[0]);
  };

  const uploadmsmeHandler = (e) => {
    setMsme(URL.createObjectURL(e.target.files[0]));
    setMsmeFile(e.target.files[0]);
  };

  return (
    <>
      {validUntill > 0 ? (
        <>
          {loading ? (
            <div className="progress-con">
              <meter
                className="progress-bar"
                min="0"
                max="100"
                low="30"
                high="60"
                optimum="70"
                value={progress}
              ></meter>
              <h1 className="progrees-status">{progress} %</h1>
            </div>
          ) : (
            <>
              <Header />
              <div className="bg-container">
                <SideNav />

                {/*  
                                    keep his desktop views as it is
                                    but need to change mobile view as showin in HRI App figma UI
                                */}

                <div className="editor">
                  <div className="controlers-container">
                    <div className="controlerBtnContainer">
                      <button
                        className="view-btn"
                        type="button"
                        onClick={() => setDesktop((prev) => !prev)}
                      >
                        {desktop ? <GoDeviceDesktop /> : <BiMobile />}
                      </button>
                      {/* <BsArrow90DegLeft className="controller" />
                                <BsArrow90DegRight className="controller" /> */}
                      <button
                        type="button"
                        onClick={onSave}
                        className="controller-btn"
                      >
                        SAVE
                      </button>
                    </div>
                    {desktop ? (
                      <p className="reminder">
                        Use Large Screen Device For Desktop View Settings
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    id="header"
                    className="temp-container"
                    style={{ width: `${desktop ? "960px" : "380px"}` }}
                  >
                    <div
                      className="contact-data"
                      style={{ padding: `${desktop ? "0vw 5vw" : "0vw 1vw"}` }}
                    >
                      <input
                        type="email"
                        style={{ width: `${desktop ? "20%" : "30%"}` }}
                        className="email"
                        placeholder="E-Mail"
                        value={store.settings.header.email}
                        onChange={(e) =>
                          setStore((prev) => ({
                            ...prev,
                            settings: {
                              ...prev.settings,
                              header: {
                                ...prev.settings.header,
                                email: e.target.value,
                              },
                            },
                          }))
                        }
                      />
                      <FaUserEdit />
                      <input
                        type="text"
                        style={{ width: `${desktop ? "20%" : "30%"}` }}
                        className="phone-number"
                        placeholder="Phone Number"
                        value={store.settings.header.phoneNumber}
                        onChange={(e) =>
                          setStore((prev) => ({
                            ...prev,
                            settings: {
                              ...prev.settings,
                              header: {
                                ...prev.settings.header,
                                phoneNumber: e.target.value,
                              },
                            },
                          }))
                        }
                      />
                    </div>
                    <nav
                      className="header-container"
                      style={{ padding: `${desktop ? "0vw 5vw" : "0vw 1vw"}` }}
                    >
                      <div className="logo-store-name-con">
                        <label className="image-label" htmlFor="logo">
                          <input
                            type="file"
                            id="logo"
                            hidden
                            onChange={(e) => {
                              setNewImage(
                                URL.createObjectURL(e.target.files[0])
                              );
                              setNewImageFile(e.target.files[0]);
                            }}
                          />
                          {/* <div className="add-con">
                            <AiOutlineCloudUpload className="add-logo" />
                            <p className="add-text">Add Logo</p>
                          </div> */}

                          {!newImage ? (
                            <img
                              src={profileImage}
                              alt="logo"
                              className="logo"
                            />
                          ) : (
                            <img src={newImage} alt="logo" className="logo" />
                          )}
                          {/* {profileImage && (
                            <img
                              src={profileImage}
                              alt="logo"
                              className="logo"
                            />
                          )}
                          {newImage && (
                            <img src={newImage} alt="logo" className="logo" />
                          )} */}
                        </label>

                        {/* <img src={profileImage} alt="logo" className="logo" /> */}
                        <input
                          className="store-name"
                          placeholder="Store Name"
                          style={{ width: `${desktop ? "70%" : "50%"}` }}
                          type="text"
                          value={store.settings.header.shopName}
                          onChange={(e) =>
                            setStore((pre) => ({
                              ...pre,
                              settings: {
                                ...pre.settings,
                                header: {
                                  ...pre.settings.header,
                                  shopName: e.target.value,
                                },
                              },
                            }))
                          }
                        />
                      </div>
                      <div className="tabs">
                        <RxPerson className="tab" />
                        <BiHeart className="tab" />
                        <FiShoppingBag className="tab" />
                      </div>
                    </nav>
                    <div className="banner-bg-container">
                      <div
                        className="banner"
                        style={{
                          backgroundImage: `url(${activeBanner})`,
                          height: `${desktop ? "65vh" : "40vh"}`,
                        }}
                      >
                        <div className="banner-tab">
                          {img1 ? (
                            <img
                              src={img1}
                              alt="logo"
                              onClick={() => setActiveBanner(img1)}
                              className="tab-img"
                            />
                          ) : (
                            <label className="image-label" htmlFor="banner-1">
                              <input
                                type="file"
                                id="banner-1"
                                hidden
                                onChange={(e) => {
                                  setImg1(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                  setImgf1(e.target.files[0]);
                                }}
                              />
                              <div className="add-con">
                                <AiOutlineCloudUpload className="add-logo" />
                                <p className="add-text">Add Photo</p>
                              </div>
                            </label>
                          )}
                          <button
                            className="remove-btn"
                            onClick={() => {
                              setImg1();
                              setImgf1();
                            }}
                            type="button"
                          >
                            X
                          </button>
                        </div>
                        <div className="banner-tab">
                          {img2 ? (
                            <img
                              src={img2}
                              alt="logo"
                              onClick={() => setActiveBanner(img2)}
                              className="tab-img"
                            />
                          ) : (
                            <label className="image-label" htmlFor="banner-2">
                              <input
                                type="file"
                                id="banner-2"
                                hidden
                                onChange={(e) => {
                                  setImg2(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                  setImgf2(e.target.files[0]);
                                }}
                              />
                              <div className="add-con">
                                <AiOutlineCloudUpload className="add-logo" />
                                <p className="add-text">Add Photo</p>
                              </div>
                            </label>
                          )}
                          <button
                            className="remove-btn"
                            onClick={() => {
                              setImg2();
                              setImgf2();
                            }}
                            type="button"
                          >
                            X
                          </button>
                        </div>
                        <div className="banner-tab">
                          {img3 ? (
                            <img
                              src={img3}
                              alt="logo"
                              onClick={() => setActiveBanner(img3)}
                              className="tab-img"
                            />
                          ) : (
                            <label className="image-label" htmlFor="banner-3">
                              <input
                                type="file"
                                id="banner-3"
                                hidden
                                onChange={(e) => {
                                  setImg3(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                  setImgf3(e.target.files[0]);
                                }}
                              />
                              <div className="add-con">
                                <AiOutlineCloudUpload className="add-logo" />
                                <p className="add-text">Add Photo</p>
                              </div>
                            </label>
                          )}
                          <button
                            className="remove-btn"
                            onClick={() => {
                              setImg3();
                              setImgf3();
                            }}
                            type="button"
                          >
                            X
                          </button>
                        </div>
                      </div>
                    </div>
                    {desktop ? (
                      <img src={web} alt="temp" />
                    ) : (
                      <img src={mob} alt="temp" />
                    )}
                    <div className="team-container" id="team">
                      <div className="team-detail-container">
                        <div
                          className="team-h-bg-con"
                          style={{
                            justifyContent: `${
                              !desktop ? "center" : "flex-end"
                            }`,
                          }}
                        >
                          <div
                            className="team-h-con"
                            style={{
                              flexDirection: `${!desktop ? "column" : "row"}`,
                              justifyContent: `${
                                !desktop ? "center" : "space-between"
                              }`,
                            }}
                          >
                            <p className="team-heading">Meet the team</p>
                            <button
                              type="button"
                              onClick={() =>
                                setTeamList((prev) => [
                                  ...prev,
                                  {
                                    imgUrl: "",
                                    memberVisibility: true,
                                    name: "",
                                    role: "",
                                  },
                                ])
                              }
                              className="add-btn"
                            >
                              + ADD
                            </button>
                          </div>
                        </div>

                        <ul className="teams">
                          {teamList.length !== 0 ? (
                            <>
                              {teamList.map((each, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="team-member-details"
                                    style={{
                                      width: `${!desktop ? "22vh" : "22%"}`,
                                      marginLeft: `${
                                        !desktop ? "0.5rem" : "1rem"
                                      }`,
                                      marginRight: `${
                                        !desktop ? "0.5rem" : "1rem"
                                      }`,
                                    }}
                                  >
                                    <div className="team-member-img-div">
                                      <div className="without-image">
                                        <label
                                          htmlFor={index}
                                          className="without-img-uploads"
                                        >
                                          {each.imgUrl ? (
                                            <div>
                                              <img
                                                src={each.imgUrl}
                                                alt="team"
                                                className="team-member-image"
                                              />
                                            </div>
                                          ) : (
                                            <div className="iconss">
                                              <BiUser size={40} />
                                              <p className="text">
                                                Upload photo
                                              </p>
                                            </div>
                                          )}
                                          <input
                                            id={index}
                                            type="file"
                                            className="hidden"
                                            onChange={(e) => {
                                              let updatedList = [...teamList];
                                              updatedList[index] = {
                                                ...updatedList[index],
                                                imgUrl: URL.createObjectURL(
                                                  e.target.files[0]
                                                ),
                                              };
                                              updatedList[index] = {
                                                ...updatedList[index],
                                                imgFile: e.target.files[0],
                                              };
                                              setTeamList(updatedList);
                                            }}
                                          />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="input-details">
                                      <div className="input-box">
                                        <input
                                          type="text"
                                          maxLength={16}
                                          id="add"
                                          style={{ textAlign: "center" }}
                                          value={teamList[index].name}
                                          onChange={(e) => {
                                            let updatedList = [...teamList];
                                            updatedList[index] = {
                                              ...updatedList[index],
                                              name: e.target.value,
                                            };
                                            setTeamList(updatedList);
                                          }}
                                          placeholder="Name"
                                          className="input-name"
                                        />
                                      </div>
                                      <div className="input-box">
                                        <input
                                          type="text"
                                          id="add"
                                          maxLength={16}
                                          style={{ textAlign: "center" }}
                                          value={teamList[index].role}
                                          onChange={(e) => {
                                            let updatedList = [...teamList];
                                            updatedList[index] = {
                                              ...updatedList[index],
                                              role: e.target.value,
                                            };
                                            setTeamList(updatedList);
                                          }}
                                          placeholder="Position"
                                          className="input-position"
                                        />
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            <div
                              style={{ marginLeft: "1em" }}
                              onClick={() =>
                                setTeamList([
                                  {
                                    imgUrl: "",
                                    memberVisibility: true,
                                    name: "",
                                    role: "",
                                  },
                                ])
                              }
                            >
                              <div className="addteam">
                                <h1 className="plus">+</h1>
                                <p>Add Team Member</p>
                              </div>
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>
                    {/* <div className="button-container" id="buttons">
                                            <h1 className="headin">Join the Conversation!</h1>
                                            <div className="without-heading" style={{ display: `${!desktop ? 'flex' : 'grid'}`, flexDirection: `${!desktop ? 'column' : 'row'}` }}>
                                                {
                                                    btnImgFile ? <img src={btnImg} alt='button bg' className="bag" style={{ height: `${!desktop ? '40vh' : '49vh'}` }} /> :
                                                        <label htmlFor="button-img" className="btn-label">
                                                            <FiUpload size={20} />
                                                            <p style={{ fontSize: '16px', marginTop: '12px' }}>Upload photo</p>
                                                            <input id='button-img' type="file" className='hidden' onChange={(e) => { const locUrl = URL.createObjectURL(e.target.files[0]); setButtonImgFile(e.target.files[0]); setBtnImg(locUrl) }} />
                                                        </label>
                                                }
                                                <div className="button-details" style={{ marginTop: `${!desktop ? '2rem' : '0'}` }}>
                                                    <textarea className="text-area-store-btn" rows={5} maxLength={180} type="text" value={buttonsDiscription} onChange={(e) => setButtonsDiscription(e.target.value)} id="storebtn" placeholder="Type something...." />
                                                    <ul className="button-list" style={{ display: `${!desktop ? 'flex' : 'grid'}`, flexDirection: `${!desktop ? 'column' : 'row'}` }}>
                                                        <li className="button-description">
                                                            <label htmlFor="ama"><img src={ama} alt="amazon" className="btn-icon" /></label>
                                                            <input id="ama" type="text" value={buttonsList[0].buttonUrl} onChange={(e) => { let updatedBtns = [...buttonsList]; updatedBtns[0] = { ...updatedBtns[0], buttonUrl: e.target.value }; setButtonsList(updatedBtns) }} className="btn-input" />
                                                        </li>
                                                        <li className="button-description">
                                                            <label htmlFor="fli"><img src={fli} alt="flipkart" className="btn-icon" /></label>
                                                            <input id="fli" type="text" value={buttonsList[1].buttonUrl} onChange={(e) => { let updatedBtns = [...buttonsList]; updatedBtns[1] = { ...updatedBtns[1], buttonUrl: e.target.value }; setButtonsList(updatedBtns) }} className="btn-input" />
                                                        </li>
                                                        <li className="button-description">
                                                            <label htmlFor="aji"><img src={aji} alt="ajio" className="btn-icon" /></label>
                                                            <input id="aji" type="text" value={buttonsList[2].buttonUrl} onChange={(e) => { let updatedBtns = [...buttonsList]; updatedBtns[2] = { ...updatedBtns[2], buttonUrl: e.target.value }; setButtonsList(updatedBtns); }} className="btn-input" />
                                                        </li>
                                                        <li className="button-description">
                                                            <label htmlFor="mee"><img src={mee} alt="meeshow" className="btn-icon" /></label>
                                                            <input id="mee" type="text" value={buttonsList[3].buttonUrl} onChange={(e) => { let updatedBtns = [...buttonsList]; updatedBtns[3] = { ...updatedBtns[3], buttonUrl: e.target.value }; setButtonsList(updatedBtns) }} className="btn-input" />
                                                        </li>
                                                        <li className="button-description">
                                                            <label htmlFor="duk"><img src={duk} alt="dukan" className="btn-icon-duk" /></label>
                                                            <input id="duk" type="text" value={buttonsList[4].buttonUrl} onChange={(e) => { let updatedBtns = [...buttonsList]; updatedBtns[4] = { ...updatedBtns[4], buttonUrl: e.target.value }; setButtonsList(updatedBtns) }} className="btn-input" />
                                                        </li>
                                                        <li className="button-description">
                                                            <label htmlFor="int"><img src={int} alt="other" className="btn-icon" /></label>
                                                            <input id="int" type="text" value={buttonsList[5].buttonUrl} onChange={(e) => { let updatedBtns = [...buttonsList]; updatedBtns[5] = { ...updatedBtns[5], buttonUrl: e.target.value }; setButtonsList(updatedBtns) }} className="btn-input" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> */}
                    <div
                      className="footer-container"
                      id="footer"
                      style={{
                        flexDirection: `${!desktop ? "column" : "row"}`,
                        justifyContent: `${
                          !desktop ? "center" : "space-evenly"
                        }`,
                      }}
                    >
                      <div
                        className="about-address"
                        style={{
                          display: `${!desktop && "flex"}`,
                          width: `${!desktop ? "100%" : "45%"}`,
                          flexDirection: `${!desktop && "column"}`,
                          justifyContent: `${!desktop ? "center" : "normal"}`,
                          alignItems: `${!desktop && "center"}`,
                          marginTop: `${!desktop ? "0rem" : "2rem"}`,
                        }}
                      >
                        <div className="about">
                          <label htmlFor="description" className="label">
                            About {store.settings.header.shopName}
                          </label>
                          <textarea
                            className="text-area"
                            rows={5}
                            cols={100}
                            maxLength={500}
                            value={store.settings.footer.about}
                            onChange={(e) =>
                              setStore((prev) => ({
                                ...prev,
                                settings: {
                                  ...prev.settings,
                                  footer: {
                                    ...prev.settings.footer,
                                    about: e.target.value,
                                  },
                                },
                              }))
                            }
                            type="text"
                            id="description"
                          />
                        </div>
                        <div className="address">
                          <label htmlFor="add" className="label">
                            Address
                          </label>
                          <input
                            type="text"
                            id="add"
                            value={store.settings.footer.address}
                            onChange={(e) =>
                              setStore((prev) => ({
                                ...prev,
                                settings: {
                                  ...prev.settings,
                                  footer: {
                                    ...prev.settings.footer,
                                    address: e.target.value,
                                  },
                                },
                              }))
                            }
                            className="input-value"
                          />
                        </div>
                        {desktop && (
                          <p className="copyright">
                            All Rights Reserved (C)|{store.shopId}.digistall.in
                          </p>
                        )}
                      </div>
                      <div>
                        <div
                          className="terms-msme"
                          style={{
                            display: `${!desktop && "flex"}`,
                            width: `${!desktop ? "90%" : "100%"}`,
                            marginLeft: `${!desktop ? "1.5rem" : "0rem"}`,
                          }}
                        >
                          <div
                            className="terms-condition-msme"
                            style={{ widows: "100%" }}
                          >
                            <p className="heading">Terms & Conditions</p>
                            <div className="without-terms-msme">
                              <label
                                htmlFor="tearms"
                                className="without-uploads"
                              >
                                {terms !== "NO Terms & Cunditions." ? (
                                  <div>
                                    <img
                                      src={terms}
                                      className="terms-msme-image"
                                    />
                                  </div>
                                ) : (
                                  <div className="icons">
                                    <FiUpload size={30} />
                                    <p className="texts">Upload photo</p>
                                  </div>
                                )}
                                <input
                                  id="tearms"
                                  type="file"
                                  className="hidden"
                                  onChange={uploadtermsHandler}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="terms-condition-msme">
                            <p className="heading">MSME Certificate</p>
                            <div className="without-terms-msme">
                              <label htmlFor="msme" className="without-uploads">
                                {msme !== "www.blankpage.com" ? (
                                  <div>
                                    <img
                                      src={msme}
                                      className="terms-msme-image"
                                    />
                                  </div>
                                ) : (
                                  <div className="icons">
                                    <FiUpload size={30} />
                                    <p className="texts">Upload photo</p>
                                  </div>
                                )}
                                <input
                                  id="msme"
                                  type="file"
                                  className="hidden"
                                  onChange={uploadmsmeHandler}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mt-6" style={{ padding: "0px 14px" }}>
                          <div
                            className="flex items-center mt-2"
                            style={{
                              borderBottom: "2px solid #fff",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <FiLink2
                              size={20}
                              className="mr-1"
                              style={{ color: "#fff" }}
                            />
                            <input
                              type="text"
                              value={insta}
                              onChange={(e) => setInsta(e.target.value)}
                              className="social-link"
                              placeholder="Add Link to Your Instagram"
                            />
                            <AiOutlineInstagram
                              size={20}
                              style={{ color: "#fff" }}
                              className="ml-1"
                            />
                          </div>
                          <div
                            className="flex items-center mt-2"
                            style={{
                              borderBottom: "2px solid #fff",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <FiLink2
                              size={20}
                              className="mr-1"
                              style={{ color: "#fff" }}
                            />
                            <input
                              type="text"
                              value={Facebook}
                              onChange={(e) => setFacebook(e.target.value)}
                              className="social-link"
                              placeholder="Add Link to Your Facebook"
                            />
                            <BsFacebook
                              size={15}
                              style={{ color: "#fff" }}
                              className="ml-1"
                            />
                          </div>
                          <div
                            className="flex items-center mt-2"
                            style={{
                              borderBottom: "2px solid #fff",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <FiLink2
                              size={20}
                              className="mr-1"
                              style={{ color: "#fff" }}
                            />
                            <input
                              type="text"
                              value={youtube}
                              onChange={(e) => setYoutube(e.target.value)}
                              className="social-link"
                              placeholder="Add Link to Your Youtube"
                            />
                            <AiFillYoutube
                              size={20}
                              style={{ color: "#fff" }}
                              className="ml-1"
                            />
                          </div>
                        </div>
                      </div>
                      {!desktop && (
                        <p className="copyright-mobile">
                          All Rights Reserved (C) | {store.shopId}.digistall.in
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <ExpiryView />
      )}
    </>
  );
};

export default Editor;
