// import React from "react";
// import logo from "../../../img/land-logo.png";
// import emitralogo from "../../../img/emitraLogo.jpg";
// import { useSelector } from "react-redux";

// function Navbar() {
//   const rlang = useSelector((state) => state.user.lang);

//   return (
//     <nav className="bg-white  w-full">
//       <div className=" mx-auto flex items-center justify-between ">
//         <div className="flex items-center space-x-4">
//           {/* Main Logo */}
//           <img src={emitralogo} alt="Emitra Logo" className="h-8 w-auto" />
//           <img src={logo} alt="HK Logo" className="h-12 w-auto" />
//         </div>

//         <div className="flex space-x-6">
//           <a
//             href="https://digistall.in/emitra/emitra_terms_and_conditions"
//             className="text-gray-700 hover:text-blue-600 transition-colors duration-300"
//           >
//             {rlang === "hi"
//               ? "ई-मित्र शर्तें और शर्तें"
//               : "E-mitra Terms and Conditions"}
//           </a>
//           <a
//             href="https://www.youtube.com/live/VBvzWzAfPvI?si=g33PV08blzgOdSvg"
//             className="text-gray-700 hover:text-blue-600 transition-colors duration-300"
//           >
//             {rlang === "hi" ? "ई-मित्र मार्गदर्शिका" : "Emitra Guide"}
//           </a>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;

import React, { useState } from "react";
import logo from "../../../img/land-logo.png";
import emitralogo from "../../../img/emitraLogo.jpg";
import { useSelector } from "react-redux";

function Navbar() {
  const rlang = useSelector((state) => state.user.lang);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-white w-full md:w-10/12 mx-auto border-b border-gray-300 pb-2 ">
      <div className=" mx-auto w-full flex items-center justify-between">
        <div className="flex items-center space-x-4">
          {/* Main Logo */}
          <img src={emitralogo} alt="Emitra Logo" className="h-8 w-auto" />
          <img src={logo} alt="HK Logo" className="h-12 w-auto" />
        </div>

        {/* Hamburger Menu Button for Mobile */}
        <div className="sm:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-gray-700 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={
                  isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"
                }
              />
            </svg>
          </button>
        </div>

        {/* Links Section for Desktop */}
        <div className="hidden sm:flex space-x-6">
          <a
            href="https://digistall.in/emitra/emitra_terms_and_conditions"
            className="text-gray-700 hover:text-blue-600 transition-colors duration-300 text-sm"
          >
            {rlang === "hi"
              ? "ई-मित्र शर्तें और शर्तें"
              : "E-mitra Terms and Conditions"}
          </a>
          <a
            href="https://www.youtube.com/live/VBvzWzAfPvI?si=g33PV08blzgOdSvg"
            className="text-gray-700 hover:text-blue-600 transition-colors duration-300 text-sm"
          >
            {rlang === "hi" ? "ई-मित्र मार्गदर्शिका" : "Emitra Guide"}
          </a>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="sm:hidden bg-white">
          <div className="flex flex-col space-y-2 p-4">
            <a
              href="https://digistall.in/emitra/emitra_terms_and_conditions"
              className="text-gray-700 hover:text-blue-600 transition-colors duration-300 text-sm"
            >
              {rlang === "hi"
                ? "ई-मित्र शर्तें और शर्तें"
                : "E-mitra Terms and Conditions"}
            </a>
            <a
              href="https://www.youtube.com/live/VBvzWzAfPvI?si=g33PV08blzgOdSvg"
              className="text-gray-700 hover:text-blue-600 transition-colors duration-300 text-sm"
            >
              {rlang === "hi" ? "ई-मित्र मार्गदर्शिका" : "Emitra Guide"}
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
