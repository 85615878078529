import React from 'react';
import Stamp1 from '../../../img/Stamp1.png';
import Stamp2 from '../../../img/Stamp2.png';
import './StampList.scss';
import { Link } from 'react-router-dom';

const StampList=()=>{
  return(
    <div className='stamp-container'>
      <p className='stamp-template'>2 Templates</p>
      <div className='template-stamp-container'>
      <Link to='/tools/stamp1'>
        <div className='Stamps1'>
          <img src={Stamp1} className='stamp1'/>
        </div>
        </Link>
        <Link to='/tools/stamp2'>
        <div className='Stamps2'>
          <img src={Stamp2} className='stamp2'/>
        </div>
        </Link>
      </div>
    </div>
  )
}

export default StampList