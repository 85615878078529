import Digistall_logo from "../../img/Digistall_logo.png";
import { FiEdit, FiTool } from "react-icons/fi";
import { RiShoppingBag3Line } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { AiOutlineHome } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const tabsConst = {
  home: "HOME",
  editor: "EDITOR",
  catalogue: "CATALOGUE",
  tools: "TOOLS",
  profile: "PROFILE",
};

const HeaderProfile = () => {
  let tab = window.location.href.split("/")[3];
  if (tab === "") {
    tab = tabsConst.home;
  } else if (tab === "editor") {
    tab = tabsConst.editor;
  } else if (tab === "catalogue") {
    tab = tabsConst.catalogue;
  } else if (tab === "tools") {
    tab = tabsConst.tools;
  } else {
    tab = tabsConst.profile;
  }
  const navigate = useNavigate();
  const rlang = useSelector((state) => state.user.lang);

  return (
    <div className="relative ">
      <div className="flex justify-between items-center md:px-4 pt-2 bg-white">
        {/* <Link to="/" className="">
          <img src={Digistall_logo} alt="logo" className="w-52 md:w-52" />
        </Link> */}
        <Link to="/" className="hidden md:block">
          <img src={Digistall_logo} alt="logo" className="w-52" />
        </Link>
        <div className="hidden md:flex items-center gap-4 md:gap-6">
          <button
            type="button"
            className={`flex flex-col items-center ${
              tab === tabsConst.home ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/")}
          >
            <AiOutlineHome className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "होम पेज" : "Home"}</span>
          </button>
          <button
            className={`flex flex-col items-center ${
              tab === tabsConst.catalogue ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/catalogue")}
          >
            <RiShoppingBag3Line className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "कैटलॉग" : "Catalogue"}</span>
          </button>
          <button
            type="button"
            className={`flex flex-col items-center ${
              tab === tabsConst.editor ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/editor")}
          >
            <FiEdit className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "एडिटर" : "Editor"}</span>
          </button>
          <button
            type="button"
            className={`flex flex-col items-center ${
              tab === tabsConst.tools ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/tools")}
          >
            <FiTool className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "टूल" : "Tools"}</span>
          </button>
          <button
            type="button"
            className={`flex flex-col items-center ${
              tab === tabsConst.profile ? "text-[#0293A8]" : "text-gray-500"
            }`}
            onClick={() => navigate("/profile")}
          >
            <CgProfile className="text-2xl md:text-4xl" />
            <span>{rlang === "hi" ? "प्रोफाइल" : "Profile"}</span>
          </button>
        </div>
      </div>
      <div className="flex-1"></div>
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md py-2 md:hidden flex justify-around items-center">
        <button
          type="button"
          className={`flex flex-col items-center ${
            tab === tabsConst.home ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/")}
        >
          <AiOutlineHome className="text-2xl" />
          <span className="text-xs">{rlang === "hi" ? "होम पेज" : "Home"}</span>
        </button>
        <button
          className={`flex flex-col items-center ${
            tab === tabsConst.catalogue ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/catalogue")}
        >
          <RiShoppingBag3Line className="text-2xl" />
          <span className="text-xs">
            {rlang === "hi" ? "कैटलॉग" : "Catalogue"}
          </span>
        </button>
        <button
          type="button"
          className={`flex flex-col items-center ${
            tab === tabsConst.editor ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/editor")}
        >
          <FiEdit className="text-2xl" />
          <span className="text-xs">{rlang === "hi" ? "एडिटर" : "Editor"}</span>
        </button>
        <button
          type="button"
          className={`flex flex-col items-center ${
            tab === tabsConst.tools ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/tools")}
        >
          <FiTool className="text-2xl" />
          <span className="text-xs">{rlang === "hi" ? "टूल" : "Tools"}</span>
        </button>
        <button
          type="button"
          className={`flex flex-col items-center ${
            tab === tabsConst.profile ? "text-[#0293A8]" : "text-gray-500"
          }`}
          onClick={() => navigate("/profile")}
        >
          <CgProfile className="text-2xl" />
          <span className="text-xs">
            {rlang === "hi" ? "प्रोफाइल" : "Profile"}
          </span>
        </button>
      </div>
    </div>
  );
};

export default HeaderProfile;
