import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { FaLessThan } from "react-icons/fa";
import Product from "../Product"
import BillProduct from "../Product/product";
import { generateBill } from "../../../../fetchCalles";
import { getUser } from "../../../../fetures/user.slice";
import './BillForm.scss';

const BillForm = () => {
    const { storeData } = useSelector(state => state.store)
    const { userData } = useSelector(state => state.user)
    const {userId} = userData
    let { products } = storeData
    products = products.filter(each => each.productVisibility !== false)
    const [name, setName] = useState()
    const [number, setNumber] = useState()
    const [address, setAddress] = useState()
    const [city, setCIty] = useState()
    const [pincode, setPincode] = useState()
    const [billedProducts, setBilledProducts] = useState([])
    const [productsView, setProdutsView] = useState(false)
    const [catalogue, setCatalogue] = useState(false)
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    async function onClickGenarateBill() {
        if (name) {
            if (number) {
                if (number.length > 9) {
                    if (address) {
                        if (city) {
                            if (pincode) {
                                if (pincode.length === 6) {
                                    if (billedProducts.length > 0) {
                                        setLoading(true)
                                        setError()
                                        const fullAddress = `${name} , ${"+91-" + String(number)}, ${address}, ${city} - ${pincode}, India`
                                        const eachTotal = billedProducts.map(each => each.onePiecePrice * each.productCount)
                                        const total = eachTotal.reduce((total, next) => total + next)
                                        try {
                                            const response = await generateBill(fullAddress, billedProducts, total)
                                            dispatch(getUser(userId))
                                            navigate('/tools/billbook')
                                        } catch (e) {
                                            setError('Something Was wrong try later..')
                                            setLoading(false)
                                        }
                                    } else {
                                        setError('Please select Products')
                                    }
                                } else {
                                    setError('Enter valid Pincode')
                                }
                            } else {
                                setError('Enter Pincode')
                            }
                            
                        } else {
                            setError('Enter City')
                        }
                    } else {
                        setError('Enter Address')
                    }
                } else {
                    setError('Eenter valid Phone Number')
                }
            } else {
               setError('Enter Phone Number') 
            }
        } else {
            setError('Enter Name')
        }
    }

    const billProduct = (product) => {
        const productData = {
            productImage: product.productImage[0],
            productName: product.productName,
            productCategory: product.productCategory,
        }
        setBilledProducts()
    }

    function removeProduct( productId, size, color ) {
        const updatedProductsData = []
        billedProducts.forEach(each => {
            if (each.productId === productId) {
                if (each.hasVariants) {
                    if ((each.size !== size) || (each.color !== color)) {
                        updatedProductsData.push(each)
                    }
                }
            } else {
                updatedProductsData.push(each)
            }
        })
        setBilledProducts(updatedProductsData)
    }

    function decreseCount({ productId, activeSize, activeColor }) {
        const updatedProductsData = []
        billedProducts.forEach(each => {
            if (productId === each.productId) {
                if (each.hasVariants) {
                    if (activeSize === each.size && activeColor === each.color) {
                        if (each.productCount > 1) {
                            each.productCount = each.productCount - 1
                            updatedProductsData.push(each)
                        }
                    } else {
                        updatedProductsData.push(each)
                    }
                } else {
                    if (each.productCount > 1) {
                        each.productCount = each.productCount - 1
                        updatedProductsData.push(each)
                    }
                }
            } else {
                updatedProductsData.push(each)
            }
        })
        setBilledProducts(updatedProductsData)
    }

    function increseCount({productId, activeSize, activeColor}) {
        const updatedProductsData = billedProducts.map(each => {
            if (productId === each.productId) {
                if (each.hasVariants) {
                    if (each.color === activeColor && each.size === activeSize) {
                        if (each.variants[each.size][each.color]['stock'] > each.productCount) {
                            each.productCount = each.productCount + 1
                        } else {
                            alert('max stock reached')
                        }
                        return each
                    } else {
                        return each
                    }
                } else {
                    if (each.productUnits > each.productCount) {
                        each.productCount = each.productCount + 1
                    } else {
                        alert('max stock reached')
                    }
                    return each
                }
            }
            else {
                return each
            }
        })
        setBilledProducts(updatedProductsData)
    }



    const addToBill = (product) => {
        setBilledProducts(billedProducts.concat(product))
    }

    return (
        <div>
            {catalogue ?
                <div className="p-div">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <button type="button"  onClick={() => setCatalogue(false)}><FaLessThan className="less-icon" /></button>
                        <button type="button" className="bill-form-done-btn" onClick={() => setCatalogue(false)}>DONE</button>
                    </div>
                    <div className="prod-catalogue">
                        <ul className="products-catalogue-con">
                            {products.map((eachProduct, index) => <BillProduct key={index} productData={eachProduct} billedProducts={billedProducts} increseCount={increseCount} decreseCount={decreseCount} removeProduct={removeProduct} addToBill={addToBill} />)}
                        </ul>
                    </div>
                </div> :
                <div className="bill-details">
                    <p className="bill-txt">Fill in customer details below to generate a bill</p>
                    <div className="bill-form">
                        {productsView ?
                            <div>
                                <ul>
                                    {products.map(each => <Product productData={each} billProduct={billProduct} billedProducts={billedProducts} key={each.productId} />)}
                                </ul>
                            </div> :
                            <div className="forms">
                                <div className="left-form">
                                    <div className="input-val">
                                        <label htmlFor="name" className="label">Name</label>
                                        <input type='text' id="name"  className="inp input-shadow" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="input-val">
                                        <label htmlFor="number " className="label">Phone Number</label>
                                        <input type='number' id="number"  className="inp input-shadow" value={number} onChange={(e) => setNumber(e.target.value)} />
                                    </div>
                                    <div className="input-val">
                                        <label htmlFor="add" className="label">Address</label>
                                        <input type='text' value={address} onChange={(e) => setAddress(e.target.value)}  className="inp input-shadow" id="add" />
                                    </div>
                                    <div className="hafl-input-val">
                                        <div className="val">
                                            <label htmlFor="city" className="label">City</label>
                                            <input type='text' value={city} onChange={(e) => setCIty(e.target.value)}  className="inp input-shadow" id="city"  />
                                        </div>
                                        <div className="val">
                                            <label htmlFor="pin" className="label">Pin Code</label>
                                            <input type='number' value={pincode} onChange={(e) => setPincode(e.target.value)} className="inp input-shadow"  id="pin"  />
                                        </div>
                                    </div>
                                    {error && <p className="error" style={{color: 'red', fontSize: '14px', textAlign: 'center', marginTop: '12px'}}>{error}</p>}
                                    <div className="buttons-con">
                                        {!loading && <button type="button" onClick={() => navigate('/tools/billbook')} className="bill-back-btn" >Back</button>}
                                        {loading ? <button type="button" className="done-btn">Loading..</button> : <button type="button" onClick={onClickGenarateBill} className="done-btn">Done</button>} 
                                    </div>
                                </div>
                                <div className="right-form">
                                    <p className="pur-txt">Purchase</p>
                                    <div onClick={() => setCatalogue(true)} className="purchase-btn">
                                        <button type="button" className="ad">+</button>
                                        <p className="purchase-div">Add a purchase</p>
                                    </div>
                                    {billedProducts.length !== 0 &&
                                        <ul className="prod-list">
                                            {billedProducts.map((eachProduct, index) => <li key={index} className="prods">{eachProduct.productImages[0] && <img src={eachProduct.productImages[0]} alt="product" className="prod-img"/>}<p className="prod-name">{eachProduct.productName.slice(0, 12)}..</p><p className="prod-qtn"><p className="txt">Qtn:</p>{eachProduct.productCount}</p><p className="prod-size"><p className="txt">Size:</p>{eachProduct.size}</p><div className="prod-color"><p>Color:</p> <div className="color" style={{backgroundColor: eachProduct.color}}></div></div><button type="button" className="prodc-btn" onClick={() => {removeProduct(eachProduct.productId, eachProduct.size, eachProduct.color); console.log(eachProduct)}}>X</button></li>)}
                                        </ul>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>}
        </div>
    )
}

export default BillForm