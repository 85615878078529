import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { RiImageAddFill } from "react-icons/ri";
import { updatePayment } from "../../../fetchCalles";
import { getStoreData } from "../../../fetures/store.slice";
import COD from "../../../img/cod.png";
import Switch from "react-switch";
import { CiEdit } from "react-icons/ci";
import { ImPriceTag } from "react-icons/im";
import "./payment.scss";

const Payment = () => {
  const { storeData } = useSelector((state) => state.store);
  const { shopId, upiId, QRCode } = storeData;
  const [qr, setQr] = useState();
  const [upi, setUpi] = useState(upiId);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [cod, setCod] = useState(storeData.cod);
  const dispatch = useDispatch();

  const saveChnages = async () => {
    setLoading(true);
    const updatedQr = qr ? qr : QRCode;
    try {
      await updatePayment(shopId, updatedQr, upi, cod);
      dispatch(getStoreData(shopId));
      setEditMode(false);
      setLoading(false);
    } catch (e) {
      alert("Something went wrong");
      setLoading(false);
    }
  };

  const uploadImg = async (e) => {
    setUploading(true);
    const storage = getStorage();
    const location = `${shopId}-upi-${e.target.files[0]}`;
    const storageRef = ref(storage, "profile" + location);
    await uploadBytes(storageRef, e.target.files[0]).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then(async (url) => {
        setQr(url);
        setUploading(false);
      });
    });
  };
  useEffect(() => {
    const Price = async () => {
      const response = await fetch(
        "https://backend.digistall.in/user/checkpp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shopId,
          }),
        }
      );

      const data = await response.json();
      // console.log(data);
      if (data.pp) {
        setPp(data.pp);
        // console.log(data.pp);
      } else {
        setPp(20);
      }
    };

    Price();
  }, []);

  const handleDeliveryPrice = async () => {
    const response = await fetch("https://backend.digistall.in/user/updatepp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shopId,
        pp: pp,
      }),
    });

    const data = await response.json();
    console.log(data);
    if (data.status === "success") {
      setPopup(false);
    }
  };
  const [popUp, setPopup] = useState(false);
  const [pp, setPp] = useState("");
  return (
    <div className="payment-con">
      <h1 className="payment-h">Make sure you add the right information</h1>
      {editMode ? (
        <>
          <div className="payment-edit-form">
            <div className="imgContainer">
              <p className="label-text">QR Code</p>
              <label htmlFor="dropzone-file" className="qr-input">
                {qr ? (
                  <img src={qr} alt="qr code" />
                ) : (
                  <>
                    {uploading ? (
                      <h1>Uploading..</h1>
                    ) : (
                      <RiImageAddFill className="add-icon" size={40} />
                    )}
                    <p className="qr-p">Update Your new QR code</p>
                  </>
                )}
                <input
                  id="dropzone-file"
                  type="file"
                  disabled={uploading}
                  className="hidden"
                  onChange={uploadImg}
                />
              </label>
              <div className="upi-input">
                <label htmlFor="upi" className="label-text">
                  Enter your UPI ID
                </label>
                <input
                  type="text"
                  id="upi"
                  className="upiId input-shadow"
                  value={upi}
                  onChange={(e) => setUpi(e.target.value.toLowerCase())}
                />
              </div>
            </div>
            <div className="imgContainer">
              <img src={COD} lat="COD" className="cod-img" />
              <Switch
                onChange={() => setCod(!cod)}
                uncheckedIcon={false}
                checkedIcon={false}
                activeBoxShadow="0 0 2px 3px #0293A8"
                onColor="#0293A8"
                checked={cod}
              />
            </div>
          </div>
          <div className="cancel-save-btn">
            <button
              type="button"
              className="cancelBtn"
              onClick={() => setEditMode(false)}
            >
              Cancel
            </button>
            {loading ? (
              <button className="saveBtn" type="button">
                Loading...
              </button>
            ) : (
              <button className="saveBtn" onClick={saveChnages} type="button">
                Save
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="payment">
            <div className="imgContainer">
              <img src={QRCode} alt="qr code" className="qrcode" />
              <label htmlFor="upi-id" className="upi-id-label">
                UPI ID
              </label>
              <p className="upi-id" id="upi-id">
                {upiId}
              </p>
            </div>
            <div className="imgContainer">
              <img src={COD} alt="cod" className="cod-img" />
              <h1 className="cod">
                Cash on Delivery:{" "}
                {cod ? (
                  <p className="text-green-600 pl-2">Available</p>
                ) : (
                  <p className="text-red-600 pl-2"> Not Available</p>
                )}
              </h1>
            </div>
          </div>
          <button
            type="button"
            className="edit-btn"
            onClick={() => setEditMode(true)}
          >
            Edit
          </button>
        </>
      )}
      <div className="flex flex-row gap-5 border-black border-2 p-4 relative rounded-md">
        {" "}
        <div className="flex flex-row">
          {" "}
          <ImPriceTag className="mt-1 mr-2" />
          <div> Delivery Cost:</div>
        </div>
        <div>{pp}</div>
        <div className="absolute right-0 top-0">
          <CiEdit size={25} onClick={() => setPopup(!popUp)} />
        </div>
      </div>
      {popUp && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-2xl mb-4">Enter Delivery Cost per Order</h2>
            <input
              type="number"
              className="w-full border border-gray-400 p-2 mb-4"
              value={pp}
              onChange={(e) => setPp(e.target.value)}
            ></input>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded  w-full"
              onClick={handleDeliveryPrice}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
