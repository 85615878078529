// import React, { useState, useEffect } from "react";
// import { CSVLink } from "react-csv";

// function Download() {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     fetch("http://localhost:5000/api/product/chai")
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         setProducts(data.products);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//         setLoading(false);
//       });
//   }, []);

//   const csvData = products.map((product) => ({
//     "Product Name": product.productName,
//     "Discount Price": product.productdiscountPrice,
//     "Product Images": product.productImages.join(", "), // Joining images into a comma-separated string
//   }));

//   return (
//     <div>
//       {loading ? (
//         <p>Loading...</p>
//       ) : (
//         <div>
//           <h1>Products</h1>
//           <CSVLink data={csvData} filename={"products.csv"}>
//             Export to CSV
//           </CSVLink>
//           <ul>
//             {products.map((product, index) => (
//               <li key={index}>
//                 <p>Product Name: {product.productName}</p>
//                 <p>Discount Price: {product.productdiscountPrice}</p>
//                 {/* Display product images */}
//                 {product.productImages.map((image, imageIndex) => (
//                   <img
//                     key={imageIndex}
//                     src={image}
//                     alt={`Product Image ${imageIndex + 1}`}
//                   />
//                 ))}
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Download;
import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { CSVLink } from "react-csv";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 200,
    height: 200,
  },
});

function Download() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://backend1-one.vercel.app/api/product/chai")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setProducts(data.products);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const csvData = products.map((product) => ({
    "Product Name": product.productName,
    "Discount Price": product.productdiscountPrice,
    "Product Images": product.productImages.join(", "),
  }));

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <h1>Products</h1>
          <CSVLink data={csvData} filename={"products.csv"}>
            Export to CSV
          </CSVLink>
          <PDFViewer style={{ width: "100%", height: "90vh" }}>
            <Document>
              <Page size="A4" style={styles.page}>
                {products.map((product, index) => (
                  <View key={index}>
                    <Text>Product Name: {product.productName}</Text>
                    <Text>Discount Price: {product.productdiscountPrice}</Text>
                    <Text>
                      Images:{" "}
                      {/* <Image
                        width={90}
                        style={styles.image}
                        src={product.productImages[0]}
                      /> */}
                    </Text>
                    {product.productImages.length > 0 && (
                      <Image
                        style={styles.image}
                        src={product.productImages[0]} // Display the first image
                      />
                    )}
                  </View>
                ))}
              </Page>
            </Document>
          </PDFViewer>
        </div>
      )}
    </div>
  );
}

export default Download;
