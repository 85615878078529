import Card3_template from '../../../../img/Card3_template.png';
import { RiImageAddFill } from 'react-icons/ri';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import './Cards3.scss'
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Cards1 = () => {

  const [BusinessName, setBusinessName] = useState()
  const [BusinessAddress, setBusinessAddress] = useState()
  const [BusinessWeb, setBusinessWeb] = useState()
  const [Businessphone, setBusinessPhone] = useState()
  const [BusinessEmail, setBusinessEmail] = useState()
  const [BusinessLogo, setBusinessLogo] = useState();

  const uploadBusinessLogoHandler = (e) => {
    setBusinessLogo(URL.createObjectURL(e.target.files[0]));
  }

  const businessname = (e) => {
    setBusinessName(e.target.value);
  }

  const businessaddress = (e) => {
    setBusinessAddress(e.target.value)
  }

  const businessweb = (e) => {
    setBusinessWeb(e.target.value)
  }

  const businessemail = (e) => {
    setBusinessEmail(e.target.value)
  }

  const phone = (e) => {
    setBusinessPhone(e.target.value)
  }

  const handleCaptureClick = async () => {
    const canvas = await html2canvas(document.querySelector('#cardDownload'));
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, 'Card.png', 'image/png');
  };


  return (
    <>
      <div className='card3-with-btns'>
        <div className='card3-detail-container'>
          <div className='card3-detail'>
            <p className='logo-heading'>Logo Image</p>
            <div className='logo-container'>
              <label for="dropzone-file" className='logo-img-container'>
                <RiImageAddFill size={40} className='add-logo-icon' />
                <input id="dropzone-file" type="file" class="hidden" onChange={uploadBusinessLogoHandler} />
              </label>
            </div>
            <div className="half-input">
              <label htmlFor="Business-name" className="label"> Business Name</label>
              <input type="text" id="Business-name" className='input-value input-shadow' onChange={businessname} />
            </div>
            <div className="half-input">
              <label htmlFor="phone" className="label">Phone Number</label>
              <input type="text" id="phone" className='input-value input-shadow' onChange={phone} />
            </div>
            <div className="half-input">
              <label htmlFor="web" className="label">Website</label>
              <input type="text" id="web" className='input-value input-shadow' onChange={businessweb} />
            </div>
            <div className="half-input">
              <label htmlFor="emil" className="label">Email</label>
              <input type="email" id="emil" className='input-value input-shadow' onChange={businessemail} />
            </div>
            <div className="half-input">
              <label htmlFor="address" className="label">Address</label>
              <input type="email" id="address" className='input-value input-shadow' onChange={businessaddress} />
            </div>

          </div>
          <div className='card3_preview' >
            <div className='card3_download' id='cardDownload'>
              <img src={Card3_template} className='card3_template' />
              <div className='preview-detail3'>
                <div className='business-name-logo3'>
                  <div className='logos3'>
                    <img src={BusinessLogo} className='logo-img3' />
                  </div>
                  <div className="business-name3">
                    {BusinessName}
                  </div>
                </div>
                <div className='p-details3'>
                  <div className='phone-number3'>
                    Mobile No: {Businessphone}
                  </div>
                  <div className='web3'>
                    Website: {BusinessWeb}
                  </div>
                  <div className='email3'>
                    Email: {BusinessEmail}
                  </div>
                  <div className='add3'>
                    Address: {BusinessAddress}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='btns'>
          <Link to='/tools'>
            <div className="cancel-btn">
              <button type="button" className="titles">Cancel</button>
            </div>
          </Link>
          <div className="download-btn">
            <button type="button" className="titles" onClick={handleCaptureClick}>Download</button>
          </div>
        </div>
      </div>
    </>
  )
};

export default Cards1;

