import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

function Appointment() {
  const shopId = useSelector((state) => state.user.userData.shopId);
  useEffect(() => {
    checkApp();
    handleShowAllAppointment();
  }, []);
  const checkApp = async () => {
    const response = await axios.post(
      `https://backend.digistall.in/user/checkappointment`,
      {
        shopId,
      }
    );
    if (response.data.data === true) {
      setCheckApp(true);
    }
  };
  const handleTurnonAppointment = async () => {
    console.log(shopId);
    try {
      const response = await axios.post(
        "https://backend.digistall.in/user/turnonappointment",
        {
          shopId,
        }
      );
      console.log(response.status, "status");
    } catch (error) {
      console.error(
        "Error turning on appointment:",
        error.response ? error.response.data : error.message
      );
    }
  };
  const handleShowAllAppointment = async () => {
    try {
      const response = await axios.post(
        "https://backend.digistall.in/user/seeallappointments",
        {
          shopId,
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error(
        "Error turning on appointment:",
        error.response ? error.response.data : error.message
      );
    }
  };
  const [data, setData] = useState([]);
  const [checkAppdata, setCheckApp] = useState(false);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {checkAppdata === false ? (
        <button
          onClick={handleTurnonAppointment}
          className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Turn On Appointment
        </button>
      ) : (
        <button className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Appointment Function is On
        </button>
      )}
      <h2 className="text-xl font-semibold mb-2">All Appointments</h2>
      {data.length > 0 ? (
        <ul className="space-y-4">
          {data.map((appointment, index) => (
            <li
              key={index}
              className="p-4 bg-white rounded shadow-md flex justify-between"
            >
              <div>
                <p className="text-gray-700">
                  <span className="font-medium">Date:</span>{" "}
                  {format(new Date(appointment.date), "PPpp")}
                </p>
                <p className="text-gray-700">
                  <span className="font-medium">Phone Number:</span>{" "}
                  {appointment.phoneNumber}
                </p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">No appointments available.</p>
      )}
    </div>
  );
}

export default Appointment;
