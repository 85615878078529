import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getEmitraUser = createAsyncThunk("getEmitraUser", (KIOSKCODE) => {
  const url = "https://backend.digistall.in/emitra/get-user";
  // const url = "http://localhost:5000/emitra/get-user"

  const SSOID = window.location.href.split("/")[5];
  const body = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ KIOSKCODE, SSOID }),
  };
  return fetch(url, body)
    .then((resp) => resp.json())
    .catch((err) => console.log(err));
});

const defaultState = {
  loadingStatus: "LOADING",
  emitraData: {},
  logged: false,
};

const loadState = () => {
  let state;
  try {
    const serialisedState = window.localStorage.getItem("local_emitra");
    if (!serialisedState) {
      state = defaultState;
      const serialisedState2 = JSON.stringify(state);
      window.localStorage.setItem("local_emitra", serialisedState2);
    } else {
      state = JSON.parse(serialisedState);
    }
  } catch (err) {
    console.log("error");
  }
  return state;
};

const initialState = loadState();

const emitraSlice = createSlice({
  name: "emitra",
  initialState,
  reducers: {
    logoutEmitra: (state) => {
      state.loadingStatus = "LOADING";
      state.emitraData = {};
      state.logged = false;
    },
    emitraLogin: (state) => {
      state.logged = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmitraUser.pending, (state) => {
        if (initialState.loadingStatus !== "SUCCESS") {
          state.loadingStatus = "LOADING";
        }
      })
      .addCase(getEmitraUser.fulfilled, (state, action) => {
        state.loadingStatus = "SUCCESS";
        state.emitraData = action.payload.user;
        state.logged = true;
      })
      .addCase(getEmitraUser.rejected, (state) => {
        state.loadingStatus = "FAILED";
        state.logged = false;
        state.emitraData = {};
      });
  },
});

export const { logoutEmitra, emitraLogin } = emitraSlice.actions;

export default emitraSlice.reducer;
