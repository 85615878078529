import React, { useEffect, useState, useRef } from "react";
import logo from "../../../img/land-logo.png";
import emitralogo from "../../../img/emitraLogo.jpg";
import "./dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getEmitraUser, logoutEmitra } from "../../../fetures/emitra.slice";
import { logoutUser } from "../../../fetures/user.slice";
import { resetStoreData, setStoreData } from "../../../fetures/store.slice";
import { intlFormatDistance } from "date-fns";
import { BsSearch } from "react-icons/bs";
import EmitraStoreItem from "./storeItem";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase.config.auth";
import Logo from "../../../img/Digistall.png";
import Modal from "react-modal";
import { setLang } from "../../../fetures/user.slice";

import ModalData from "../Dashboard/modal";
import {
  getUserData,
  createStore,
  updatePayment,
  addStore,
  checkAvailability,
  createsubdomain,
  updateDp,
  addProduct,
  paySubscription,
  addCategory,
  subscribeStore,
} from "../../../fetchCalles";
import digi from "../../../img/Digistall.png";
import axios from "axios";
import { addDays } from "date-fns";
import { HiOutlinePhotograph } from "react-icons/hi";
import qr from "../../../img/qr.png";
import GooglePayLogo from "../../../img/google-pay-logo.svg";
import PhonePeLogo from "../../../img/phonepe-logo.svg";
import PaytmLogo from "../../../img/paytm-logo.svg";
import Switch from "react-switch";
import COD from "../../../img/cod.png";
import StoreItem from "./storeItem";
import successImg from "../../../img/pay-suc.png";
import subscriptionBody from "../../../img/sub.png";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Radio from "../../utils/radio";
import Navbar from "./navbar";

const newStore = localStorage.getItem("new_store");

const filterConst = [
  "ALL",
  "ACTIVE",
  "INACTIVE",
  "1 DAY",
  "6 MONTHS",
  "12 MONTHS",
];

const contenConstent = {
  phone: "PHONE",
  otp: "OTP",
  domain: "DOMAIN",
  business: "BUSINESS",
  product: "PRODUCT",
  payment: "PAYMENT",
  stores: "STORS",
};

const paymentConst = {
  plans: "PLANS",
  failed: "FAILED",
  invoice: "INVOICE",
};

const Dashboard = () => {
  const rlang = useSelector((state) => state.user.lang);

  const handleToggle = () => {
    dispatch(setLang(rlang === "hi" ? "en" : "hi"));
  };
  const { emitraData, logged } = useSelector((state) => state.emitra);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStore, setActiveStore] = useState();
  const [storeStatus, setStoreStatus] = useState(contenConstent.stores);
  const [paymentStage, setPaymentStatge] = useState(paymentConst.plans);
  const [phone, setPhone] = useState();
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [skiploading, setSkipLoading] = useState(false);
  const [userName, setUserName] = useState();
  const [error, setError] = useState();
  const [OTP, setOtp] = useState(new Array(6).fill(""));
  const [userId, setUserId] = useState();
  const [domain, setDomain] = useState();
  const [available, setAvailability] = useState(false);
  const [err, setErr] = useState();
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState();
  const [productImage, setProductImage] = useState();
  const [productImageFile, setProductImageFile] = useState();
  const [productName, setProductName] = useState();
  const [productCategory, setProductCategory] = useState();
  const [productOriginalPrice, setProductOriginalPrice] = useState();
  const [productDiscountPrice, setProductDiscountPrice] = useState();
  const [productCount, setProductCount] = useState(1);
  const [paymentQR, setPaymentQR] = useState();
  const [paymentQRFile, setPaymentQRFile] = useState();
  const [UPIId, setUPIId] = useState();
  const [cod, setCod] = useState(false);
  const [filter, setFilter] = useState(filterConst[0]);
  const [search, setSearch] = useState("");
  const [printing, setPrinting] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  // const {shopId, planType } = storeData
  const [planPrice, setPlanPrice] = useState();
  const [invoice, setInvoice] = useState();
  const printRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //@TAking number first
  const EmitraNumber = useSelector(
    (state) => state.emitra?.emitraData?.PHONE ?? null
  );
  const [emitraPop, setEmitraPop] = useState(false);
  const [nameforEmitra, setNameforEmitra] = useState("");
  const [nameforNumber, setNameforNumber] = useState("");

  // console.log(EmitraNumber, "emitra num");

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (EmitraNumber == null) {
  //       setEmitraPop(true);
  //     }
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);

  const handleDataSubmit = async () => {
    if (!nameforEmitra || !nameforNumber) {
      alert("Please enter name and number");
      return;
    }
    // console.log("kfj", emitraData.KIOSKCODE, nameforEmitra, nameforNumber);
    try {
      const response = await axios.post(
        "https://backend.digistall.in/emitra/add-details",
        {
          KIOSKCODE: emitraData.KIOSKCODE,
          NAME: nameforEmitra,
          PHONE: nameforNumber,
        }
      );
      // console.log(response.data);
      if (response.data.success == true) {
        console.log("jf");
        setEmitraPop(false);
        dispatch(getEmitraUser(emitraData.KIOSKCODE));
      }
    } catch (error) {
      console.log(error, "er");
    }
  };

  let storsKeys = [];
  let storsCount = 0;
  if (emitraData && emitraData.STORES) {
    storsKeys = Object.keys(emitraData.STORES);
    storsCount = storsKeys.length;
  }

  var date = new Date();
  const validTill = addDays(date, 7); //this is changed

  let validUntill = "1";
  if (logged && activeStore) {
    validUntill = intlFormatDistance(
      new Date(activeStore.validTill),
      new Date(),
      { numeric: "always", unit: "day" }
    ).split(" ")[1];
  }

  useEffect(() => {
    if (logged && emitraData) {
      dispatch(getEmitraUser(emitraData.KIOSKCODE));
    }
    const clearLocalStorageOnUnload = () => {
      localStorage.clear();
    };

    window.addEventListener("beforeunload", clearLocalStorageOnUnload);

    return () => {
      window.removeEventListener("beforeunload", clearLocalStorageOnUnload);
    };
  }, [logged]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const downloadInvoice = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    await pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    await pdf.save(`${invoice.TRANSACTIONID}Invoice.pdf`);
    setPrinting(false);
  };

  async function updateProfilePicture(url) {
    const userData = {
      KIOSKCODE: emitraData.KIOSKCODE,
      DP: url,
    };
    try {
      const response = await updateDp(userData);
      dispatch(getEmitraUser(emitraData.KIOSKCODE));
      alert(response.data.response);
      setLoading(false);
    } catch (error) {
      alert(error.response);
      setLoading(false);
    }
  }

  async function callUpdateRef() {
    try {
      await addStore({
        KIOSKCODE: emitraData.KIOSKCODE,
        newStore: {
          SSOID: emitraData.SSOID,
          userId: userId,
          shopId: domain,
          storeName: businessName,
          validTill: validTill,
          planType: "1 days trail",
          logo: "https://res.cloudinary.com/aguruprasad/image/upload/v1669717427/woodline/product-images/Logo_2_xastkj.png",
        },
      });
      dispatch(getEmitraUser(emitraData.KIOSKCODE));
      setLoadingStatus(false);
      setModalStatus(true);
      // alert('Store Created Successfully')
      // setStoreStatus("STORS");
    } catch (e) {
      setErr("Something went wrong 1");
      setLoadingStatus(false);
    }
  }

  async function callUpdatePayment(qr) {
    try {
      await updatePayment(domain, qr, UPIId, cod);
      callUpdateRef();
    } catch (e) {
      setErr("Something went wrong");
      setSkipLoading(false);
    }
  }

  const uploadPaymentQRHandler = (e) => {
    setPaymentQR(URL.createObjectURL(e.target.files[0]));
    setPaymentQRFile(e.target.files[0]);
  };

  async function paymentHandler() {
    if (!paymentQR) {
      setErr("Please upload QR code for payments");
      return;
    }
    if (!UPIId) {
      setErr("Please enter UPI Id");
      return;
    }
    setLoadingStatus(true);
    const storage = getStorage();
    const location = domain + "-" + businessName;
    const storageRef = ref(storage, "shop-qr/" + location);
    await uploadBytes(storageRef, paymentQRFile).then(async (snapshot) => {
      console.log(snapshot);
      await getDownloadURL(snapshot.ref).then(async (url) => {
        callUpdatePayment(url);
      });
    });
  }

  async function paymentHandlerSkip() {
    setSkipLoading(true);
    const storage = getStorage();
    const location = domain + "-" + businessName;
    const storageRef = ref(storage, "shop-qr/" + location);
    await uploadBytes(storageRef, paymentQRFile).then(async (snapshot) => {
      // console.log(snapshot);
      await getDownloadURL(snapshot.ref).then(async (url) => {
        callUpdatePayment(url);
      });
    });
  }

  const addProductFunction = async (product) => {
    try {
      await addProduct(domain, product);
      setLoadingStatus(false);
      setStoreStatus("PAYMENT");
    } catch (error) {
      setErr("Something went wrong");
      setLoadingStatus(false);
    }
  };

  const addCategoryAndProduct = async (product) => {
    const category = {
      categoryName: productCategory,
      categoryImg:
        product.productImages.length === 0
          ? "https://res.cloudinary.com/aguruprasad/image/upload/v1678167791/woodline/product-images/noImage_nyfhe0.jpg"
          : product.productImages[0],
      gst: 0,
    };
    try {
      await addCategory(domain, category);
      addProductFunction(product);
    } catch (error) {
      setErr("Something went wrong");
      setLoadingStatus(false);
    }
  };

  const addProductHandler = async () => {
    if (!productName) {
      setErr("Please enter Product Name");
      return;
    }
    if (!productCategory) {
      setErr("Please enter Product Category");
      return;
    }
    if (!productOriginalPrice) {
      setErr("Please enter Product Original Price");
      return;
    }
    if (!productCount) {
      setErr("Please enter Product Count ( Stock )");
      return;
    }

    if (productImage) {
      setLoadingStatus(true);
      const storage = getStorage();
      const location = productName + "-" + productCategory;
      const storageRef = ref(storage, "product/" + location);
      await uploadBytes(storageRef, productImageFile).then(async (snapshot) => {
        await getDownloadURL(snapshot.ref).then(async (url) => {
          try {
            const product = {
              productName,
              productImages: [url],
              productPrice: productOriginalPrice,
              productDescription: "",
              productdiscountPrice: productDiscountPrice,
              productCategory: productCategory,
              productUnits: productCount,
              productHSN: "",
              productGST: "",
              productUnitType: "unit",
            };
            addCategoryAndProduct(product);
          } catch (e) {
            setErr("Something went wrong");
            setLoadingStatus(false);
          }
        });
      });
    } else {
      try {
        const product = {
          productName,
          productImages: [],
          productPrice: productOriginalPrice,
          productDescription: "",
          productdiscountPrice: productDiscountPrice,
          productCategory: productCategory,
          productUnits: productCount,
          productHSN: "",
          productGST: "",
          productUnitType: "unit",
        };
        addCategoryAndProduct(product);
        // setLoadingStatus(false);
        // setStoreStatus("PAYMENT");
      } catch (e) {
        setErr("Something went wrong");
        setLoadingStatus(false);
      }
    }
  };

  async function uploadImage(e) {
    setLoading(true);
    const storage = getStorage();
    const location = emitraData.KIOSKCODE + "-" + "DP";
    const storageRef = ref(storage, "images" + location);
    await uploadBytes(storageRef, e.target.files[0]).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then(async (url) => {
        updateProfilePicture(url);
      });
    });
  }

  const uploadProductImageHandler = (e) => {
    setProductImage(URL.createObjectURL(e.target.files[0]));
    setProductImageFile(e.target.files[0]);
  };

  const businessHandler = async () => {
    if (!businessName) {
      setErr("Please enter a Business Name");
      return;
    }
    if (!businessAddress) {
      setErr("Please enter a Business Address");
      return;
    }
    setLoadingStatus(true);
    try {
      await createStore(
        domain,
        businessName,
        userId,
        undefined,
        businessAddress,
        validTill
      );
      setLoadingStatus(false);
      setStoreStatus("PRODUCT");
    } catch (e) {
      setErr("some thing was wrong please try again..");
      setLoadingStatus(false);
    }
  };

  async function onClickCreatesubdomain() {
    setLoadingStatus(true);
    try {
      await createsubdomain(domain, userName, undefined);
      await createStore(
        domain,
        businessName,
        userId,
        undefined,
        businessAddress,
        validTill
      );
      setLoadingStatus(false);
      setStoreStatus("PAYMENT");
      // paymentHandlerSkip();
      // setStoreStatus("STORE");
    } catch (e) {
      setErr("Something went wrong");
      setLoadingStatus(false);
    }
  }

  async function onChnageSubdomain(e) {
    setAvailability(false);
    const value = e.target.value
      .replace(/[^\w\s]/gi, "")
      .trim()
      .toLowerCase();
    setDomain(value);
    try {
      const { data } = await checkAvailability(value);
      if (data.response) {
        setAvailability(true);
        setErr("");
      } else {
        setAvailability(false);
        setErr("sub-domain already exist");
      }
    } catch (e) {
      setAvailability(false);
      setErr("Something went wrong");
    }
  }

  const changStoreData = () => {
    // modalStatus(false);

    return setStoreStatus("STORS");
  };

  const customStyles = {
    content: {
      top: "28%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const renderPaymentView = () => {
    return (
      <div
        className="pay-container"
        style={{ backgroundColor: "rgb(244, 244, 244)" }}
      >
        {modalStatus && (
          <div>
            <Modal
              isOpen={modalStatus}
              // onAfterOpen={afterOpenModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <h1 className="text-center">
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#4bd70f", fontSize: "20px" }}
                ></i>
              </h1>
              <h1
                className="text-success text-center"
                style={{ fontSize: "18px" }}
              >
                {" "}
                <span></span>Store Created Successfully
              </h1>
              <div className="d-flex">
                <div
                  className="bottom-btn-container mr-1 mt-1"
                  style={{ height: "auto", padding: "5px" }}
                >
                  <button
                    type="button"
                    className="confirm-btn"
                    onClick={() => {
                      setStoreStatus("STORS");
                      setModalStatus(false);
                    }}
                  >
                    Skip
                  </button>
                </div>
                <div
                  className="bottom-btn-container w-auto mt-1"
                  style={{ height: "auto", padding: "5px" }}
                >
                  <button
                    type="button"
                    className="confirm-btn"
                    onClick={() => {
                      let allData = {
                        SSOID: emitraData.SSOID,
                        userId: userId,
                        shopId: domain,
                        storeName: businessName,
                        validTill: validTill,
                        planType: "1 days trail",
                        logo: "https://res.cloudinary.com/aguruprasad/image/upload/v1669717427/woodline/product-images/Logo_2_xastkj.png",
                      };
                      onClickSetActiveStore(allData);
                      // setStoreStatus("STORS");
                      setModalStatus(false);
                    }}
                  >
                    Subscribe Now
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        )}
        <div className="payment-heading">Set up Payment</div>
        <div className="payment-sub-heading">Enter your payment details</div>
        <div className="pay-cod">
          <div className="payment-cod-container">
            <div className="payment-container">
              <p className="qr-title">Upload QR Code</p>
              <div className="qr-container">
                {paymentQR ? (
                  <div>
                    <img src={paymentQR} className="qr-code" />
                  </div>
                ) : (
                  <div className="without-qr">
                    <label htmlFor="dropzone-file" className="without-upload">
                      <div className="icons">
                        <img src={qr} />
                        <p className="texts">
                          For Payments please upload QR code
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        onChange={uploadPaymentQRHandler}
                      />
                    </label>
                  </div>
                )}
              </div>
              <div className="upi-logo-container">
                <div className="upi-logo">
                  <img src={PaytmLogo} />
                </div>
                <div className="upi-logo">
                  <img src={PhonePeLogo} />
                </div>
                <div className="upi-logo">
                  <img src={GooglePayLogo} />
                </div>
              </div>
              {err && (
                <div>
                  <div className="text-red-600 mt-2 text-lg">{err}</div>
                </div>
              )}

              <div className="upi-input">
                <div className="upi-id">
                  <label htmlFor="firstname" className="title">
                    Enter Your UPI ID
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    className="upi-full input-shadow"
                    onChange={(e) => setUPIId(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="cod-container">
              <p className="qr-title">Enable for Cash on delivery</p>
              <div>
                <img src={COD} alt="cod" className="cod-img" />
                <Switch
                  onChange={() => setCod(!cod)}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  activeBoxShadow="0 0 2px 3px #0293A8"
                  onColor="#0293A8"
                  checked={cod}
                />
              </div>
              <div className="bttn d-flex">
                <>
                  {/* <div className="bottom-btn-container ">
                    <button type="button" className="confirm-btn" onClick={() => { paymentHandlerSkip() }}>
                      Skip
                    </button>
                  </div> */}
                  {skiploading ? (
                    <div className="bottom-btn-container">
                      <button type="button" className="confirm-btn">
                        Loading...{" "}
                      </button>
                    </div>
                  ) : (
                    <div
                      className="bottom-btn-container ml-2"
                      onClick={() => {
                        paymentHandlerSkip();
                      }}
                    >
                      <button type="button" className="confirm-btn">
                        Skip
                      </button>
                    </div>
                  )}
                  {loadingStatus ? (
                    <div className="bottom-btn-container">
                      <button type="button" className="confirm-btn">
                        Loading...{" "}
                      </button>
                    </div>
                  ) : (
                    <div
                      className="bottom-btn-container ml-2"
                      onClick={paymentHandler}
                    >
                      <button type="button" className="confirm-btn">
                        Next{" "}
                      </button>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderProductView = () => {
    return (
      <div
        style={{
          width: "100vw",
          height: "85vh",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="p-container"
          style={{
            width: "65vw",
            backgroundColor: "rgb(244, 244, 244)",
            borderRadius: "6px",
          }}
        >
          <div className="product-heading">Add Your First Product</div>
          <div className="product-sub-heading">
            Upload Product Details To Get Started
          </div>
          <div className="first-product-container" style={{ marginTop: "1em" }}>
            <div className="image-container">
              {!productImage ? (
                <label
                  htmlFor="dropzone-file"
                  className="without-img-container"
                >
                  <div style={{ color: "#B3B3B3" }}>
                    <HiOutlinePhotograph size={50} />
                  </div>
                  <div style={{ color: "#B3B3B3" }}>Click to add an Image</div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={uploadProductImageHandler}
                  />
                </label>
              ) : (
                <div>
                  <img src={productImage} className="uploaded-img" />
                </div>
              )}
            </div>
            <div className="text-inputs">
              <div className="product-input">
                <label htmlFor="firstname" className="title">
                  Product Name
                </label>
                <input
                  type="text"
                  id="firstname"
                  placeholder="Name"
                  className="product-full input-shadow"
                  onChange={(e) => {
                    setProductName(e.target.value);
                    setErr();
                  }}
                />
              </div>
              <div className="product-input">
                <label htmlFor="firstname" className="title">
                  Product Category
                </label>
                <input
                  type="text"
                  id="firstname"
                  placeholder="Daily use, Grocery, Home appliances etc"
                  className="product-full input-shadow"
                  onChange={(e) => {
                    setProductCategory(e.target.value);
                    setErr();
                  }}
                />
              </div>
              <div className="form-two-line-container">
                <div className="price-container">
                  <label htmlFor="firstname" className="title">
                    {" "}
                    Product Price
                  </label>
                  <input
                    type="number"
                    id="firstname"
                    placeholder="Product price in market"
                    className="hulf-Width-input input-shadow"
                    onChange={(e) => {
                      setProductOriginalPrice(e.target.value);
                      setErr();
                    }}
                  />
                </div>
                <div className="price-container">
                  <label htmlFor="firstname" className="title">
                    Discounted Price
                  </label>
                  <input
                    type="number"
                    id="firstname"
                    placeholder="Discount Price"
                    className="hulf-Width-input input-shadow"
                    onChange={(e) => {
                      setProductDiscountPrice(e.target.value);
                      setErr();
                    }}
                  />
                </div>
              </div>
              <div className="product-input">
                <label htmlFor="firstname" className="title">
                  {" "}
                  Product Stock Count
                </label>
                <input
                  type="number"
                  id="firstname"
                  placeholder="Eg: 12"
                  className="product-full input-shadow"
                  onChange={(e) => {
                    setProductCount(e.target.value);
                    setErr();
                  }}
                />
                <div className="d-flex">
                  <div
                    className="bottom-btn-container"
                    onClick={addProductHandler}
                  >
                    <button
                      type="button"
                      className="confirm-btn"
                      onClick={() => {
                        // setStoreStatus("PRODUCT");
                        setStoreStatus("PAYMENT");
                      }}
                    >
                      Skip
                    </button>
                  </div>

                  {loadingStatus ? (
                    <div className="bottom-btn-container">
                      <button type="button" className="confirm-btn">
                        Loading...{" "}
                      </button>
                    </div>
                  ) : (
                    <div
                      className="bottom-btn-container"
                      onClick={addProductHandler}
                    >
                      <button type="button" className="confirm-btn">
                        Next{" "}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {err && <p className="error">{err}</p>}

          {/* <div className="d-flex">

            <div className="bottom-btn-container mr-1">
              <button type="button" className="confirm-btn" onClick={() => {
                // setStoreStatus("PRODUCT");
                setStoreStatus("PAYMENT");
              }}>
                Skip
              </button>
            </div>
            {loadingStatus ? (
              <div className="bottom-btn-container">
                <button type="button" className="confirm-btn">
                  Loading...{" "}
                </button>
              </div>
            ) : (
              <div className="bottom-btn-container" onClick={addProductHandler}>
                <button type="button" className="confirm-btn">
                  Next{" "}
                </button>
              </div>
            )}
          </div> */}
          {/* <div className="mt-1 flex justify-center">
            <>
              {loadingStatus ? (
                <div className="bottom-btn-container">
                  <button type="button" className="confirm-btn">
                    Loading...{" "}
                  </button>
                </div>
              ) : (
                <div className="bottom-btn-container" onClick={addProductHandler}>
                  <button type="button" className="confirm-btn">
                    Next{" "}
                  </button>
                </div>
              )}
            </>
          </div> */}
        </div>
      </div>
    );
  };

  const renderBussinessView = () => {
    return (
      <div
        style={{
          width: "100vw",
          height: "85vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="oshop-details"
          style={{
            width: "45vw",
            backgroundColor: "rgb(244, 244, 244)",
            paddingTop: "2rem",
            paddingBottom: "2rem",
            borderRadius: "6px",
          }}
        >
          <div className="shop-details">Shop Details</div>
          <p className="shop-sub-heading">Fill in the Shop details below</p>
          <div className="business-input">
            <label htmlFor="firstname" className="title">
              {" "}
              Business Name
            </label>
            <input
              type="text"
              id="firstname"
              className="full input-shadow"
              placeholder="Eg: Digistall Pvt"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </div>

          <div className="business-input">
            <label htmlFor="firstname" className="title">
              {" "}
              Business Address
            </label>
            <input
              type="text"
              id="firstname"
              className="full input-shadow"
              placeholder="Eg: xyz area, Rajasthan. India - 342902"
              value={businessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
            />
          </div>
          {err && (
            <div>
              <div className="error-msg">{err}</div>
            </div>
          )}
          {/* <div className="d-flex">

            <div className="bottom-btn-container mr-1">
              <button type="button" className="confirm-btn" onClick={() => {
                setStoreStatus("PRODUCT");
              }}>
                Skip
              </button>
            </div>
            {loadingStatus ? (
              <div className="bottom-btn-container">
                <button type="button" className="confirm-btn">
                  Loading...{" "}
                </button>
              </div>
            ) : (
              <div className="bottom-btn-container" onClick={businessHandler}>
                <button type="button" className="confirm-btn">
                  Next{" "}
                </button>
              </div>
            )}
          </div> */}
          <div className="mt-2 flex justify-start mb-2 shopDetails-btn">
            <>
              {loadingStatus ? (
                <div className="bottom-btn-container">
                  <button type="button" className="confirm-btn">
                    Loading...{" "}
                  </button>
                </div>
              ) : (
                <div className="bottom-btn-container" onClick={businessHandler}>
                  <button type="button" className="confirm-btn">
                    Next{" "}
                  </button>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  const renderDomainView = () => {
    return (
      <>
        <div
          className="phone-bg-con"
          style={{
            width: "100vw",
            height: "85vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="phone-number-con"
            style={{
              width: "45vw",
              backgroundColor: "rgb(244, 244, 244)",
              paddingTop: "3rem",
              paddingBottom: "3rem",
              borderRadius: "6px",
            }}
          >
            <h1 className="d-flex align-items-center">
              <span style={{ fontSize: "30px" }}>
                <i
                  class="fa-solid fa-globe mr-2"
                  style={{ color: "#0293a8" }}
                ></i>
              </span>
              <h1 style={{ color: "#0293A8", fontSize: "20px" }}>
                {" "}
                Create Your Website Name
              </h1>
            </h1>

            <label htmlFor="phone" className="phone-lab"></label>
            <div
              className="domain-input"
              style={{
                width: "40vw",
                backgroundColor: "rgb(244, 244, 244)",
                borderRadius: "6px",
              }}
            >
              <input
                type="text"
                id="domain"
                placeholder="Ex:jeweleryforall"
                className="full"
                value={domain}
                onChange={onChnageSubdomain}
              />
              <div className="server-name">.digistall.in</div>
            </div>
            {err && (
              <p className="text-red-600 text-center mt-6 text-lg">{err}</p>
            )}
            <div className="screate-domain">
              {available && domain !== "" ? (
                <>
                  {loadingStatus ? (
                    <button
                      type="button"
                      className="confirm-btn bottom-btn-container"
                    >
                      Loading...{" "}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={onClickCreatesubdomain}
                      className="bottom-btn-container confirm-btn"
                    >
                      CREATE
                    </button>
                  )}
                </>
              ) : (
                <button
                  type="button"
                  disabled={true}
                  style={{ backgroundColor: "#87a7ac" }}
                  className="bottom-btn-container confirm-btn"
                >
                  CREATE
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const createUser = async (userId) => {
    try {
      await axios.post("https://backend.digistall.in/user/createuser", {
        userId: userId,
        userName: userName,
        phoneNumber: phone,
      });
      setLoadingStatus(false);
      setStoreStatus("DOMAIN");
    } catch (e) {
      setError("Something went wrong! PLEASE TRY TO LOGIN");
      setLoadingStatus(false);
    }
  };

  const checkUser = async (userId) => {
    try {
      const response = await getUserData(userId);

      if (response.data.response === "User Does Not Existed") {
        await createUser(userId);
      } else {
        setError("User already existed please try Login");
        setLoadingStatus(false);
      }
    } catch (e) {
      setError("Something went wrong");
      setLoadingStatus(false);
    }
  };

  const onClickVerify = async () => {
    let otp = "";
    OTP.forEach((each) => (otp += each));
    setLoadingStatus(true);
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then((result) => {
        // setUserId("zXNdZFakIBe65LdZNYmix2aPrXK2");
        // setStoreStatus("DOMAIN")
        setUserId(result.user["uid"]);
        checkUser(result.user["uid"]);
        // checkUser("zXNdZFakIBe65LdZNYmix2aPrXK2");
      })
      .catch((error) => {
        setError("Somerthing was wrong");
        setLoadingStatus(false);
      });
  };

  function changeFocus(target, index) {
    if (isNaN(target.value)) {
      return false;
    }
    setOtp([...OTP.map((v, ind) => (ind === index ? target.value : v))]);
    if (target.nextSibling) {
      target.nextSibling.focus();
    }
  }

  const genarateCaptchaVerifyer = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "send-otp-button",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
        auth
      );
    }
  };

  const onClickSendOtp = async () => {
    if (phone.length === 10) {
      setLoadingStatus(true);
      genarateCaptchaVerifyer();
      let appVerifyer = window.recaptchaVerifier;
      const phoneNumber = `+91${phone}`;
      signInWithPhoneNumber(auth, phoneNumber, appVerifyer)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setError();
          setStoreStatus("OTP");
          setLoadingStatus(false);
        })
        .catch((error) => {
          setError("Something went wrong");
          setLoadingStatus(false);
        });
    } else {
      setError("Enter valid phone number");
      setLoadingStatus(false);
    }
  };

  const renderOtpView = () => {
    return (
      <div
        style={{
          width: "100vw",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="phoneotp-bg-con"
          style={{
            width: "45vw",
            backgroundColor: "rgb(244, 244, 244)",
            paddingBottom: "3rem",
            borderRadius: "6px",
          }}
        >
          <div className="phone-otp-container p-4">
            <h1 style={{ fontSize: "50px" }}>
              {" "}
              <i className="fa-solid fa-comment-sms"></i>
            </h1>
            <p className="otp-heading">
              {/* <i className="fa-solid fa-comment-sms"></i> */}
              <b style={{ fontSize: "20px" }} className="text-center">
                {" "}
                We have sent a code to your Phone:
              </b>
              <br /> <span className="number">+91-{phone}</span>
            </p>
            <div className="Otp">
              {OTP.map((each, index) => {
                return (
                  <input
                    type="text"
                    value={each}
                    key={index}
                    maxLength={1}
                    name="otp"
                    onChange={(e) => changeFocus(e.target, index)}
                    onFocus={(e) => e.target.select()}
                    className="otp-field"
                  />
                );
              })}
            </div>
            {loadingStatus ? (
              <button className="ver-btn">Please Wait..</button>
            ) : (
              <button type="button" onClick={onClickVerify} className="ver-btn">
                Verify
              </button>
            )}
            {error && <p className="error-msg">{error}</p>}
          </div>
        </div>
      </div>
    );
  };

  const renderPhoneView = () => {
    return (
      <div
        className="phone-bg-con"
        style={{
          width: "100vw",
          height: "85vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="phone-number-con"
          style={{
            width: "45vw",
            backgroundColor: "rgb(244, 244, 244)",
            paddingTop: "3rem",
            paddingBottom: "3rem",
            borderRadius: "6px",
          }}
        >
          <img src={digi} alt="digi-logo" className="signup-logo" />
          <label htmlFor="name" className="phone-lab">
            Full Name
          </label>
          <input
            type="text"
            value={userName}
            className="seller-name-input"
            onChange={(e) => {
              setUserName(e.target.value);
              setError();
            }}
          />
          <label htmlFor="phone" className="phone-lab">
            Phone Number
          </label>
          <div className="phone-number-input-div">
            <button
              type="button"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px 0px 0px 8px",
              }}
              className="reg-code"
            >
              +91
            </button>
            <input
              type="number"
              className="phone-number-input"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setError();
              }}
              style={{ borderRadius: "0px 8px 8px 0px" }}
            />
          </div>
          {loadingStatus ? (
            <button type="button" className="send-otp-btn">
              Loading..
            </button>
          ) : (
            <button
              type="button"
              id="send-otp-button"
              className="send-otp-btn"
              onClick={onClickSendOtp}
            >
              Send OTP
            </button>
          )}
          {error && <p className="error-msg">{error}</p>}
        </div>
      </div>
    );
  };

  const onClickSetActiveStore = (store) => {
    setActiveStore(store);
  };

  const getFileredStores = (storesList) => {
    let filteredStores;
    switch (filter) {
      case filterConst[1]:
        const activeStores = storesList.filter((each) => {
          let validUntill = 1;
          if (logged) {
            validUntill = intlFormatDistance(
              new Date(emitraData.STORES[each].validTill),
              new Date(),
              { numeric: "always", unit: "day" }
            ).split(" ")[1];
          }
          if (validUntill > 0) {
            return each;
          }
        });
        filteredStores = activeStores;
        return filteredStores;
      case filterConst[2]:
        const InActiveStores = storesList.filter((each) => {
          let validUntill = "day";
          if (logged) {
            validUntill = intlFormatDistance(
              new Date(emitraData.STORES[each].validTill),
              new Date(),
              { numeric: "always", unit: "day" }
            ).split(" ")[2];
          }
          if (validUntill < 1) {
            return each;
          }
        });
        filteredStores = InActiveStores;
        return filteredStores;
      case filterConst[3]:
        const oneDayStores = storesList.filter(
          (each) => emitraData.STORES[each].planType === "1 day trail"
        );
        filteredStores = oneDayStores;
        return filteredStores;
      case filterConst[4]:
        const threeMonthsStores = storesList.filter(
          (each) => emitraData.STORES[each].planType === "Half-Yearly"
        );
        filteredStores = threeMonthsStores;
        return filteredStores;
      case filterConst[5]:
        const sixMonthsStores = storesList.filter(
          (each) => emitraData.STORES[each].planType === "Yearly"
        );
        filteredStores = sixMonthsStores;
        return filteredStores;
      default:
        return storesList;
    }
  };

  const renderStoresView = () => {
    const storesList = storsKeys.filter(
      (each) =>
        emitraData.STORES[each].storeName.startsWith(search) ||
        emitraData.STORES[each].shopId.startsWith(search)
    );
    const filteredStores = getFileredStores(storesList);

    return (
      <>
        {/* <div className="e-user-details"> */}
        {/* <div className="e-user-left">
            <img src={emitraData && emitraData.DP} alt="profile-picture" />
            {loading ? (
              <label className="e-user-upload-img">Uploading Image...</label>
            ) : (
              <label htmlFor="profile" className="e-user-upload-img">
                Upload Image
                <input
                  id="profile"
                  type="file"
                  className="hidden"
                  onChange={uploadImage}
                />
              </label>
            )}
          </div> */}
        {/* {emitraData && (
            <div className="e-user-right">
              <div className="e-user">
                <div className="e-user-text">{emitraData.NAME}</div>
                <span className="user-title"> Name </span>
              </div>
              <div className="e-user">
                <div className="e-user-text">+91 {emitraData.PHONE}</div>
                <span className="user-title"> Phone </span>
              </div>
              <div className="e-user">
                <div className="e-user-text">{emitraData.SERVICEID}</div>
                <span className="user-title"> SERVICEID </span>
              </div>
              <div className="e-user">
                <div className="e-user-text">{emitraData.KIOSKCODE}</div>
                <span className="user-title"> Kiosk ID </span>
              </div>
              <div className="e-user">
                <div className="e-user-text">{emitraData.SSOID}</div>
                <span className="user-title"> SSO ID </span>
              </div>
            </div>
          )} */}
        {/* </div> */}
        {/* <div className="w-full mx-auto mt-4 text-center flex flex-row">
          <div className={"w-1/2"}>
            <div>Hello User How are you</div>
            <div>Add a card here</div>
          </div>
          <div className="w-1/2">
            <Radio />
          </div>
        </div> */}
        <div className="md:w-10/12 w-full mx-auto mb-4 mt-4 text-center flex md:flex-row flex-col ">
          <div className="md:w-1/2 w-full flex items-center justify-center">
            <Radio />
          </div>
          <div className="md:w-1/2 w-full">
            <div className="max-w-sm mx-auto  p-6">
              <div className="text-left mb-4">
                <div className="font-semibold text-xl text-green-600 mb-2">
                  {rlang === "hi"
                    ? `नमस्ते! ${emitraData.KIOSKCODE}, `
                    : `Hello! ${emitraData.KIOSKCODE}, `}
                  <span className="text-theme-blue">
                    {rlang === "hi"
                      ? "हमारी नई योजना देखें"
                      : "checkout our new plan"}
                  </span>
                </div>
                <div className=" flex justify-center mt-2 mb-2">
                  <div className="flex items-center space-x-4">
                    <span
                      className={`py-2 px-4 rounded ${
                        rlang === "hi"
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                    >
                      हिंदी
                    </span>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={rlang === "hi" ? false : "en"}
                        onChange={handleToggle}
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-1 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                    <span
                      className={`py-2 px-4 rounded ${
                        rlang === "en"
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                    >
                      English
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-sm mx-auto bg-white border border-gray-300 rounded-lg shadow-lg p-6">
              <h2 className="text-xl font-bold text-gray-800 mb-4">
                {rlang === "hi" ? "नई सदस्यता योजना" : "New Subscription Plan"}
              </h2>
              <div className="text-left mb-4">
                <p className="text-4xl font-semibold text-green-600 mb-2">
                  {rlang === "hi" ? "₹2000" : "₹2000"}
                </p>
                <p className="text-gray-700">
                  {rlang === "hi" ? "ई-मित्र कमीशन: " : "Emitra Commission: "}
                  <span className="font-bold">
                    {rlang === "hi" ? "₹1200" : "₹1200"}
                  </span>
                </p>
                <p className="text-gray-700">
                  {rlang === "hi" ? "अवधि: " : "Duration: "}
                  <span className="font-bold">
                    {rlang === "hi" ? "6 महीने" : "6 months"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <h1 className="flex justify-center text-lg font-bold">
          {rlang === "hi"
            ? "सभी उपयोगकर्ताओं के लिए 7 दिन की मुफ्त ट्रायल सक्रिय है"
            : "7-Day Free Trial for All Users is Active"}
        </h1>
        <h1 className="e-heading">
          <span>
            <i className="fa-solid fa-store"></i>
          </span>{" "}
          {rlang === "hi" ? "स्टोर्स प्रबंधित करें" : "Manage Stores"}
        </h1>
        <div className="e-stores-bg-con">
          <div className="e-h-btn-con ">
            <button
              className=""
              type="button"
              // onClick={() => setStoreStatus(contenConstent.phone)}
            ></button>
            <button
              className="e-add-btn"
              type="button"
              onClick={() => setStoreStatus(contenConstent.phone)}
            >
              <span>
                <i className="fa-solid fa-plus"></i>
              </span>{" "}
              {rlang === "hi" ? "नया वेबसाइट बनाएं" : "Build New Website"}
            </button>
            <button
              className="e-add-btn"
              onClick={() => {
                dispatch(logoutUser());
                dispatch(resetStoreData());
                navigate("/login/phone");
              }}
            >
              {rlang === "hi" ? "स्टोर में लॉगिन करें!" : "Log-in to Store!"}
            </button>
          </div>

          {storsCount !== 0 ? (
            <ul className="e-stors-con">
              <div className="e-stores-filter-section">
                <select
                  value={filter}
                  className="e-filter"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  {filterConst.map((each, index) => (
                    <option className="select-option" value={each} key={index}>
                      {each}
                    </option>
                  ))}
                </select>

                <input
                  type="text"
                  placeholder="Find Store..."
                  className="e-search-bar"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <BsSearch className="e-search-icon" />
              </div>
              <li style={{ width: "96%" }}>
                <table
                  className="table mt-1"
                  style={{ width: "100%", overflow: "auto" }}
                >
                  <thead>
                    <tr>
                      <th>{rlang === "hi" ? "SSO आईडी" : "SSO ID"}</th>
                      <th>{rlang === "hi" ? "लोगो" : "Logo"}</th>
                      <th>
                        {rlang === "hi" ? "व्यापार का नाम" : "Business Name"}
                      </th>
                      <th>{rlang === "hi" ? "लिंक" : "Link"}</th>
                      <th>{rlang === "hi" ? "स्थिति" : "Status"}</th>
                      <th className="text-center">
                        {rlang === "hi" ? "सदस्यता" : "Subscription"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredStores.map((each, index) => (
                      <tr key={index}>
                        <EmitraStoreItem
                          storeData={emitraData.STORES[each]}
                          key={emitraData.STORES[each].shopId}
                          setStore={onClickSetActiveStore}
                        />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </li>
              {/* <li className="e-stores-header">
                <p>SSO ID</p>
                <p>Logo</p>
                <p>Business Name</p>
                <p>Link</p>
                <p>Status</p>
                <p>Subscription</p>
              </li>
              {filteredStores.map((each) => (
                <EmitraStoreItem
                  storeData={emitraData.STORES[each]}
                  key={emitraData.STORES[each].shopId}
                  setStore={onClickSetActiveStore}
                />
              ))} */}
            </ul>
          ) : (
            <div className="e-no-stors-con">
              {rlang === "hi" ? "कोई स्टोर नहीं मिला" : "No Stores Found"}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderStorePages = () => {
    switch (storeStatus) {
      case contenConstent.stores:
        return renderStoresView();

      case contenConstent.phone:
        return renderPhoneView();
      case contenConstent.otp:
        return renderOtpView();
      case contenConstent.domain:
        return renderDomainView();
      case contenConstent.business:
        return renderBussinessView();
      case contenConstent.product:
        return renderProductView();
      case contenConstent.payment:
        return renderPaymentView();
      default:
        break;
    }
  };
  // const renderStorePages = () => {
  //   switch (storeStatus) {
  //     case contenConstent.stores:
  //       return renderStoresView();
  //     case contenConstent.phone:
  //       return renderPhoneView();
  //     case contenConstent.otp:
  //       return renderOtpView();
  //     case contenConstent.domain:
  //       return renderDomainView();
  //     case contenConstent.business:
  //       return renderBussinessView();
  //     case contenConstent.product:
  //       return renderProductView();
  //     case contenConstent.payment:
  //       return renderPaymentView();
  //     default:
  //       break
  //   }
  // }

  const renderFailedView = () => {
    return (
      <div className="failure-con">
        <h1 className="failed-h">
          {rlang === "hi" ? "भुगतान विफल" : "Payment Failed"}
        </h1>
        <p>
          {rlang === "hi"
            ? "कृपया पुनः प्रयास करें! अन्यथा एसएसओ से फिर से खोलें।"
            : "Please Try again! Else open from SSO again."}
        </p>
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            setActiveStore();
            setPaymentStatge(paymentConst.plans);
          }}
        >
          {rlang === "hi" ? "वापस" : "Back"}
        </button>
      </div>
    );
  };

  const renderInvoiceView = () => {
    return (
      // <div>
      //   <div className="pdf-container" ref={printRef}>
      //     <div className="emitraBox">
      //       <img src={emitralogo} width="5%" />
      //       <div className="content">
      //         <div className="invoiceHeading">Government of Rajasthan</div>
      //         <div className="invoiceSubHeading">
      //           District e-Governance Society (JAIPUR)
      //         </div>
      //       </div>
      //     </div>
      //     <div className="invoiceSubHeading">Duplicate Receipt</div>
      //     <div className="invoiceFirstBox">
      //       <div className="invoice-left">
      //         <img src={Logo} />
      //       </div>
      //       <div className="invoice-right">
      //         {/* <div className="invoice-right-content">
      //       Code: RISLTEST
      //     </div> */}
      //         <div className="invoice-right-content">
      //           Name: {emitraData.NAME}
      //         </div>
      //         <div className="invoice-right-content">
      //           Kiosk ID: {emitraData.KIOSKCODE}
      //         </div>
      //         <div className="invoice-right-content">
      //           SSOID: {emitraData.SSOID}
      //         </div>
      //         <div className="invoice-right-content">
      //           Phone: +91-{emitraData.PHONE}
      //         </div>
      //         <div className="invoice-right-content">
      //           Service Provider: Digistall
      //         </div>
      //       </div>
      //     </div>
      //     <div className="invoiceSecondBox">
      //       <div className="invoiceSecondBox-content">
      //         <b>Receipt No: </b>
      //         {invoice.REQUESTID}
      //       </div>
      //     </div>
      //     <div className="invoice-dashboard">
      //       <div className="invoice-dashboard-third">
      //         <div className="invoiceheadingouter">
      //           <div className="invoice-headings">
      //             <div>SR No.</div>
      //             <div>Department/Service</div>
      //             <div>Information</div>
      //             <div>Trans ID</div>
      //             <div>Status</div>
      //             <div>Amount</div>
      //           </div>
      //         </div>
      //         <div className="invoiceheadingouter">
      //           <div className="invoice-content">
      //             <div>1</div>
      //             <div>Website Making</div>
      //             <div>{emitraData.SSOID}</div>
      //             <div>{invoice.TRANSACTIONID}</div>
      //             <div>SUCCESS</div>
      //             <div>{planPrice}</div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="seconLastBox">
      //       <div style={{ width: "65%" }}>
      //         Received Amount of Rs. {planPrice} <br />
      //         (Note: This is a computer generated receipt and requires no
      //         signature)
      //         <br />
      //         Thank you for purchasing the subscription
      //         <br />
      //         Digistall.in
      //       </div>
      //     </div>
      //   </div>
      //   <div className="invoiceLastBox" style={{ paddingBottom: "2em" }}>
      //     <div className="invoiceLastBoxbtn">
      //       <button
      //         className="invoiceBtn viewbtn"
      //         onClick={() => {
      //           dispatch(logoutUser());
      //           dispatch(resetStoreData());
      //           navigate("/login/phone");
      //         }}
      //       >
      //         Visit Store
      //       </button>
      //       <button
      //         className="invoiceBtn printbtn"
      //         onClick={() => {
      //           downloadInvoice();
      //           setPrinting(true);
      //         }}
      //       >
      //         <i class="fa-solid fa-print"></i>{" "}
      //         {printing ? "Printing" : "Print"}
      //       </button>
      //       <button
      //         className="invoiceBtn closebtn"
      //         onClick={() => {
      //           setActiveStore();
      //           setPaymentStatge(paymentConst.plans);
      //         }}
      //       >
      //         Close
      //       </button>
      //     </div>
      //   </div>
      // </div>
      <div>
        <div className="pdf-container" ref={printRef}>
          <div className="emitraBox">
            <img src={emitralogo} width="5%" alt="Emitra Logo" />
            <div className="content">
              <div className="invoiceHeading">
                {rlang === "hi" ? "राजस्थान सरकार" : "Government of Rajasthan"}
              </div>
              <div className="invoiceSubHeading">
                {rlang === "hi"
                  ? "जिला ई-गवर्नेंस सोसाइटी (जयपुर)"
                  : "District e-Governance Society (JAIPUR)"}
              </div>
            </div>
          </div>
          <div className="invoiceSubHeading">
            {rlang === "hi" ? "डुप्लीकेट रसीद" : "Duplicate Receipt"}
          </div>
          <div className="invoiceFirstBox">
            <div className="invoice-left">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="invoice-right">
              <div className="invoice-right-content">
                {rlang === "hi" ? "नाम:" : "Name:"} {emitraData.NAME}
              </div>
              <div className="invoice-right-content">
                {rlang === "hi" ? "कियोस्क आईडी:" : "Kiosk ID:"}{" "}
                {emitraData.KIOSKCODE}
              </div>
              <div className="invoice-right-content">
                {rlang === "hi" ? "SSOID:" : "SSOID:"} {emitraData.SSOID}
              </div>
              <div className="invoice-right-content">
                {rlang === "hi" ? "फोन:" : "Phone:"} +91-{emitraData.PHONE}
              </div>
              <div className="invoice-right-content">
                {rlang === "hi" ? "सेवा प्रदाता:" : "Service Provider:"}{" "}
                Digistall
              </div>
            </div>
          </div>
          <div className="invoiceSecondBox">
            <div className="invoiceSecondBox-content">
              <b>{rlang === "hi" ? "रसीद संख्या:" : "Receipt No:"}</b>
              {invoice.REQUESTID}
            </div>
          </div>
          <div className="invoice-dashboard">
            <div className="invoice-dashboard-third">
              <div className="invoiceheadingouter">
                <div className="invoice-headings">
                  <div>{rlang === "hi" ? "क्रमांक" : "SR No."}</div>
                  <div>
                    {rlang === "hi" ? "विभाग/सेवा" : "Department/Service"}
                  </div>
                  <div>{rlang === "hi" ? "जानकारी" : "Information"}</div>
                  <div>{rlang === "hi" ? "ट्रांजेक्शन आईडी" : "Trans ID"}</div>
                  <div>{rlang === "hi" ? "स्थिति" : "Status"}</div>
                  <div>{rlang === "hi" ? "राशि" : "Amount"}</div>
                </div>
              </div>
              <div className="invoiceheadingouter">
                <div className="invoice-content">
                  <div>1</div>
                  <div>
                    {rlang === "hi" ? "वेबसाइट निर्माण" : "Website Making"}
                  </div>
                  <div>{emitraData.SSOID}</div>
                  <div>{invoice.TRANSACTIONID}</div>
                  <div>{rlang === "hi" ? "सफलता" : "SUCCESS"}</div>
                  <div>{planPrice}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="seconLastBox">
            <div style={{ width: "65%" }}>
              {rlang === "hi"
                ? `रु. ${planPrice} की प्राप्त राशि`
                : `Received Amount of Rs. ${planPrice}`}{" "}
              <br />
              {rlang === "hi"
                ? "(नोट: यह एक कंप्यूटर जनित रसीद है और इसे हस्ताक्षर की आवश्यकता नहीं है)"
                : "(Note: This is a computer generated receipt and requires no signature)"}{" "}
              <br />
              {rlang === "hi"
                ? "सदस्यता खरीदने के लिए धन्यवाद"
                : "Thank you for purchasing the subscription"}{" "}
              <br />
              Digistall.in
            </div>
          </div>
        </div>
        <div className="invoiceLastBox" style={{ paddingBottom: "2em" }}>
          <div className="invoiceLastBoxbtn">
            <button
              className="invoiceBtn viewbtn"
              onClick={() => {
                dispatch(logoutUser());
                dispatch(resetStoreData());
                navigate("/login/phone");
              }}
            >
              {rlang === "hi" ? "स्टोर पर जाएं" : "Visit Store"}
            </button>
            <button
              className="invoiceBtn printbtn"
              onClick={() => {
                downloadInvoice();
                setPrinting(true);
              }}
            >
              <i className="fa-solid fa-print"></i>{" "}
              {printing
                ? rlang === "hi"
                  ? "प्रिंट हो रहा है"
                  : "Printing"
                : rlang === "hi"
                ? "प्रिंट करें"
                : "Print"}
            </button>
            <button
              className="invoiceBtn closebtn"
              onClick={() => {
                setActiveStore();
                setPaymentStatge(paymentConst.plans);
              }}
            >
              {rlang === "hi" ? "बंद करें" : "Close"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const extendValidity = async (amount) => {
    let planType = "Half-Yearly";
    let months = 6;
    if (amount === 6000) {
      planType = "Yearly";
      months = 12;
    }

    const paymentData = {
      shopId: activeStore.shopId,
      months: months,
      planType: planType,
    };
    try {
      await subscribeStore(paymentData);
      dispatch(getEmitraUser(emitraData.KIOSKCODE));
      setPaymentStatge(paymentConst.invoice);
      setLoadingStatus(false);
    } catch (error) {
      alert("Something was wrong..! Please contact +91 94618 52060");
      setLoadingStatus(false);
    }
  };

  const makePayment = async (amount) => {
    setLoadingStatus(true);
    const paymentData = {
      SSOID: emitraData.SSOID,
      SSOTOKEN: emitraData.SSOTOKEN,
    };
    setPlanPrice(amount);
    try {
      const response = await paySubscription(paymentData);
      if (response.data.response.TRANSACTIONSTATUS !== "FAILURE") {
        extendValidity(amount);
        setInvoice(response.data.response);
      } else {
        setPaymentStatge(paymentConst.failed);
      }
    } catch (error) {
      setPaymentStatge(paymentConst.failed);
      setLoadingStatus(false);
    }
  };

  const renderPlansView = () => {
    return (
      // <div className="subscription">
      //   <h1 className="subscription-h pt-2" style={{ textAlign: "center" }}>
      //     Your {activeStore.planType} plan is{" "}
      //     <span style={{ fontWeight: "600" }}>ACTIVE!</span>
      //     <br />
      //     ~Your {activeStore.planType} plan ends in{" "}
      //     {validUntill === "days" ? 0 : validUntill} Days~
      //   </h1>
      //   <div className="subscription-plan">
      //     <img src={subscriptionBody} alt="plan data" className="plan-data" />
      //     <div className="plans">
      //       <div className="plan">
      //         <div className="plan-name">Half-Yearly Plan</div>

      //         <div className="plan-details">
      //           <h1>&#8377;2000</h1>
      //           <p>6 month</p>
      //           <div className="text-sm pb-2 text-green-800">
      //             Emitra Commission &#8377;1200
      //           </div>
      //           {loadingStatus ? (
      //             <button type="button">Loading..</button>
      //           ) : (
      //             <button type="button" onClick={() => makePayment(2000)}>
      //               Subscribe
      //             </button>
      //           )}
      //         </div>
      //       </div>
      //       {/* <div className="plan">
      //         <div className="plan-name">Yearly Plan</div>
      //         <div className="plan-details">
      //           <h1>&#8377;6000</h1>
      //           <p>1 Year</p>
      //           {loadingStatus ? (
      //             <button type="button">Loading..</button>
      //           ) : (
      //             <button type="button" onClick={() => makePayment(6000)}>
      //               Subscribe
      //             </button>
      //           )}
      //         </div>
      //       </div> */}
      //     </div>
      //   </div>
      // </div>
      <div className="subscription">
        <h1 className="subscription-h pt-2" style={{ textAlign: "center" }}>
          {rlang === "hi"
            ? `आपका ${activeStore.planType} योजना ${
                validUntill === "days" ? 0 : validUntill
              } दिन में समाप्त हो रही है`
            : `Your ${activeStore.planType} plan is ACTIVE! ~Your ${
                activeStore.planType
              } plan ends in ${validUntill === "days" ? 0 : validUntill} Days~`}
          <span style={{ fontWeight: "600" }}>
            {rlang === "hi" ? "सक्रिय है!" : "ACTIVE!"}
          </span>
          <br />
        </h1>
        <div className="subscription-plan">
          <img
            src={subscriptionBody}
            alt={rlang === "hi" ? "योजना डेटा" : "plan data"}
            className="plan-data"
          />
          <div className="plans">
            <div className="plan">
              <div className="plan-name">
                {rlang === "hi" ? "अर्ध-वार्षिक योजना" : "Half-Yearly Plan"}
              </div>
              <div className="plan-details">
                <h1>&#8377;2000</h1>
                <p>{rlang === "hi" ? "6 महीने" : "6 month"}</p>
                <div className="text-sm pb-2 text-green-800">
                  {rlang === "hi"
                    ? "एमीट्रा कमीशन &#8377;1200"
                    : "Emitra Commission &#8377;1200"}
                </div>
                {loadingStatus ? (
                  <button type="button">
                    {rlang === "hi" ? "लोड हो रहा है.." : "Loading.."}
                  </button>
                ) : (
                  <button type="button" onClick={() => makePayment(2000)}>
                    {rlang === "hi" ? "सदस्यता लें" : "Subscribe"}
                  </button>
                )}
              </div>
            </div>
            {/* Uncomment and translate the yearly plan section if needed
      <div className="plan">
        <div className="plan-name">
          {rlang === "hi" ? "वार्षिक योजना" : "Yearly Plan"}
        </div>
        <div className="plan-details">
          <h1>&#8377;6000</h1>
          <p>{rlang === "hi" ? "1 वर्ष" : "1 Year"}</p>
          {loadingStatus ? (
            <button type="button">{rlang === "hi" ? "लोड हो रहा है.." : "Loading.."}</button>
          ) : (
            <button type="button" onClick={() => makePayment(6000)}>
              {rlang === "hi" ? "सदस्यता लें" : "Subscribe"}
            </button>
          )}
        </div>
      </div>
      */}
          </div>
        </div>
      </div>
    );
  };

  const renderPaymentPages = () => {
    switch (paymentStage) {
      case paymentConst.plans:
        return renderPlansView();
      case paymentConst.invoice:
        return renderInvoiceView();
      case paymentConst.failed:
        return renderFailedView();
      default:
        break;
    }
  };

  return (
    <div>
      {emitraPop && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
            <div className="p-4">
              <h2 className="text-lg font-semibold mb-4">
                Fill This to Proceed
              </h2>
              <form className="space-y-4">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={(e) => setNameforEmitra(e.target.value)}
                    value={nameforEmitra}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phone"
                    onChange={(e) => setNameforNumber(e.target.value)}
                    value={nameforNumber}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="flex justify-end">
                  <div
                    onClick={handleDataSubmit}
                    className="bg-theme-blue text-white px-4 py-2 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                  >
                    Submit
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <Navbar />
      {activeStore ? renderPaymentPages() : renderStorePages()}
    </div>
  );
};

export default Dashboard;
