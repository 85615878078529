import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkPaymentStatus, initiatePayment } from "../fetchCalles";

function Test() {
  const {
    userData: { userId },
  } = useSelector((state) => state.user);
  const { storeData } = useSelector((state) => state.store);
  const { shopId, validTill, planType } = storeData;

  const dispatch = useDispatch();
  //make payment req
  const makeTransactionRequest = async (amount) => {
    const months = 6;
    let planType = "Lifetime";

    const paymentData = {
      amount,
      userId,
      shopId,
      months,
      planType,
    };
    try {
      const response = await initiatePayment(paymentData);
      const paymentDetails = JSON.stringify(response.data.response);
      console.log(paymentDetails);
    } catch (error) {
      alert("Something was wrong! Please check your network. ");
    }
  };
  return (
    <div>
      <button type="button" onClick={() => makeTransactionRequest(1)}>
        Subscribe
      </button>
    </div>
  );
}

export default Test;
