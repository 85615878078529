import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { auth, googleAuthProvider } from "../../../firebase.config.auth";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { getUser } from "../../../fetures/user.slice";
import { getUserData } from "../../../fetchCalles";
// import Google from "../../../img/Google.png";
import Email from "../../../img/email.png";

const PhoneLogin = () => {
  const { userStatus } = useSelector((state) => state.user);
  const [phone, setPhone] = useState("");
  const [otpSent, setOtpStatus] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [OTP, setOtp] = useState(new Array(6).fill(""));
  const [error, setErr] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    switch (userStatus) {
      case "DOMAIN":
        return navigate("/domain");
      case "BUSSINESS":
        return navigate("/bussiness");
      case "FIRSTPRODUCT":
        return navigate("/product");
      case "PAYMENT":
        return navigate("/payment");
      case "COMPLETED":
        return navigate("/");
      default:
        break;
    }
  });

  const checkUser = async (userId) => {
    try {
      const response = await getUserData(userId);
      if (response.data.response !== "User Does Not Existed") {
        dispatch(getUser(userId));
      } else {
        setErr("User Not existed please Signup");
        setLoadingStatus(false);
      }
    } catch (e) {
      setErr("Something went wrong");
      setLoadingStatus(false);
    }
  };

  const onClickVerify = async () => {
    let otp = "";
    OTP.forEach((each) => (otp += each));
    setLoadingStatus(true);
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then((result) => {
        checkUser(result.user["uid"]);
      })
      .catch((error) => {
        setErr("Somerthing was wrong");
        setLoadingStatus(false);
      });
  };

  function changeFocus(target, index) {
    if (isNaN(target.value)) {
      return false;
    }
    setOtp([...OTP.map((v, ind) => (ind === index ? target.value : v))]);
    if (target.nextSibling) {
      target.nextSibling.focus();
    }
  }

  const genarateCaptchaVerifyer = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  const onClickSendOtp = async () => {
    if (phone.length === 10) {
      setLoadingStatus(true);
      genarateCaptchaVerifyer();
      let appVerifyer = window.recaptchaVerifier;
      const phoneNumber = `+91${phone}`;
      signInWithPhoneNumber(auth, phoneNumber, appVerifyer)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setErr();
          setOtpStatus(true);
          setLoadingStatus(false);
        })
        .catch((error) => {
          console.log(error);
          setErr("Something went wrong");
          setLoadingStatus(false);
        });
    } else {
      setErr("Enter valid phone number");
      setLoadingStatus(false);
    }
  };

  return (
    <div className="body-container">
      <div className="email-container">
        <h1 className="login-h">Welcome</h1>
        <p className="login-d">
          Join our family today
          <br />
          We look forward to have you with us at Digistall.in
        </p>
        <h1 className="form-h">Login</h1>
        {!otpSent ? (
          <div className="number-container">
            <div className="number-input-container">
              <div className="phone-number-con">
                <div className="cuntry-code">+91</div>
                <input
                  id="phone"
                  type="number"
                  className="phone-input"
                  value={phone}
                  placeholder="Phone Number"
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setErr();
                  }}
                />
              </div>
              {loadingStatus ? (
                <button className="rqs-btn">Please Wait..</button>
              ) : (
                <button
                  type="button"
                  onClick={onClickSendOtp}
                  className="rqs-btn"
                >
                  Request OTP
                </button>
              )}
              {error && <p className="error-msg">{error}</p>}
            </div>
          </div>
        ) : (
          <div className="otp-bg-container">
            <div className="otp-container">
              <p className="otp-heading">
                We have sent a code to your Phone:
                <br /> <span className="number">+91-{phone}</span>
              </p>
              <div className="Otp">
                {OTP.map((each, index) => {
                  return (
                    <input
                      type="text"
                      value={each}
                      key={index}
                      maxLength={1}
                      name="otp"
                      onChange={(e) => changeFocus(e.target, index)}
                      onFocus={(e) => e.target.select()}
                      className="otp-field"
                    />
                  );
                })}
              </div>
              {loadingStatus ? (
                <button className="ver-btn">Please Wait..</button>
              ) : (
                <button
                  type="button"
                  onClick={onClickVerify}
                  className="ver-btn"
                >
                  Verify
                </button>
              )}
              <p className="error-msg">{error}</p>
            </div>
          </div>
        )}
        <div id="sign-in-button"></div>
      </div>
      <div className="or-container">
        <hr className="hulf-line" />
        <p className="or">or</p>
        <hr className="hulf-line" />
      </div>
      <div className="buttons-con">
        <button
          type="button"
          onClick={() => navigate("/login")}
          className="email-btn"
        >
          <img src={Email} alt="email" className="btn-icon" />
          Continue with Email
        </button>
      </div>
      <p className="already">
        Don't have an Account?{" "}
        <Link to="/signup" className="sign-link">
          Signup
        </Link>
      </p>
    </div>
  );
};

export default PhoneLogin;
