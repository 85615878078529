
import { RiImageAddFill } from 'react-icons/ri';
import downloadjs from 'downloadjs';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import './SocialMediaCard.scss'
import { useState } from 'react';

const SocialMediaCard = () => {

    const [BusinessName, setBusinessName] = useState()
    const [Headline, setHeadline] = useState()
    const [Message, setMessage] = useState()
    const [BackgroundColor, setBackgroundColor] = useState("#DADADA");
    const [HeadlineColor, setHeadlineColor] = useState("Black");
    const [MessageColor, setMessageColor] = useState("Black");
    const [BusinessLogo, setBusinessLogo] = useState();

    console.log(BackgroundColor);
    const uploadBusinessLogoHandler = (e) => {
        setBusinessLogo(URL.createObjectURL(e.target.files[0]));
    }

    const businessname = (e) => {
        setBusinessName(e.target.value);
    }

    const headline = (e) => {
        setHeadline(e.target.value)
    }

    const message = (e) => {
        setMessage(e.target.value)
    }

    const backgroundcolor = (e) => {
        setBackgroundColor(e.target.value)
    }

    const headlinecolor = (e) => {
        setHeadlineColor(e.target.value)
    }

    const messagecolor = (e) => {
        setMessageColor(e.target.value)
    }
    const handleCaptureClick = async () => {
        const canvas = await html2canvas(document.querySelector('#SocialcardDownload'));
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, 'Card.png', 'image/png');
    };


    return (
        <div className='socialCard-with-btns'>
            <div className='SocialCard-detail-container'>
                <div className='SocialCard-detail'>
                    <p className='logo-heading'>Logo Image</p>
                    <div className='logo-container'>
                        <label for="dropzone-file" className='logo-img-container'>
                            <RiImageAddFill size={40} className='add-logo-icon' />
                            <input id="dropzone-file" type="file" class="hidden" onChange={uploadBusinessLogoHandler} />
                        </label>
                    </div>
                    <div className="half-input">
                        <label htmlFor="Business-name" className="label"> Business Name</label>
                        <input type="text" maxLength={30} id="Business-name" className='input-value input-shadow' onChange={businessname} />
                    </div>
                    <div className="half-input">
                        <label htmlFor="phone" className="label">Headline</label>
                        <input type="text" maxLength={30} id="phone" className='input-value input-shadow' onChange={headline} />
                    </div>
                    <div className='color-container'>
                        <div className="halfs-input">
                            <label htmlFor="cholor" className="label">Headline Color</label>
                            <div className='input-boxs input-shadow'>
                                <input type="color" id="cholor" value={HeadlineColor} className='input-color' onChange={headlinecolor} />
                                <p className='color-name'>{HeadlineColor}</p>
                            </div>
                        </div>
                        <div className="halfs-input">
                            <label htmlFor="cholor" className="label"> Message Color</label>
                            <div className='input-boxs input-shadow'>
                                <input type="color" id="cholor" value={MessageColor} className='input-color' onChange={messagecolor} />
                                <p className='color-name'>{MessageColor}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="half-input">
                        <label htmlFor="web" className="label">Add Your Message</label>

                        <input type="text" maxLength={250} id="web" className='input-value input-shadow' onChange={message} />
                    </div>


                </div>
                <div className='socialCard_preview' >
                <div className="half-inputs">
                        <label htmlFor="cholor" className="labels"> Background Color</label>
                        <div className='input-boxes input-shadow'>
                            <input type="color" id="cholor" value={BackgroundColor} className='input-color' onChange={backgroundcolor} />
                            <p className='colores-name'>{BackgroundColor}</p>
                        </div>
                    </div>
                    <div className='socialCard-div' style={{ backgroundColor: BackgroundColor }} id='SocialcardDownload'>
                        <div className='detail-social'>
                            <div className='logo-business-name'>
                                <div className='business-logos'>
                                    <img src={BusinessLogo} />
                                </div>
                                <div className='business-name' style={{ color: HeadlineColor }} >
                                    {BusinessName}
                                </div>
                            </div>
                            <div className='headlines' style={{ color: HeadlineColor }}>
                                {Headline}
                            </div>
                            <div className='msg' style={{ color: MessageColor }}>
                                {Message}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="downloadbtn">
                <button type="button" className="titles" onClick={handleCaptureClick}>Download</button>
            </div>
        </div>

    )
};

export default SocialMediaCard;

