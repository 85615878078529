import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BillItem from "../BillItem/BillItem";
import { useState, useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "./billbookDashboad.scss";

const formatter = new Intl.NumberFormat(undefined, {
  currency: "INR",
  style: "currency",
});

const Bills = () => {
  const { userData } = useSelector((state) => state.user);
  const [activeBill, setActiveBill] = useState();
  const { Bills } = userData;
  const { storeData } = useSelector((state) => state.store);
  const { stamp } = storeData;
  const printRef = useRef();
  const { address, orderId, date, totalCartValue } = activeBill
    ? activeBill[0]
    : {
        address: "null",
        orderId: "null",
        date: "1-1-2023-defaultDate",
        totalCartValue: "null",
      };
  const dateArray = date.slice(0, 10).split("-");
  const time = date.slice(11, 16);
  let ordersNames;
  let qntys;
  let eachPrice;
  let eachTotals;
  let eachGsts;
  let total;
  if (activeBill) {
    ordersNames = activeBill.map((each) => {
      if (each.variant) {
        const [size, color] = each.variant.split(" ");
        return `${each.productName} - ${size} : ${color} `;
      } else {
        return each.productName;
      }
    });
    qntys = activeBill.map((each) => each.productCount);
    eachPrice = activeBill.map((each) => each.onePiecePrice);
    eachTotals = qntys.map(
      (each, index) => each * activeBill[index].onePiecePrice
    );
    total = eachTotals.reduce((tot, nex) => tot + nex);
    eachGsts = activeBill.map((each) => each.gst);
  }

  let store;
  let comapnyAddress;
  if (storeData) {
    store = storeData.settings.header.shopName;
    comapnyAddress = storeData.settings.footer.address;
  }

  const downloadInvoice = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${orderId}Invoice.pdf`);
  };
  const navigate = useNavigate();
  const groupedBillsIds = [];
  const groupedBills = {};
  if (Bills !== undefined) {
    Bills.forEach((each) => {
      if (!groupedBillsIds.includes(each.orderId)) {
        groupedBillsIds.push(each.orderId);
      }
    });
    groupedBillsIds.forEach((each) => {
      groupedBills[each] = [];
    });
    Bills.forEach((each) => {
      groupedBills[each.orderId] = [...groupedBills[each.orderId], each];
    });
  }

  const onClickView = (billData) => {
    setActiveBill(billData);
  };

  return (
    <>
      {activeBill ? (
        <div className="bill-bg-container">
          <div className="invoice-background-container" ref={printRef}>
            <div className="inv-header">
              <h1 className="inv-heading">INVOICE</h1>
              <p className="digistall">Digistall.in</p>
            </div>
            <hr className="inv-h-line" />
            <div className="inv-top-sec">
              <div className="inv-top-left">
                <p>{store}</p>
                <p>{comapnyAddress}</p>
              </div>
              <div className="inv-top-right">
                <p>{`Invoice Number #${Bills.length + 999}`}</p>
                <p>{`Date: ${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`}</p>
                <p>{`Time : ${time}`}</p>
              </div>
            </div>
            <div className="inv-delivery-address">
              <h1 className="delivery-h">Delivery Address:</h1>
              <p className="delivery-address">{address}</p>
            </div>
            <div className="inv-bill">
              <div className="inv-bill-hed">
                <p>Item Name</p>
                <p>Qty.</p>
                <p>Price</p>
                <p>Amount</p>
              </div>
              <div className="inv-bill-body">
                <div>
                  {ordersNames.map((each, index) => (
                    <p className="product-name" key={index}>
                      {each.slice(0, 32)}..
                    </p>
                  ))}
                </div>
                <div>
                  {qntys.map((each, index) => (
                    <p key={index}>{each}</p>
                  ))}
                </div>
                <div className="each-prices">
                  {eachPrice.map((each, index) => (
                    <p key={index}>
                      {formatter.format(each)}
                      <br />
                      {`GST: ${eachGsts[index]}%`}
                    </p>
                  ))}
                </div>
                <div className="each-total">
                  {eachTotals.map((each, index) => (
                    <p key={index}>
                      {formatter.format(each)}
                      <br />
                      {formatter.format(
                        Math.round(
                          (eachPrice[index] / 100) *
                            eachGsts[index] *
                            qntys[index]
                        )
                      )}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="inv-bill-bottom-con">
              <h1 className="Thanks-text">Thankyou for shopping</h1>
              <div className="charges">
                <p>Sub Total Amount</p>
                <p>Delivery Charges</p>
                <p>Special Offer</p>
              </div>
              <div className="charges-prices">
                <p>{formatter.format(totalCartValue)}</p>
                <p>+{formatter.format(100)}</p>
                <p>-{formatter.format(100)}</p>
              </div>
            </div>
            <hr className="bottom-line" />
            <div className="total-amount-con">
              <p className="toatal">Total</p>
              <p className="total-amount">{formatter.format(totalCartValue)}</p>
            </div>
            {/* <div className="invoice-stamp" >
                        <img src={stamp} alt="stamp"/>
                        </div> */}
          </div>
          <div className="buttons-con">
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setActiveBill()}
            >
              Go Back
            </button>
            <button
              type="button"
              className="download-btn"
              onClick={downloadInvoice}
            >
              Download Invoice
            </button>
          </div>
        </div>
      ) : (
        <div className="billbook-con">
          <div className="billbook-add-btn-con">
            <button
              onClick={() => navigate("/tools/billbook/billform")}
              type="button"
              className="add-btn"
            >
              + ADD NEW
            </button>
          </div>
          <div className="bills">
            {Bills.length !== 0 ? (
              <ul>
                <li className="biils-h">
                  <p>Order ID</p>
                  <p>Name</p>
                  <p>Address</p>
                  <p>Price</p>
                  <p>view</p>
                </li>
                {groupedBillsIds.map((each, index) => (
                  <BillItem
                    BillData={groupedBills[each]}
                    viewBill={onClickView}
                    key={index}
                  />
                ))}
              </ul>
            ) : (
              <div className="empty-bill-con">
                <h1 className="no-bills-h">No Bills Data Found..!</h1>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Bills;
