import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getUser = createAsyncThunk("getUser", (userId) => {
  const url = "https://backend.digistall.in/user/getuser";
  // const url = "http://localhost:5000/user/getUser"
  const body = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ userId: userId }),
  };
  return fetch(url, body)
    .then((resp) => {
      if (resp.status === 210) {
        alert("Sorry, you don't have an account. Please SignUp");
        return resp.json();
      } else {
        return resp.json();
      }
    })
    .catch((err) => console.log(err));
});

const defaultState = {
  loadingStatus: "LOADING",
  userData: {},
  userStatus: "UNAUTHORIZED",
  lang: "en",
};

const loadState = () => {
  let state;
  try {
    const serialisedState = window.localStorage.getItem("local_user");
    if (!serialisedState) {
      state = defaultState;
      const serialisedState2 = JSON.stringify(state);
      window.localStorage.setItem("local_user", serialisedState2);
    } else {
      state = JSON.parse(serialisedState);
    }
  } catch (err) {
    console.log("error");
  }
  return state;
};

const initialState = loadState();

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setShopId: (state, action) => {
      const shopId = action.payload;
      state.userData.shopId = shopId;
    },
    setUserStatus: (state, action) => {
      const status = action.payload;
      state.userStatus = status;
    },
    setShopName: (state, action) => {
      const shopName = action.payload;
      state.userData.shopName = shopName;
    },
    logoutUser: (state) => {
      state.loadingStatus = "LOADING";
      state.userData = {};
      state.userStatus = "UNAUTHORIZED";
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        if (initialState.loadingStatus !== "SUCCESS") {
          state.loadingStatus = "LOADING";
        }
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loadingStatus = "SUCCESS";
        state.userData = action.payload.response;
        state.userStatus = action.payload.response.userStatus;
      })
      .addCase(getUser.rejected, (state) => {
        state.loadingStatus = "FAILED";
        state.userData = {};
        state.userStatus = "UNAUTHORIZED";
      });
  },
});

export const { setShopId, setUserStatus, setShopName, logoutUser, setLang } =
  userSlice.actions;

export default userSlice.reducer;
