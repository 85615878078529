import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { sendMail } from "../../../fetchCalles"
import './contact.scss'

const Contact = () => {
    const { userData } = useSelector(state => state.user)
    const { userName, email} = userData
    const [name, setName] = useState(userName)
    const [Email, setEmail] = useState(email)
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState()
    const [error, setError] = useState()
    const navigate = useNavigate()

    const onClickSend = async (e) => {
        e.preventDefault()
        if (message) {
            setLoading(true)
            setError()
            try {
                await sendMail(name, Email, message)
                setLoading(false)
                setMessage('')
                alert(`-*- MR/MIS ${name} Your Request Sent -*-`)
            } catch (e) {
                setError('Something went wrong..')
                setLoading(false)
            }
        } else {
            setError('Enter some message..')
        }
    }

    return (
        <div className="contactus-bg">
            <form onSubmit={onClickSend} className="contactus">
                <label htmlFor="name" className="label">Full Name</label>
                <input id="name" className="name input-shadow" type='text' value={name} onChange={(e) => setName(e.target.value)} />
                <label htmlFor="email" className="label">Email</label>
                <input type='email' className="email input-shadow" id="email" value={Email} onChange={(e) => setEmail(e.target.value)} />
                <label htmlFor="message" className="label">Message</label>
                <textarea rows={4} className="message input-shadow" cols={100} type="text" id="message" value={message} onChange={(e) => { setMessage(e.target.value); setError() }}></textarea>
                {error && <p className="error-msg">{error}</p>}
                {loading ? <button type="button" className="send-btn">Loading..</button> : <button type="submit" className="send-btn">Send</button> } 
            </form>
        </div>
    )
}

export default Contact