import './BillItem.scss'

const formatter = new Intl.NumberFormat(undefined, {
  currency: 'INR',
  style: "currency"
})

const BillItem = ({ BillData, viewBill }) => {
    const { orderId, address, totalCartValue } = BillData[0]
    const name = address.split(",")[0]
    const placeArray = address.split(",")
    const arrayLen = placeArray.length
    const place = placeArray[arrayLen - 2] 
    return (
        <li className='bill-item'>
            <p className='id'>{`#${orderId.slice(0,8)}`}</p>
            <p>{name}</p>
            <p>{place}</p>
            <p>{formatter.format(totalCartValue)}</p>
            <button className='view-more' type="button" onClick={() => viewBill(BillData)}>View More</button>
        </li>
    )
}

export default BillItem
