// import { useNavigate } from "react-router-dom";
// // import './ToolsNav.scss'

// import { useSelector } from "react-redux";

// const ToolsNav = () => {
//   const shopType = useSelector(
//     (state) => state.user.userData.shopType || "product"
//   );
//   const path = window.location.pathname;
//   const navigate = useNavigate();
//   const rlang = useSelector((state) => state.user.lang);

//   return (
//     <div className="bg-white md:h-full md:w-64">
//       <div className="flex md:flex-col flex-row md:space-y-4 p-4 justify-evenly">
//         <div
//           onClick={() => navigate("/catalogue")}
//           className={`border p-2 rounded-md cursor-pointer ${
//             path === "/tools" ? "bg-theme-blue text-white" : "bg-gray-200"
//           }`}
//         >
//           <div className="text-center">
//             <p className="text-sm font-medium">
//               {rlang === "hi" ? "श्रेणियाँ" : "Business Card"}
//             </p>
//           </div>
//         </div>
//         <div
//           onClick={() => navigate("/tools/socialposts")}
//           className={`border p-2 rounded-md cursor-pointer ${
//             path === "/tools/socialposts"
//               ? "bg-theme-blue text-white"
//               : "bg-gray-200"
//           }`}
//         >
//           <div className="text-center">
//             <p className="text-sm font-medium">
//               <>{rlang === "hi" ? "उत्पादों" : "Social Media"}</>
//             </p>
//           </div>
//         </div>
//         <div
//           onClick={() => navigate("/tools/businessStamp")}
//           className={`border p-2 rounded-md cursor-pointer ${
//             path === "/tools/businessStamp"
//               ? "bg-theme-blue text-white"
//               : "bg-gray-200"
//           }`}
//         >
//           <div className="text-center">
//             <p className="text-sm font-medium">
//               {rlang === "hi" ? "थोक" : "Business Stamp"}
//             </p>
//           </div>
//         </div>
//         <div
//           onClick={() => navigate("/tools/billbook")}
//           className={`border p-2 rounded-md cursor-pointer ${
//             path === "/tools/billbook"
//               ? "bg-theme-blue text-white"
//               : "bg-gray-200"
//           }`}
//         >
//           <div className="text-center">
//             <p className="text-sm font-medium">
//               {rlang === "hi" ? "थोक" : "Billing Form"}
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ToolsNav;

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ToolsNav = () => {
  const shopType = useSelector(
    (state) => state.user.userData.shopType || "product"
  );
  const path = window.location.pathname;
  const navigate = useNavigate();
  const rlang = useSelector((state) => state.user.lang);

  return (
    <div className="bg-white md:h-full md:w-64">
      <div className="flex md:flex-col flex-row md:space-y-4 p-4 justify-evenly">
        <div
          onClick={() => navigate("/tools")}
          className={`border p-2 rounded-md cursor-pointer ${
            path === "/tools" ? "bg-theme-blue text-white" : "bg-gray-200"
          }`}
        >
          <div className="text-center">
            <p className="text-sm font-medium">
              {rlang === "hi" ? "बिजनेस कार्ड" : "Business Card"}
            </p>
          </div>
        </div>
        {/* <div
          onClick={() => navigate("/tools/socialposts")}
          className={`border p-2 rounded-md cursor-pointer ${
            path === "/tools/socialposts"
              ? "bg-theme-blue text-white"
              : "bg-gray-200"
          }`}
        >
          <div className="text-center">
            <p className="text-sm font-medium">
              {rlang === "hi" ? "सामाजिक मीडिया" : "Social Media"}
            </p>
          </div>
        </div> */}
        {/* <div
          onClick={() => navigate("/tools/businessStamp")}
          className={`border p-2 rounded-md cursor-pointer ${
            path === "/tools/businessStamp"
              ? "bg-theme-blue text-white"
              : "bg-gray-200"
          }`}
        >
          <div className="text-center">
            <p className="text-sm font-medium">
              {rlang === "hi" ? "व्यवसाय की मोहर" : "Business Stamp"}
            </p>
          </div>
        </div> */}
        <div
          onClick={() => navigate("/tools/billbook")}
          className={`border p-2 rounded-md cursor-pointer ${
            path === "/tools/billbook"
              ? "bg-theme-blue text-white"
              : "bg-gray-200"
          }`}
        >
          <div className="text-center">
            <p className="text-sm font-medium">
              {rlang === "hi" ? "बिल बुक" : "Bill Book"}
            </p>
          </div>
        </div>
        <div
          onClick={() => navigate("/tools/addblog")}
          className={`border p-2 rounded-md cursor-pointer ${
            path === "/tools/addblog"
              ? "bg-theme-blue text-white"
              : "bg-gray-200"
          }`}
        >
          <div className="text-center">
            <p className="text-sm font-medium">
              {rlang === "hi" ? "बिल बुक" : "Add Blog"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsNav;
