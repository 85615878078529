import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import subscriptionBody from "../../img/sub.png";
import { intlFormatDistance } from "date-fns";
import { checkPaymentStatus, initiatePayment } from "../../fetchCalles";
import { logoutUser } from "../../fetures/user.slice";
import { getStoreData, resetStoreData } from "../../fetures/store.slice";
import pay from "../../img/pay-rim.png";
import vector from "../../img/Vector.png";
import "./expiryView.scss";
import { useNavigate } from "react-router-dom";

const formatter = new Intl.NumberFormat(undefined, {
  currency: "INR",
  style: "currency",
});

const HALF_YEARLY_AMOUNT = 3000;
const YEARLY_AMOUNT = 6000;

const ExpiryView = () => {
  const {
    userData: { userId },
    userStatus,
  } = useSelector((state) => state.user);
  const { storeData } = useSelector((state) => state.store);
  console.log("sd: ", storeData);
  const { shopId, validTill } = storeData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stage, setStage] = useState("plans");
  const [loading, setLoading] = useState(false);
  const [billData, setBillData] = useState();
  const printRef = useRef();

  let validUntill = "1";
  if (userStatus === "COMPLETED") {
    validUntill = intlFormatDistance(new Date(validTill), new Date(), {
      numeric: "always",
      unit: "day",
    }).split(" ")[1];
  }

  useEffect(() => {
    let paymentDetails = localStorage.getItem("paymentDetails");
    paymentDetails = JSON.parse(paymentDetails);

    if (paymentDetails !== null) {
      setStage("payment");
      getPaymentStatus();
    }
  }, []);

  const getPaymentStatus = async () => {
    let paymentDetails = localStorage.getItem("paymentDetails");
    paymentDetails = JSON.parse(paymentDetails);
    try {
      const response = await checkPaymentStatus({
        merchantTransactionId: paymentDetails.merchantTransactionId,
      });
      setBillData(response.data);
      if (response.data.code === "PAYMENT_SUCCESS") {
        localStorage.removeItem("paymentDetails");
        setStage("success");
      }
      if (response.data.code === "PAYMENT_ERROR") {
        localStorage.removeItem("paymentDetails");
        setStage("failed");
      }
    } catch (error) {
      alert("Something was wrong! Please check your network.");
    }
  };

  const downloadInvoice = async () => {
    setLoading(true);
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${billData.data.transactionId}Invoice.pdf`);
    setLoading(false);
  };

  const makeTransactionRequest = async (amount) => {
    const months = amount === HALF_YEARLY_AMOUNT ? 6 : 12;
    let planType = "Half-Yearly";
    if (amount === YEARLY_AMOUNT) {
      planType = "Yearly";
    }
    setLoading(true);
    const paymentData = {
      amount,
      userId,
      shopId,
      months,
      planType,
    };
    try {
      const response = await initiatePayment(paymentData);
      const paymentDetails = JSON.stringify(response.data.response);
      localStorage.setItem("paymentDetails", paymentDetails);
      window.location = response.data.response.url;
    } catch (error) {
      setLoading(false);
      alert("Something was wrong! Please check your network.");
    }
  };

  const renderFailure = () => {
    return (
      <div className="failure-view">
        <h1 className="failed-h">Payment Failed!</h1>
        <p className="failed-p">
          Your payment was unsuccessful..! please try again!
        </p>
        <button
          className="f-back-btn"
          type="button"
          onClick={() => setStage("plans")}
        >
          Back
        </button>
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div className="success-view">
        <div className="invoice" ref={printRef}>
          <img src={vector} alt="vector" className="vector-img" />
          <h1 className="success-h">Payment Successful!</h1>
          <h1 style={{ fontWeight: "600", marginTop: "1em" }}>
            Payment Details
          </h1>
          <div className="payment-details-con">
            <ul className="payment-keys">
              <li className="key">Transaction ID:</li>
              <li className="key">Amount Paid:</li>
              <li className="key">Subscription Plan:</li>
              <li className="key">Date:</li>
              <li className="key">Time:</li>
            </ul>
            <ul className="payment-values">
              <li className="value">{billData.data.transactionId}</li>
              <li className="value">
                {formatter.format(billData.data.amount / 100)}
              </li>
              <li className="value">
                {billData.data.amount === HALF_YEARLY_AMOUNT * 100
                  ? "Half Yearly plan"
                  : "Yearly plan"}
              </li>
              <li className="value">{new Date().toDateString()}</li>
              <li className="value">{new Date().toTimeString()}</li>
            </ul>
          </div>
        </div>
        <div className="buttons-con">
          {loading ? (
            <button type="button" className="print-button">
              Loading..
            </button>
          ) : (
            <button
              type="button"
              onClick={downloadInvoice}
              className="print-button"
            >
              Print
            </button>
          )}
          <button
            type="button"
            onClick={() => dispatch(getStoreData(shopId))}
            className="cancel-button"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const renderPayment = () => {
    let paymentDetails = localStorage.getItem("paymentDetails");
    paymentDetails = JSON.parse(paymentDetails);
    // if (paymentDetails === null) {
    //     setStage('plans')
    // }
    return (
      <div className="payment-page">
        <h1 className="sub-payment-h">Complete Your Payment</h1>
        <img src={pay} alt="pay" className="sub-pay-img" />
        <button
          type="button"
          className="pay-now-btn"
          onClick={() => (window.location = paymentDetails.url)}
        >
          Pay Now
        </button>
      </div>
    );
  };

  const renderPlans = () => {
    return (
      <div className="ex-subscription">
        <h1 className="subscription-h">
          You are on a free Trial. Please choose a plan to continue.
          <br />
          Free Trial ends in {validUntill} Days
        </h1>
        <div className="subscription-plan">
          <div>
            <img src={subscriptionBody} alt="plan data" className="plan-data" />
            <button
              type="button"
              className="logout-btn"
              onClick={() => {
                dispatch(logoutUser());
                dispatch(resetStoreData());
                navigate("/login");
              }}
            >
              Logout!
            </button>
          </div>
          <div className="plans">
            <div className="plan">
              <div className="plan-name">Half-Yearly Plan</div>
              <div className="plan-details">
                <h1>{HALF_YEARLY_AMOUNT}</h1>
                <p>6 month</p>
                {loading ? (
                  <button type="button">Loading..</button>
                ) : (
                  <button
                    type="button"
                    onClick={() => makeTransactionRequest(HALF_YEARLY_AMOUNT)}
                  >
                    Subscribe
                  </button>
                )}
              </div>
            </div>
            <div className="plan">
              <div className="plan-name">Yearly Plan</div>
              <div className="plan-details">
                <h1>{YEARLY_AMOUNT}</h1>
                <p>1 Year</p>
                {loading ? (
                  <button type="button">Loading..</button>
                ) : (
                  <button
                    type="button"
                    onClick={() => makeTransactionRequest(YEARLY_AMOUNT)}
                  >
                    Subscribe
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="expiry-bg-con">
      {stage === "plans" && renderPlans()}
      {stage === "payment" && renderPayment()}
      {stage === "success" && renderSuccess()}
      {stage === "failed" && renderFailure()}
    </div>
  );
};

export default ExpiryView;
