import { useState } from "react"

const Product = ({ productData, billProduct, billedProducts }) => {
    const {productId, productImages, productName, productCategory, productdiscountPrice, productPrice, hasVariants, productUnits } = productData 
    const billedProductsIds = billedProducts.map(each => each.productId)
    let isInCart = billedProductsIds.includes(productId)
    let billedProductData
    if (isInCart) {
        const [data] = billedProducts.filter(each => each.productId === productId)
        billedProductData = data
    }
    

    const onClickAdd = () => {
        billProduct(productData)
    }

    return (
        <li>
            <img src={productImages[0]} alt={productName} />
            <div>
                <h1>{productName.slice(0, 10)}..</h1>
                <p>{productCategory}</p>
                <p>{productdiscountPrice ? productdiscountPrice : productPrice}</p>
            </div>
            {productUnits < 1 ? <button type="button">OUT OF STOCK</button> : <>isInCart
                ? <div><button type="button">+</button>{billedProductData.count}<button type="button">-</button></div> : <button type="button" onClick={onClickAdd}>Add</button> </>}
        </li>
    )
}

export default Product
