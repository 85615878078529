import { Outlet } from "react-router-dom";
import Header from "../Header/header";
import './ToolsSharedlayout.scss'
import ToolsNav from "./ToolsNav";

const ToolsSharedlayout = () => {
    return(
        <div className="catalogue-bg-container">
            <Header />
            <div className="header-outlet-container">
                <ToolsNav />
                <Outlet />
            </div>
        </div>
    )
    
}

export default ToolsSharedlayout