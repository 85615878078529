import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { auth, googleAuthProvider } from "../../../firebase.config.auth";
import { useSelector, useDispatch } from "react-redux";
import { getUser, setLang } from "../../../fetures/user.slice";
import Google from "../../../img/Google.png";
import { MdOutlinePhone } from "react-icons/md";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { getUserData } from "../../../fetchCalles";
import axios from "axios";

const EmailLogin = () => {
  const [err, setErr] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const { userStatus } = useSelector((state) => state.user);
  const [loadingStatus, setLodingStatus] = useState(false);
  const [visibul, setVisibulity] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rlang = useSelector((state) => state.user.lang);
  const handleToggle = () => {
    dispatch(setLang(rlang === "hi" ? "en" : "hi"));
  };
  useEffect(() => {
    switch (userStatus) {
      case "DOMAIN":
        return navigate("/domain");
      case "BUSSINESS":
        return navigate("/bussiness");
      case "FIRSTPRODUCT":
        return navigate("/product");
      case "PAYMENT":
        return navigate("/payment");
      case "COMPLETED":
        return navigate("/");
      default:
        break;
    }
  });

  const onClcikLogin = async (e) => {
    e.preventDefault();
    if (!email || !pass) {
      setErr("Please enter email and password");
    } else {
      setLodingStatus(true);
      try {
        setErr("");
        const response = await auth.signInWithEmailAndPassword(email, pass);
        await dispatch(getUser(response.user["_delegate"]["uid"]));

        setLodingStatus(false);
      } catch (err) {
        const error = err.message.split(".");
        setErr(error[0].split(":")[1]);
        setLodingStatus(false);
      }
    }
  };
  async function createUser2(userId, username, email) {
    try {
      await axios.post("https://backend.digistall.in/user/createuser", {
        userId,
        userName: username,
        email: email,
      });
      dispatch(getUser(userId));
    } catch (e) {
      alert("Something went wrong PLEASE TRY TO LOGIN");
    }
  }
  const checkUser2 = async (userId, username, email) => {
    try {
      const response = await getUserData(userId);
      if (response.data.response !== "User Does Not Existed") {
        // console.log(response.data.response, "user exisst");
        dispatch(getUser(userId));
      } else {
        console.log("user doesnot exists");
        await createUser2(userId, username, email);
        await dispatch(getUser(userId));
        // setLoadingStatus(false);
      }
    } catch (e) {
      setErr("Something went wrong");
      // setLoadingStatus(false);
    }
  };
  const googleLoginHandler = async () => {
    setLodingStatus(true);
    setErr();
    try {
      // await dispatch(getUser(response.user["_delegate"]["uid"]))
      // alert('yes')
      const response = await auth.signInWithPopup(googleAuthProvider);
      const userId = response.user.uid;
      const username = response.user.displayName;
      const email = response.user.email;
      checkUser2(userId, username, email);

      // await dispatch(getUser(response.user.uid));
      // console.log(response);
      setLodingStatus(false);
    } catch (e) {
      console.log(e);
      setErr("Some Thing Was Wrong");
      setLodingStatus(false);
    }
  };

  return (
    <div className="body-container">
      <div className=" md:-mt-32 mb-10 font-bold text-theme-blue text-4xl">
        Log In
      </div>
      <div className="text-gray-700">Choose Your Language</div>
      <div className=" flex justify-center mt-2 mb-2">
        <div className="flex items-center space-x-4">
          <span
            className={`py-2 px-4 rounded ${
              rlang === "hi"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            हिंदी
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={rlang === "hi" ? false : "en"}
              onChange={handleToggle}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-1 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
          <span
            className={`py-2 px-4 rounded ${
              rlang === "en"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            English
          </span>
        </div>
      </div>
      <div className="email-container mt-10">
        <h1 className="login-h">{rlang === "hi" ? "नमस्ते" : "Welcome"}</h1>
        <p className="login-d">
          {rlang === "hi"
            ? "आज ही हमारे परिवार में शामिल हों।"
            : "Join our family today"}

          <br />

          {rlang === "hi"
            ? " हम आपको डिजीस्टॉल.in पर हमारे साथ पाकर उत्सुक हैं।"
            : "We look forward to have you with us at Digistall.in"}
        </p>
      </div>

      <div className="buttons-con">
        {loadingStatus ? (
          <button type="button" className="email-btn">
            Loading..
          </button>
        ) : (
          <button
            type="button"
            onClick={googleLoginHandler}
            className="email-btn"
          >
            <img src={Google} alt="google" className="btn-icon" />
            {rlang === "hi" ? "गूगल के साथ जारी रखें" : "Continue with Google"}
          </button>
        )}
        <button
          type="button"
          onClick={() => navigate("/login/phone")}
          className="email-btn"
        >
          <MdOutlinePhone className="icon" />
          {rlang === "hi" ? "नंबर के साथ जारी रखें" : "Continue with Number"}
        </button>
      </div>

      <p className="text-gray-800 mt-3">
        {rlang === "hi" ? "पहले से ही एक खाता है?" : " Don't have an Account? "}

        <Link to="/signup" className="text-theme-blue font-semibold">
          {rlang === "hi" ? " लॉगिन करें " : "Signup "}
        </Link>
      </p>
    </div>
  );
};

export default EmailLogin;
