import React from 'react';
import Card1 from '../../../../img/card1.png';
import Card2 from '../../../../img/card2.png';
import Card3 from '../../../../img/card3.png';
import Card4 from '../../../../img/card4.png';
import './BusinessCardList.scss';
import { Link } from 'react-router-dom';

const BusinessCardList=()=>{
  return(
    <div className='card-container'>
      <p className='template'>3 Templates</p>
      <div className='template-container'>
      <Link to='/tools/card1'>
        <div className='cards'>
          <img src={Card1} className='card'/>
        </div>
        </Link>
        <Link to='/tools/card2'>
        <div className='cards'>
          <img src={Card2} className='card'/>
        </div>
        </Link>
        <Link to='/tools/card3'>
        <div className='cards'>
          <img src={Card3} className='card'/>
        </div>
        </Link>
      </div>
    </div>
  )
}

export default BusinessCardList















