import React, { useRef } from "react";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";

import frontDesign from "../../../img/frontCard.png";
import backDesign from "../../../img/backCard.png";

const Card = () => {
  const cardRefFront = useRef(null);
  const cardRefBack = useRef(null);
  const { storeData } = useSelector((state) => state.store);
  const { shopId, QRCode: qrCodeURL, shopName } = storeData || {};
  console.log(qrCodeURL, "qrCodeURL");

  const handleDownloadFront = async () => {
    try {
      const frontCanvas = await html2canvas(cardRefFront.current, {
        useCORS: true,
      });
      const link = document.createElement("a");
      link.download = "seller-card-front.png";
      link.href = frontCanvas.toDataURL();
      link.click();
    } catch (error) {
      console.error("Error creating front canvas:", error);
    }
  };

  const handleDownloadBack = async () => {
    try {
      const backCanvas = await html2canvas(cardRefBack.current, {
        useCORS: true,
      });
      const link = document.createElement("a");
      link.download = "seller-card-back.png";
      link.href = backCanvas.toDataURL();
      link.click();
    } catch (error) {
      console.error("Error creating back canvas:", error);
    }
  };

  const renderQrCodeImage = () => {
    return <img src={qrCodeURL} style={styles.qrCodeImg} alt="QR Code" />;
  };

  return (
    <div style={styles.cardPage}>
      <div ref={cardRefFront} style={styles.card}>
        <div style={styles.details}>
          <h1 style={styles.h1}>{shopName}</h1>
          <div style={styles.text}>
            <p>{storeData.settings.header.phoneNumber}</p>
            <p>{storeData.settings.header.email}</p>
            <p>{storeData.settings.footer.address}</p>
          </div>
        </div>
        <div style={styles.frontQrCode}>
          <QRCode
            size={120}
            value={`https://${shopId}.digistall.in`}
            level="H"
            renderAs="canvas"
          />
        </div>
      </div>
      <div>
        <button onClick={handleDownloadFront} style={styles.downloadButton}>
          Download Front Card
        </button>
      </div>
      <div ref={cardRefBack} style={styles.cardBack}>
        <div className="absolute top-[21px]">
          <div>{renderQrCodeImage()}</div>
        </div>
        <div className="text-[12px] flex flex-col gap-2 mt-36 items-start">
          <span className="text-black text-[13px] font-semibold text-center absolute top-[145px] left-[50%] translate-x-[-50%]">
            Payment gateway
          </span>
          <div className="flex gap-2 items-center justify-center">
            <a
              href="https://rider.digistall.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`https://${shopId}.digistall.in`}
            </a>
          </div>
        </div>
      </div>
      <div>
        <button onClick={handleDownloadBack} style={styles.downloadButton}>
          Download Back Card
        </button>
      </div>
    </div>
  );
};

const styles = {
  cardPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: "16px",
    overflow: "scroll",
  },
  card: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #ccc",
    borderRadius: "8px",
    width: "426px",
    height: "242px",
    margin: "0 auto 16px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    background: `url(${frontDesign}) no-repeat center center`,
    backgroundSize: "cover",
  },
  cardBack: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ccc",
    borderRadius: "8px",
    width: "426px",
    height: "242px",
    margin: "0 auto 16px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    background: `url(${backDesign}) no-repeat center center`,
    backgroundSize: "cover",
  },
  details: {
    flex: "1",
    width: "100%",
    marginRight: "8px",
    position: "absolute",
    top: "60px",
    left: "17px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  text: {
    position: "absolute",
    top: "34.4px",
    left: "38px",
    display: "flex",
    flexDirection: "column",
    gap: "21px",
    fontSize: "13px",
    color: "#fff",
  },
  h1: {
    position: "absolute",
    top: "-35px",
    left: "2px",
    margin: "2px 0",
    fontSize: "25px",
    color: "#fff",
  },
  qrCode: {
    position: "absolute",
    top: "76px",
    right: "23px",
  },
  frontQrCode: {
    position: "absolute",
    top: "60px",
    right: "10px",
  },

  qrCodeImg: {
    width: "108px",
    height: "108px",
  },
  logo: {
    width: "60px",
    height: "60px",
    marginBottom: "8px",
  },
  link: {
    textDecoration: "none",
    fontSize: "13px",
    color: "#000",
  },
  url: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  downloadButton: {
    display: "block",
    margin: "20px auto",
    padding: "10px 20px",
    backgroundColor: "#00C0FF",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default Card;
