import logo from "../../img/Digistall_logo.png";
import { AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import bg from "../../img/cg.png";
import "./note.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";

const Note = () => {
  const { userStatus } = useSelector((state) => state.user);
  const { shopId } = useSelector((state) => state.store.storeData);
  const email = useSelector(
    (state) => state.store.storeData.settings.header.email
  );
  const navigate = useNavigate();
  console.log(shopId);
  useEffect(() => {
    if (shopId) {
      const subdomain = shopId;
      fetch("https://gotanbackend.digistall.in/api/digi/subdomainmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subdomain }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        });
    }
    fetch("https://gotanbackend.digistall.in/api/digi/welcomeemail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
  }, []);

  useEffect(() => {
    switch (userStatus) {
      case "UNAUTHORIZED" || "User Not Existed":
        return navigate("/login");
      case "DOMAIN":
        return navigate("/domain");
      case "BUSSINESS":
        return navigate("/bussiness");
      case "FIRSTPRODUCT":
        return navigate("/product");
      case "PAYMENT":
        return navigate("/payment");
      default:
        break;
    }
  });

  return (
    <div className="note-bg-con">
      <div className="note-bg-img-con">
        <img src={bg} alt="background" className="background-img" />
      </div>
      <div className="note-header">
        <img src={logo} alt="logo" className="note-logo" />
      </div>
      <div className="notebody-con">
        <h1>Congratulation You Are In</h1>
        <p>Your 7 day Free Trial Starts Now</p>
        <div className="buttons-con">
          <button type="button" onClick={() => navigate("/")} className="trail">
            Continue Free Trial
          </button>
          <button
            type="button"
            onClick={() => navigate("/profile/subscription")}
            className="sub"
          >
            Subscribe
          </button>
        </div>
      </div>
      <div className="footer-bottom-container">
        <p className="copyright">All Rights Reserved (C) | digistall.in</p>
        <div className="footer-social-accounts">
          <div className="insta social-icon">
            <a
              rel="noreferrer"
              href="https://www.instagram.com/digistallapp/"
              target="_blank"
            >
              {" "}
              <AiOutlineInstagram className="insta social-icon" />
            </a>
          </div>
          <div className="youtube social-icon">
            <a
              href="https://www.youtube.com/@humansofruralindia1989"
              target="_blank"
            >
              {" "}
              <AiFillYoutube className="youtube social-icon" />
            </a>
          </div>
          <div className="facebook social-icon">
            <a
              rel="noreferrer"
              href="https://www.facebook.com/humansofruralindia/"
              target="_blank"
            >
              {" "}
              <BsFacebook className="facebook social-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Note;
