import React, { useState } from "react";
import radio from "../../img/emitraradio.jpeg";
import "./radio.css";
import { useSelector } from "react-redux";
const audioSrc =
  "https://res.cloudinary.com/dgwbh1dxo/video/upload/v1709383096/t76wznxl0pwpqy1stfe6.mp3";

function Radio() {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();
  const rlang = useSelector((state) => state.user.lang);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  return (
    <>
      <div className="cont" id="player-cont">
        <img className="cont-img" src={radio} width={200} height={200} />
        <audio ref={audioRef} src={audioSrc} />
        {/* {isPlaying ? <p>Radio is Playing</p> : <p>Radio is Paused</p>} */}

        <button className="playbutton" onClick={togglePlay}>
          {/* {isPlaying ? "Pause" : "Play"} */}
          {rlang === "hi"
            ? isPlaying
              ? "रोकें"
              : "चलाएं"
            : isPlaying
            ? "Pause"
            : "Play"}
        </button>
      </div>
    </>
  );
}

export default Radio;
