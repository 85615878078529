import { TailSpin } from 'react-loader-spinner'

const Loader = () => (
    <TailSpin
                height={80}
                width={80}
                color="#0293A8"
            />
)

export default Loader